import React, { useEffect, useState, useRef } from "react";
import "../Login/index.css";
import { connect } from "react-redux";
import { setCookie, getCookie } from "../../shared/utils/cookies";
// import NewCandidatePage from "./singleton/NewCAndidatePage";
import NewAdvisoryDetails from "./singleton/NewAdvisoryDetails";
import { Helmet } from "react-helmet";
import SkeletonCards from "./singleton/SkeletonCards.js/SkeletonCards";
import { PropTypes, element } from "prop-types";
import "./custom-scrollbar.css"; // Import your custom scrollbar styles
import { motion } from "framer-motion";
import axios from "axios";
import {
  ExpertiseMapStringresult,
  Unqiiidesire,
  uniqueBizzModel,
  uniqueNewStage,
  uniqueLocationresult,
} from "../../shared/utils/lists";
import {
  // getDash,
  getAdvisoryDash,
  getPortfolioComp,
  getCompanyDash,
  getCompanyData,
  getUniqueLink,
  getUserDetails,
  getLogOut,
  getRoles,
  getDesire,
  getExperience,
  getGlobalSearch,
} from "../authentication";
import HeaderUIDash from "./singleton/HeaderDash";
import HeaderUI from "./singleton/Header";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import copy from "copy-to-clipboard";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { getFullProfile } from "../../shared/httpService/apis/enrichedApi";
import { funtion } from "../../shared/utils/funtion";
import BannerModule from "../BannerModule";
import PrimiumModule from "../PrimiumModule";
import {
  GooglePlacesSelect,
  searchOverGooglePlacesObject,
} from "../../shared/components/googlePlaces";
import FormModleAdvisors from "./singleton/FormModleAdvisors";

const AdvisoryDashboard = ({
  authentication_state,
  // fetchDashboard,
  fetchAdvisoryDashboard,
  fetchVentureData,
  fetchRegisteredCandidate,
  fetchUniqueLink,
  fetchUSerDetails,
  fetchRoles,
  UserLogout,
  fetchGlobalSearch,
}) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const uniqueId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.id || "";
  const uniqueRoleId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.role_id ||
    "";
  const UserCase =
    authentication_ref.current?.User?.response?.data?.data?.access || "";
  const Token = getCookie("access_token");
  const effectRan = useRef(false);
  const history = useHistory();
  const menuRef = useRef();
  const buttonRef = useRef();
  const dummy = useRef(null);
  const [visible, setVisible] = useState(20);
  const [FormPopup, setFormPopup] = useState(false);
  const [apistatus, setApistatus] = useState(0)
  const [dashData, setDashData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [userRole, setUserRole] = useState();
  const [disableInitialAnimation, setDisableInitialAnimation] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userCompany, setUserCompony] = useState();
  const [userCompanys, setUserComponys] = useState([]);
  const [MultiUserComp, setMultiUserComp] = useState(); // If there are Multiple role funtionality
  const [userCompanyId, setUserCompanyId] = useState();
  const [addCandidatePopUp, setAddCAndidatePopUp] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [PaymentPop, setPaymentPop] = useState(false);
  const [events, setEvents] = useState("");
  const [buttonShows, setButoonShows] = useState(false);
  const [profileIcon, setProfileIcon] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [VC, setVC] = useState();
  const [Exper, setExper] = useState(null);
  const [BizModel, setBizModel] = useState(null);
  const [SpecialityModel, setSpecialityModel] = useState(null);
  const [StageModel, setStageModel] = useState([]);
  const [RoleModel, setRoleModel] = useState([]);
  const [Location, setLocation] = useState(null);
  const [colorData, setColorData] = useState([]);
  const [userDetails, setUserDetails] = useState("");
  const [WholeUserDetails, setWholeUserDetails] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [showPrimiumModel, setShowPrimiumModel] = useState(false);
  const [bannerSection, setBannerSection] = useState();
  const [FirstName, setFirstName] = useState("");
  const storedCompanyId = localStorage.getItem("companyId");
  const storedCompanyName = localStorage.getItem("companyName");
  const MainCompanyId = localStorage.getItem("MainCompanyId");
  const [VentureData, setVentureData] = useState()
  const MultiUserCompany = localStorage.getItem("MultiUserComp");
  const errorHandling =
    authentication_ref?.current?.dash_data?.error?.response?.status || null;

  const [size, setSize] = useState(20);
  const [cardData, setCardData] = useState({
    CanInfo: null,
  });
  const [newCardDataId, setNewCardDataId] = useState({
    CanInfo: null,
  });

  const AdvisoryExpertiesstring = [
    { currItem: "Coaching" },
    { currItem: "Commercial & Revenue" },
    { currItem: "Customer Success" },
    { currItem: "Finance" },
    { currItem: "Growth & Marketing" },
    { currItem: "Legal" },
    { currItem: "Operations & Strategy" },
    { currItem: "People & Talent" },
    { currItem: "Product" },
    { currItem: "Purchase & Supply Chain" },
    { currItem: "Risk & Compliance" },
    { currItem: "Technology" },
  ];

  //Dynamic Bizz Model
  const BizzModel = filterData?.map((item) => {
    return item.careerplan?.business_model_experience;
  });

  let uniqueBuzz = BizzModel?.filter((a, index) => {
    return BizzModel?.indexOf(a) === index;
  });
  let BizzModelString = uniqueBuzz?.toString()?.split(",");

  let UniBuzzModelString = BizzModelString?.filter((c, index) => {
    return BizzModelString?.indexOf(c) === index;
  });

  const UniBuzzModelStringresult = UniBuzzModelString?.reduce(
    (acc, currItem) => {
      // Check if the value is not an empty string before pushing it
      if (currItem !== "") {
        acc.push({ currItem });
      }
      return acc;
    },
    []
  );

  const testings = UniBuzzModelStringresult?.sort((a, b) =>
    a?.currItem?.localeCompare(b?.currItem)
  );

  //Dynamic data in the filter ---- Location-->
  const rawSpecialities = filterData?.map((item) => {
    return item?.about?.specialities;
  });

  let uniquesDesired = rawSpecialities?.filter((a, index) => {
    return rawSpecialities?.indexOf(a) === index;
  });

  let DesiredLocString = uniquesDesired?.toString()?.split(",").map(item=>item.trim());

  let UniDesiredLocString = DesiredLocString?.filter((c, index) => {
    return DesiredLocString?.indexOf(c) === index;
  });

  const UniDesiredLocStringresult = UniDesiredLocString?.reduce(
    (acc, currItem) => {
      // Check if the value is not an empty string before pushing it
      if (currItem !== "") {
        acc.push({ currItem });
      }
      return acc;
    },
    []
  );

  const testing = UniDesiredLocStringresult?.sort((a, b) =>
    a?.currItem?.localeCompare(b?.currItem)
  );

  // ******** Ends ***********

    //Calling venture data
    useEffect(() => {
      const headerData = {
        headers: {
          "Content-Type": "application/json",
        },
      };
        axios
        .get(
          `/api/venturecapitalforms/venturecapitalforms/get/${uniqueId}/`,
          headerData
        ).then((res) => {
          setVentureData(res?.data?.data)
        })
    }, [])

  useEffect(() => {
    fetchUSerDetails(uniqueId).then(() => {
      if (authentication_ref?.current?.User?.response.status == 200) {
        setUserDetails(
          authentication_ref?.current?.User?.response?.data?.data?.access
        );
        setWholeUserDetails(
          authentication_ref?.current?.User?.response?.data?.data
        );
        localStorage.setItem(
          "usersIdentity",
          authentication_ref?.current?.retailer_auth?.response?.data?.data?.id
        );
        setBannerSection(
          authentication_ref?.current?.User?.response?.data?.data?.banner
        );
        setFirstName(
          authentication_ref?.current?.User?.response?.data?.data?.firstname
        );
      }
    });
  }, []);

  useEffect(() => {
    setGlobalSearch("");
    setExper(null);
    setBizModel(null);
    setSpecialityModel(null);
    setLocation(null);
    setStageModel([]);
    setRoleModel([]);
  }, [storedCompanyName]);

  useEffect(() => {
    fetchVentureData(uniqueId).then(() => {
      if (authentication_ref?.current?.port_data?.response?.status == 200) {
        //Storing Main user Company id
        if (
          authentication_ref?.current?.port_data?.response?.data?.data?.length >
          1
        ) {
          localStorage.setItem(
            "MainCompanyId",
            authentication_ref?.current?.port_data?.response?.data?.data[1].id
          );
          localStorage.setItem(
            "MultiUserComp",
            authentication_ref?.current?.port_data?.response?.data?.data[1]
              .company_name
          );
          // setMultiUserComp(authentication_ref?.current?.port_data?.response?.data?.data[1].company_name)
        } else {
          localStorage.setItem(
            "MainCompanyId",
            authentication_ref?.current?.port_data?.response?.data?.data[0].id
          );
          localStorage.setItem(
            "MultiUserComp",
            authentication_ref?.current?.port_data?.response?.data?.data[0]
              .company_name
          );
          // setMultiUserComp(authentication_ref?.current?.port_data?.response?.data?.data[0].company_name)
        }
        setUserCompony(
          authentication_ref?.current?.port_data?.response?.data?.data[0]
        );
        setUserComponys(
          authentication_ref?.current?.port_data?.response?.data?.data
        );
        if (storedCompanyName == undefined) {
          localStorage.setItem(
            "companyName",
            authentication_ref?.current?.port_data?.response?.data?.data[0]
              .company_name
          ); // Store in local storage
          localStorage.setItem(
            "AdvisoryDetails",
            authentication_ref?.current?.port_data?.response?.data?.data[0]
              ?.advisors
          ); // Store in local storage
        }
        if (storedCompanyId) {
          setUserCompanyId(storedCompanyId);
        } else {
          const initialCompanyId =
            authentication_ref?.current?.port_data?.response?.data?.data[0].id;
          localStorage.setItem("companyId", initialCompanyId); // Store in local storage
          setUserCompanyId(initialCompanyId);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (effectRan.current) return; // Exit if the effect has already run
    if (storedCompanyId) {
      fetchAdvisoryDashboard(storedCompanyId).then(() => {
        if (
          authentication_ref?.current?.advisory_data?.response?.status == 200
        ) {
          setDashData(
            authentication_ref?.current?.advisory_data?.response?.data
          );
          setFilterData(
            authentication_ref?.current?.advisory_data?.response?.data
          );
          setColorData(
            authentication_ref?.current?.advisory_data?.response?.data
          );
          localStorage.setItem(
            "userData",
            authentication_ref?.current?.advisory_data?.response?.data
          );
        }
      });
    } else {
      fetchAdvisoryDashboard(userCompanyId).then(() => {
        if (
          authentication_ref?.current?.advisory_data?.response?.status == 200
        ) {
          setDashData(
            authentication_ref?.current?.advisory_data?.response?.data
          );
          setFilterData(
            authentication_ref?.current?.advisory_data?.response?.data
          );
          setColorData(
            authentication_ref?.current?.advisory_data?.response?.data
          );
          localStorage.setItem(
            "userData",
            authentication_ref?.current?.advisory_data?.response?.data
          );
        }
      });
    }
    effectRan.current = true; // Mark the effect as run
  }, [userCompanyId, userCompanyId]);

  useEffect(() => {
    if (apistatus == 1) {
      if(storedCompanyId){
        fetchAdvisoryDashboard(storedCompanyId).then(() => {
          if (
            authentication_ref?.current?.advisory_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setApistatus(0)
          }
        });
      }
      else {
        fetchAdvisoryDashboard(userCompanyId).then(() => {
          if (
            authentication_ref?.current?.advisory_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.advisory_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.advisory_data?.response?.data
            );
            setApistatus(0)
          }
        });
      }
    }
  }, [apistatus]);

  // useEffect(() => {
  //   Update the userID value in the script tag
  //   const plausibleScript = document.getElementById('plausible-script');
  //   plausibleScript.setAttribute('event-userID', uniqueId);
  // }, [uniqueId])

  useEffect(() => {
    if (userDetails == "noaccess") {
      UserLogout();
      setCookie("access_token", "");
      window.localStorage.removeItem("Token");
      window.location.href = "/";
    }
  }, [userDetails]);

  useEffect(() => {
    if (errorHandling == 401) {
      UserLogout();
      setCookie("access_token", "");
      window.localStorage.removeItem("Token");
      window.location.href = "/";
    }
  }, [errorHandling]);

  //FETCHING REGISTERED CANDIDTE DETAILS
  // useEffect(() => {
  //   const storedCompanyId =
  //     localStorage.getItem("companyId") ||
  //     authentication_ref?.current?.port_data?.response?.data?.data[0].id;
  //   fetchRegisteredCandidate(storedCompanyId);
  // }, []);

  const handleRegistrationLink = () => {
    if (storedCompanyName == "Startup Lane") {
      window.open(`${window.location.origin}/startupLane/register`);
    } else if (storedCompanyName == "Global VC Talent Network") {
      window.open(`${window.location.origin}/GVCTN/register`);
    } else {
      const vcURLShortName = VentureData?.url_shortname
      window.open(
        `${window.location.origin}/${vcURLShortName}/register`
      );
    }
  };

  const handlemanageTeamMember = () => {
    window.open(`${window.location.origin}/manage/team-member`);
  };

  const handleCandidatePopup = () => {
    setFormPopup(false)
  }

  //FETCHING ROLES OF THE USER
  useEffect(() => {
    fetchRoles(uniqueRoleId).then(() => {
      if (authentication_ref?.current?.Roles_data?.response?.status == 200) {
        setUserRole(
          authentication_ref?.current?.Roles_data?.response?.data?.data
            ?.rolename
        );
      }
    });
  }, []);

  useEffect(() => {
    function handleResize() {
      const newVisible = calculateVisible();
      setVisible(newVisible);
    }

    function calculateVisible() {
      // You can adjust these values according to your needs
      if (window.innerWidth > 1526) {
        return 40;
      } else {
        return 42;
      }
    }

    // Initial calculation
    const initialVisible = calculateVisible();
    setVisible(initialVisible);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // DASHBOARD DATA OF THE CANDIDATE
  // useEffect(() => {
  //   fetchDashboard().then(() => {
  //     if (authentication_ref?.current?.dash_data?.response?.status == 200) {
  //       setDashData(
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //       setFilterData(
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //       setColorData(
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //       localStorage.setItem(
  //         "userData",
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //     }
  //   });
  // }, []);

  //Candidates Filter
  useEffect(() => {
    if (dashData != undefined) {
      let _dashData = [...dashData];
      if (globalSearch) {
        const trimmedSearch = globalSearch?.toLowerCase()?.trim(); // Trim and convert to lowercase once

        _dashData = _dashData.filter((user) => {
          const trimmedFirstName = user.firstname.toLowerCase().trim(); // Trim and convert to lowercase
          const trimmedLastName = user.lastname.toLowerCase().trim(); // Trim and convert to lowercase
          const fullName = `${trimmedFirstName} ${trimmedLastName}`;

          return (
            trimmedFirstName.includes(trimmedSearch) ||
            trimmedLastName.includes(trimmedSearch) ||
            fullName.includes(trimmedSearch)
          );
        });
      }
      if (Exper) {
        _dashData = _dashData?.filter((item) =>
          item?.about?.functionalexpertise?.includes(Exper?.currItem)
        );
      }
      if (SpecialityModel) {
        _dashData = _dashData?.filter((item) =>
          item?.about?.specialities?.includes(SpecialityModel?.currItem)
        );
      }
      if (BizModel) {
        _dashData = _dashData?.filter((item) =>
          item?.careerplan?.business_model_experience?.includes(
            BizModel?.currItem
          )
        );
      }
      if (RoleModel) {
        const RoleData = RoleModel;
        if (RoleModel?.length !== 0) {
          _dashData = _dashData?.filter((item) => {
            return (
              (RoleData?.includes("Founder") && item?.careerplan?.founder_experience === "Yes") ||
              (RoleData?.includes("NED") && item?.careerplan?.board_experience === "Yes") ||
              (RoleData?.includes("Chair") && item?.careerplan?.chair_experience === "Yes") ||
              (RoleData?.includes("Advisor") && item?.careerplan?.advisory_experience === "Yes")
            );
          });
        }
        
        // if (RoleModel?.length !== 0) {
        //   _dashData = _dashData?.filter(
        //     (item) => {
        //       if (RoleData?.includes("Founder")) {
        //         return item?.careerplan?.founder_experience === "Yes";
        //       }
        //       if (RoleData?.includes("NED")) {
        //         return item?.careerplan?.board_experience === "Yes";
        //       }
        //       if (RoleData?.includes("Chair")) {
        //         return item?.careerplan?.chair_experience === "Yes";
        //       }
        //       if (RoleData?.includes("Advisor")) {
        //         return item?.careerplan?.advisory_experience === "Yes";
        //       }
        //     }
        //     // item?.careerplan?.business_model_experience?.includes(
        //     //   RoleModel?.currItem
        //     // )
        //   );
        // }
      }
      if (Location) {
        _dashData = _dashData?.filter((item) =>
          searchOverGooglePlacesObject(
            Location,
            item?.about?.currentLocation?.address_components
          )
        );
      }
      if (StageModel && StageModel.toString().trim() !== "") {
        const stageList = StageModel.toString().split(",");

        if (stageList.length > 0) {
          _dashData = _dashData?.filter((item) => {
            const careerStages = item?.careerplan?.stage_experience.split(",");
            return stageList.some((stage) => careerStages.includes(stage));
          });
        }
        // If stageList is empty, _dashData remains unchanged
      }
      setFilterData([..._dashData]);
      setColorData([..._dashData]);
    }
  }, [
    globalSearch,
    SpecialityModel,
    Exper,
    BizModel,
    StageModel,
    Location,
    RoleModel,
    dashData,
  ]);

  useEffect(() => {
    let timeout;
    if (buttonShows) {
      timeout = setTimeout(() => setButoonShows(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [buttonShows]);

  const handleAdvisors = () => {
    if(userRole == "venturecapital" &&
    storedCompanyName !==
    "Global VC Talent Network" || userRole == "globalvc" && storedCompanyName !==
    "Global VC Talent Network"){
      setFormPopup(true)
    } 
    else
     {
      if (storedCompanyName == "Startup Lane") {
        window.open(`${window.location.origin}/StartupLane/advisorsid=${uniqueId}`);
      } else if (storedCompanyName == "Global VC Talent Network") {
        window.open(`${window.location.origin}/GVCTN/advisorsid=${uniqueId}`);
      } else {
        const vcURLShortName = VentureData?.url_shortname
        window.open(
          `${window.location.origin}/${vcURLShortName}/advisorsid=${uniqueId}`
        );
      }
    }
  };

  const handleCandidates = () => {
    if(userRole == "venturecapital" &&
    storedCompanyName !==
    "Global VC Talent Network" || userRole == "globalvc" && storedCompanyName !==
    "Global VC Talent Network"){
      setFormPopup(true)
    } 
    else
     {

      if (storedCompanyName == "Startup Lane") {
        window.open(`${window.location.origin}/StartupLane/applyid=${uniqueId}`);
      } else if (storedCompanyName == "Global VC Talent Network") {
        window.open(`${window.location.origin}/GVCTN/applyid=${uniqueId}`);
      } else {
        const vcURLShortName = VentureData?.url_shortname
        window.open(
          `${window.location.origin}/${vcURLShortName}/applyid=${uniqueId}`
        );
      }
    }
  };

  const handleCandidateAddNew = () => {
    if (addCandidatePopUp == false) {
      setAddCAndidatePopUp(true);
      setDisableInitialAnimation(true);
    } else if (addCandidatePopUp == true) {
      setAddCAndidatePopUp(false);
      setDisableInitialAnimation(true);
    }
  };

  const handleReferCandidate = () => {
    if (storedCompanyName == "Startup Lane") {
      window.open(`${window.location.origin}/StartupLane/applyid=${uniqueId}`);
    } else if (storedCompanyName == "Global VC Talent Network") {
      window.open(`${window.location.origin}/GVCTN/applyid=${uniqueId}`);
    } else {
      const vcURLShortName = VentureData?.url_shortname
      window.open(
        `${window.location.origin}/${vcURLShortName}/applyid=${uniqueId}`
      );
    }
  };

  const handleCandidateDetails = () => {
    window.open(`${window.location.origin}/Candidates/details`);
  };

  const handleCompaniesDetails = () => {
    window.open(`${window.location.origin}/companies/details`);
  };

  const handleTeammember = () => {

    if (storedCompanyName == "Startup Lane") {
      window.open(`${window.location.origin}/StartupLane/add-member`);
    } else if (storedCompanyName == "Global VC Talent Network") {
      window.open(`${window.location.origin}/GVCTN/add-member`);
    } else {
      const vcURLShortName = VentureData?.url_shortname
      window.open(
        `${window.location.origin}/${vcURLShortName}/add-member`
      );
    }
  };

  const handleCompanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

  const TestingNumber =
    filterData?.length < 20
      ? filterData?.length
      : filterData?.length < visible
      ? filterData.length
      : visible;

  const handleCompanyMember = () => {
    if (storedCompanyName == "Startup Lane") {
      window.open(`${window.location.origin}/startupLane/register`);
    } else if (storedCompanyName == "Global VC Talent Network") {
      window.open(`${window.location.origin}/GVCTN/register`);
    } else {
      const vcURLShortName = VentureData?.url_shortname
      window.open(
        `${window.location.origin}/${vcURLShortName}/register`
      );
    }
  };

  const handleRegisteredComp = () => {
    window.open(`${window.location.origin}/Company/details`);
  };

  const handleList = () => {
    window.open(`${window.location.origin}/lists`);
  };

  const LogoutUser = () => {
    UserLogout();
    setCookie("access_token", "");
    window.localStorage.removeItem("Token");
    window.localStorage.removeItem("companyId");
    window.localStorage.removeItem("companyName");
    window.localStorage.removeItem("AdvisoryDetails");
    window.localStorage.removeItem("MultiUserComp");
    window.localStorage.removeItem("MainCompanyId");
    window.location.href = "/";
  };

  const handleRefreshPage = () => {
    // setShowModel(!showModel);
    window.location.href = "/dashboard/advisors";
  };

  const handleProfileIconButton = () => {
    setProfileIcon((profileIcon) => !profileIcon);
    setDisableInitialAnimation(true);
  };

  const handleModelClose = () => {
    setShowModel(!showModel);
    setRefresh(!refresh);
    history.replace(`/dashboard/advisors`);
  };

  const handlePremiumChange = () => {
    setShowPrimiumModel(false);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setToggleFilter(!toggleFilter);
  };

  const loadmoreHander = (e) => {
    e.preventDefault();
    setVisible((preValue) => preValue + visible);
    setRefresh(false); // Toggle the refresh state to force a re-render
  };

  useEffect(() => {
    const FirstName = cardData.CanInfo?.firstname.trim();
    const LastName = cardData.CanInfo?.lastname.trim();
    if (events.ctrlKey) {
      var new_url = `/advisors/${
        cardData?.CanInfo?._id?.$oid
      }-${FirstName?.charAt(0)?.toLowerCase()}${FirstName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}-${LastName?.charAt(
        0
      )?.toLowerCase()}${LastName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
      window.open(`${window.location.origin}${new_url}`);
    }
  }, [events]);

  const handleNameClick = (e, item) => {
    if (e) {
      e.preventDefault(); // Prevent the default behavior (opening a new tab)
      const FirstName = item.firstname.trim();
      const LastName = item.lastname.trim();
      var new_url = `/advisors/${item?._id?.$oid}-${FirstName?.charAt(
        0
      )?.toLowerCase()}${FirstName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}-${LastName?.charAt(
        0
      )?.toLowerCase()}${LastName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;

      // Open the new URL in a new tab with _blank target
      const newTab = window.open(
        `${window.location.origin}${new_url}`,
        "_blank"
      );

      // Switch back to the original tab (assuming you're using a two-tab setup)
      window.focus();
    }
  };

  // const FirstName = item.firstname.trim();
  // const LastName = item.lastname.trim();
  //   var new_url = `/candidate/${item?._id?.$oid}-${FirstName?.charAt(
  //     0
  //   )?.toLowerCase()}${FirstName?.slice(1)
  //     ?.toLowerCase()
  //     .replaceAll(" ", "-")}-${LastName?.charAt(
  //     0
  //   )?.toLowerCase()}${LastName?.slice(1)
  //     ?.toLowerCase()
  //     .replaceAll(" ", "-")}`;
  //   window.open(`${window.location.origin}${new_url}`);

  const handleClickEvent = (index, e) => {
    setCardData({ CanInfo: filterData[index] });
    setNewCardDataId({ CanInfo: filterData[index] });
    setEvents(e);
    setActiveIndex(index);
    if (!e.ctrlKey) {
      setShowModel(!showModel);
    }
  };

  const handlePrimiumModelOpen = () => {
    setShowPrimiumModel(true);
  };

  // Making the URL when the card is clicked and the model is opened
  useEffect(() => {
    const FirstName = cardData.CanInfo?.firstname.trim();
    const LastName = cardData.CanInfo?.lastname.trim();
    if (showModel == true) {
      var new_url = `/advisors/${
        cardData?.CanInfo?._id?.$oid
      }-${FirstName?.charAt(0)?.toLowerCase()}${FirstName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}-${LastName?.charAt(
        0
      )?.toLowerCase()}${LastName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
      window.history.pushState("data", "", new_url);
    }
  }, [showModel]);
  useEffect(() => {
    if (showModel == false) {
      var new_url = `/dashboard/advisors`;
      window.history.pushState("data", "", new_url);
    }
  }, [showModel]);

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    let eventhandler = (event) => {
      if (!buttonRef?.current?.contains(event.target)) {
        setAddCAndidatePopUp(false);
      }
    };
    document.addEventListener("mousedown", eventhandler);

    return () => {
      document.removeEventListener("mousedown", eventhandler);
    };
  }, []);

  const toUpperCaseNameFuntion = (item) => {
    const capitalizeWord = (word) => {
      // Check if the word contains a hyphen or an apostrophe
      if (word.includes("-")) {
        const parts = word?.split("-");
        return parts.map((part) => capitalizeWord(part)).join("-");
      } else if (word.includes("'")) {
        const parts = word?.split("'");
        return parts.map((part) => capitalizeWord(part)).join("'");
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const FirstName = item?.firstname;
    const arr = FirstName?.split(" ").map(capitalizeWord);
    const FirstName2 = arr.join(" ");

    const LastName = item?.lastname;
    const arrLast = LastName?.split(" ").map(capitalizeWord);
    const LastName2 = arrLast.join(" ");

    const FullName = `${FirstName2} ${LastName2}`;

    return FullName;
  };

  const handleBannerClose = (e) => {
    setBannerSection(true);
    axios({
      method: "get",
      url: `/api/user/banner/${uniqueId}/`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status_code) {
        setBannerSection(res.data.data.banner);
      }
    });
  };

  const upperCaseWorkFuntion = (item) => {
    const Str = item?.careerplan?.business_model_experience;
    const arr = Str?.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  const customStyles = {
    // Add your custom styles for the dropdown indicator here
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#2D3958", // Example color change based on focus state
      // Add more styles as needed
    }),
  };

  const upperCaseEmployer = (item) => {
    const Str = item?.careerplan?.employer;
    const arr = Str?.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)?.toLowerCase();
    }
    const str2 = arr.join(" ");
    return str2;
  };

  useEffect(() => {
    if (colorData != undefined) {
      const targetArray = filterData;
      let z = 1;
      for (let i = 0; i < targetArray.length; i++) {
        if (targetArray[i].access == "premium") {
          targetArray[i].colour = z;
          z++;
          if (z == 6) {
            z = 1;
          }
        }
      }
      setFilterData([...targetArray]);
    }
  }, [colorData]);

  const handlePopUpOpen = () => {
    setPaymentPop(true);
  };

  const ShowPaymentModel = () => {
    return <PrimiumModule handlePremiumChange={handlePremiumChange} />;
  };

  const StyleFuntion = (item) => {
    if (userRole == "companyportfolio") {
      return;
    } else if (VC == "Startup Lane") {
      return;
    } else {
      const ColorFuntions = funtion(item);
      return ColorFuntions;
    }
  };

  const handleIndustries = (item) => {
    return (
      <>
        {[
          item?.careerplan?.board_experience === "Yes" ? "NED" : null,
          item?.careerplan?.advisory_experience === "Yes" ? "Advisor" : null,
          item?.careerplan?.founder_experience === "Yes" ? "Founder" : null,
          item?.careerplan?.chair_experience === "Yes" ? "Chair" : null,
          item?.careerplan?.chair_experience === "No" &&
          item?.careerplan?.founder_experience === "No" &&
          item?.careerplan?.advisory_experience === "No" &&
          item?.careerplan?.board_experience === "No"
            ? "Industry Veteran"
            : null,
        ]
          .filter(Boolean) // Remove null values
          .join(", ")}
      </>
    );
  };

  // const handleCarrelPlan = (item) => {
  //   const levelArray = item.careerplan.careerlevel
  //     ?.split(",")
  //     .map((level) => ({ name: level }));

  //   const UniStage = levelArray?.reduce((acc, name) => {
  //     if (name.name == "Mid") {
  //       acc.push({ name, id: "1" });
  //     }
  //     if (name.name == "Senior") {
  //       acc.push({ name, id: "2" });
  //     }
  //     if (name.name == "Lead/Principal") {
  //       acc.push({ name, id: "3" });
  //     }
  //     if (name.name == "Manager") {
  //       acc.push({ name, id: "4" });
  //     }
  //     if (name.name == "Head Of") {
  //       acc.push({ name, id: "5" });
  //     }
  //     if (name.name == "Director") {
  //       acc.push({ name, id: "6" });
  //     }
  //     if (name.name == "VP/GM") {
  //       acc.push({ name, id: "7" });
  //     }
  //     if (name.name == "Exec") {
  //       acc.push({ name, id: "8" });
  //     }
  //     if (name.name == "NED") {
  //       acc.push({ name, id: "9" });
  //     }
  //     if (name.name == "Head of / Director") {
  //       acc.push({ name, id: "991" });
  //     }
  //     if (name.name == "Board of Directors / Advisory") {
  //       acc.push({ name, id: "992" });
  //     }
  //     if (name.name == "VP / GM") {
  //       acc.push({ name, id: "993" });
  //     }
  //     if (name.name == "Executive / C-suite") {
  //       acc.push({ name, id: "994" });
  //     }
  //     if (name.name == "Lead / Mid-Manager") {
  //       acc.push({ name, id: "995" });
  //     }
  //     if (name.name == "Senior / Principal") {
  //       acc.push({ name, id: "996" });
  //     }
  //     if (name.name == "Junior / Mid-Level") {
  //       acc.push({ name, id: "997" });
  //     }
  //     if (name.name == "Entry Level") {
  //       acc.push({ name, id: "998" });
  //     }
  //     if (name.name == "CEO") {
  //       acc.push({ name, id: "999" });
  //     }
  //     return acc;
  //   }, []);

  //   if (UniStage.length > 1) {
  //     const smallestIdObj = UniStage.reduce(
  //       (minObj, level) => (level.id < minObj.id ? level : minObj),
  //       UniStage[0]
  //     );
  //     const greatestIdObj = UniStage.reduce(
  //       (maxObj, level) => (level.id > maxObj.id ? level : maxObj),
  //       UniStage[0]
  //     );

  //     return `${smallestIdObj.name.name} → ${greatestIdObj.name.name}`;
  //   } else {
  //     const finalStage = UniStage.sort((a, b) => {
  //       if (a.id > b.id) return 1;
  //       if (a.id < b.id) return -1;
  //       return 0;
  //     });

  //     const CareerString = finalStage.map((item) => {
  //       return item?.name?.name?.split(",");
  //     });

  //     const FinalStringArray = CareerString.toString().replace(/,/g, " → ");

  //     return FinalStringArray;
  //   }
  // };

  useEffect(() => {
    // Reset the disableInitialAnimation state when the component mounts again
    setDisableInitialAnimation(false);
    if (showModel) {
      setRefresh(true);
    }
  }, [showModel]);

  const handleRoleArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setRoleModel([...RoleModel, value]);
    } else {
      setRoleModel(RoleModel.filter((e) => e !== value));
    }
  };

  const handleStagesArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setStageModel([...StageModel, value]);
    } else {
      setStageModel(StageModel.filter((e) => e !== value));
    }
  };

  const handleStageExperience = (item) => {
    if(item?.careerplan?.stage_experience == ""){
      return "Unknown"
    }else{
      const StagesString = item?.careerplan?.stage_experience?.split(",");
      const UniStage = StagesString?.reduce((acc, currItem) => {
        if (currItem == "Early Stage") {
          acc.push({ currItem, id: "1" });
        }
        if (currItem == "Pre-Seed") {
          acc.push({ currItem, id: "2" });
        }
        if (currItem == "Seed") {
          acc.push({ currItem, id: "3" });
        }
        if (currItem == "Series A") {
          acc.push({ currItem, id: "4" });
        }
        if (currItem == "Series B") {
          acc.push({ currItem, id: "5" });
        }
        if (currItem == "Late Stage") {
          acc.push({ currItem, id: "6" });
        }
        if (currItem == "Series C") {
          acc.push({ currItem, id: "7" });
        }
        if (currItem == "Series D") {
          acc.push({ currItem, id: "8" });
        }
        if (currItem == "Series E") {
          acc.push({ currItem, id: "9" });
        }
        if (currItem == "Pre-IPO") {
          acc.push({ currItem, id: "99" });
        }
        if (currItem == "Public") {
          acc.push({ currItem, id: "999" });
        }
        return acc;
      }, []);
      const sortedArray = UniStage?.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
      const StageToString = sortedArray?.map((item) => {
        return item?.currItem?.toString();
      });
      return StageToString?.toString().replaceAll(",", ", ");
    }
  };

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  };

  return (
    <>
      <Helmet>
        <title>
          {showModel == true
            ? "Startup Lane Candidate"
            : "Startup Lane Dashboard"}
        </title>
        {/* <script defer event-userID={uniqueId} data-domain="app.startuplane.io" type="javascript" src="https://plausible.io/js/script.outbound-links.tagged-events.pageview-props.js"></script> */}
      </Helmet>

      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        {showModel == false ? (
          <HeaderUIDash
            userRole={userRole}
            handleModelClose={handleModelClose}
            handleRefreshPage={handleRefreshPage}
            showModel={showModel}
            buttonRef={buttonRef}
            handleCollection={handleCollection}
            handleReferCandidate={handleReferCandidate}
            logo={cardData?.CanInfo?.careerplan?.capital}
            handleCandidateAddNew={handleCandidateAddNew}
            addCandidatePopUp={addCandidatePopUp}
            handleRegistrationLink={handleRegistrationLink}
            handleCandidates={handleCandidates}
            handleAdvisors={handleAdvisors}
            menuRef={menuRef}
            handleProfileIconButton={handleProfileIconButton}
            profileIcon={profileIcon}
            handleCompanyTeamMember={handleCompanyTeamMember}
            handleCampanyMember={handleCompanyMember}
            LogoutUser={LogoutUser}
            handleCandidateDetails={handleCandidateDetails}
            handleRegisteredComp={handleRegisteredComp}
            handleList={handleList}
            handlemanageTeamMember={handlemanageTeamMember}
            handleCompaniesDetails={handleCompaniesDetails}
            fetchNewDashboard={fetchAdvisoryDashboard}
            setDashData={setDashData}
            setFilterData={setFilterData}
            setColorData={setColorData}
            userCompany={userCompany}
            userCompanys={userCompanys}
            Auth={authentication_ref?.current.dash_data?.loading}
          />
        ) : (
          <HeaderUI
            userRole={userRole}
            VentureData={VentureData}
            handleModelClose={handleModelClose}
            handleRefreshPage={handleRefreshPage}
            handleCollection={handleCollection}
            showModel={showModel}
            buttonRef={buttonRef}
            logo={cardData?.CanInfo?.advisor?.careerplan?.capital}
            Vclogo={cardData?.CanInfo?.logo}
            handleCandidateAddNew={handleCandidateAddNew}
            addCandidatePopUp={addCandidatePopUp}
            handleRegistrationLink={handleRegistrationLink}
            handleCandidates={handleCandidates}
            handleAdvisors={handleAdvisors}
            menuRef={menuRef}
            handleProfileIconButton={handleProfileIconButton}
            profileIcon={profileIcon}
            handleCompanyTeamMember={handleCompanyTeamMember}
            handleCompanyMember={handleCompanyMember}
            LogoutUser={LogoutUser}
            handleCandidateDetails={handleCandidateDetails}
            handleRegisteredComp={handleRegisteredComp}
            handleList={handleList}
            handlemanageTeamMember={handlemanageTeamMember}
            handleCompaniesDetails={handleCompaniesDetails}
            fetchNewDashboard={fetchAdvisoryDashboard}
            setDashData={setDashData}
            setFilterData={setFilterData}
            setColorData={setColorData}
            userCompany={userCompany}
            userCompanys={userCompanys}
            Auth={authentication_ref?.current.dash_data?.loading}
          />
        )}
        {/* lg:max-h-[1010px] */}
        {showModel == false && (
          <aside className="bg-deep-purple-10 fixed top-0 z-10 w-full lg:w-[370px] lg:h-screen lg:max-h-[1047px] pt-28 sm:pt-32 lg:pt-24 pb-5 lg:pb-8 m-0 px-4 lg:pr-2 lg:pl-6">
            <div className="w-full lg:h-full relative rounded-lg lg:bg-white lg:border lg:border-solid lg:border-[#E6E9F0] lg:py-1 lg:pr-1">
              <form
                className="custom-scroll flex lg:block w-full lg:h-full lg:overflow-y-auto lg:p-3 lg:pl-4"
                method="POST"
                action="#"
              >
                <label className="hidden lg:block w-full text-base font-medium mb-2">
                  Search Advisors
                </label>
                <div className="inline-flex lg:flex items-center group lg:w-full sm:relative lg:mb-6">
                  <span className="mobileSearch-btn block absolute w-[46px] sm:w-5 h-[45px] sm:h-5 flex items-center justify-center left-[1px] top-[1px] sm:left-3 sm:top-[14px] z-[1]">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      className="w-4 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                        fill="#2D3958"
                      />
                    </svg>
                  </span>
                  <input
                    type="search"
                    id="search"
                    value={globalSearch}
                    onChange={(e) => {
                      setDisableInitialAnimation(true);
                      setGlobalSearch(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setGlobalSearch(e.target.value);
                      }
                    }}
                    // disabled = {authentication_ref?.current?.global_data?.loading || authentication_ref?.current?.dash_data?.loading ? true : false}
                    placeholder="Name, Job Title, etc"
                    className="w-12 hover:w-full my-input-class focus:w-full active:focus sm:w-full absolute top-0 sm:relative appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-2 pl-[45px] sm:pl-9 sm:pr-10 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:border-deep-purple-500 transition duration-500"
                    style={{ minHeight: "47px" }}
                  />
                  {globalSearch != "" ? (
                    <button
                      onClick={(e) => {
                        if (e) {
                          e.preventDefault();
                          setDisableInitialAnimation(true);
                          setGlobalSearch("");
                        }
                      }}
                      className="w-10 h-[45px] flex items-center justify-center bg-transparent focus:bg-transparent focus:outline-none absolute right-[1px] top-[1px]"
                    >
                      <svg
                        width="10px"
                        height="10px"
                        viewBox="0 0 16 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Group</title>
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <g
                            id="Group"
                            transform="translate(2.000000, 2.000000)"
                            stroke="#6936FF"
                            stroke-width="3"
                          >
                            <g id="icon_dropdown">
                              <polyline
                                id="Path"
                                points="0 0 6 6 12 0"
                              ></polyline>
                              <polyline
                                id="Path"
                                transform="translate(6.000000, 9.000000) rotate(180.000000) translate(-6.000000, -9.000000) "
                                points="0 6 6 12 12 6"
                              ></polyline>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                  ) : null}
                </div>

                <div className="flex lg:hidden items-center justify-between ml-auto h-[47px]">
                  {/* {userRole == "anonymous" ? null : userRole ==
                  "companyportfolio" ? (
                  <button
                    type="button"
                    onClick={handleReferCandidate}
                    className="add-candidate-btn inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      className="mr-1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                        fill="white"
                      />
                    </svg>
                    Refer candidate
                  </button>
                ) : null} */}

                  <button
                    onClick={(e) => handleToggle(e)}
                    className="bg-transparent focus:bg-transparent focus:outline-none ml-2"
                  >
                    <svg
                      width="25px"
                      height="23px"
                      viewBox="0 0 25 23"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>filter-icon</title>
                      <g
                        id="Page-1"
                        stroke="none"
                        className="hover:fill-deep-purple-500"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g id="filter-icon" fill="#666" fill-rule="nonzero">
                          <path
                            d="M16.3456427,5.31606969 L17.1362931,5.31606969 C17.4261982,5.31606969 17.6633933,5.07887457 17.6633933,4.78896943 C17.6633933,4.49906429 17.4261982,4.26186917 17.1362931,4.26186917 L16.3456427,4.26186917 C16.0557375,4.26186917 15.8185424,4.49906429 15.8185424,4.78896943 C15.8185424,5.07887457 16.0557375,5.31606969 16.3456427,5.31606969 Z"
                            id="Path"
                          ></path>
                          <path
                            d="M24.1467265,0.62487736 C23.9622415,0.255907177 23.5932713,0.0450670724 23.1979461,0.0450670724 L1.05973508,0.0450670724 C0.66440988,0.0450670724 0.295439697,0.28226219 0.110954605,0.62487736 C-0.0735304865,0.993847544 -0.0208204603,1.41552775 0.216374658,1.73178791 L9.51969428,13.4334137 L9.51969428,21.0500125 C9.51969428,21.4453377 9.73053438,21.7879529 10.0731496,21.972438 C10.2312796,22.051503 10.3894097,22.104213 10.5738948,22.104213 C10.7847349,22.104213 10.995575,22.051503 11.1800601,21.9197279 L14.1054666,19.8903919 C14.5271468,19.5741318 14.7643419,19.0997415 14.7643419,18.5989963 L14.7643419,13.4070587 L24.0413065,1.73178791 C24.2785016,1.41552775 24.3312116,0.993847544 24.1467265,0.62487736 Z M13.8155614,12.9063135 C13.7628514,12.9853785 13.7101414,13.0907986 13.7101414,13.2225736 L13.7101414,18.5726413 C13.7101414,18.7571264 13.6310763,18.9152564 13.4993013,19.0206765 L10.5738948,21.0500125 L10.5738948,13.2225736 C10.5738948,13.0380885 10.4948298,12.8799584 10.3630547,12.8008934 L4.40682174,5.31606969 L13.9736915,5.31606969 C14.2635966,5.31606969 14.5007918,5.07887457 14.5007918,4.78896943 C14.5007918,4.49906429 14.2635966,4.26186917 13.9736915,4.26186917 L3.69523639,4.26186917 C3.66888137,4.26186917 3.61617135,4.26186917 3.58981633,4.26186917 L1.05973508,1.0992676 L23.1979461,1.0992676 L13.8155614,12.9063135 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>

                <div className="search-bar small-filter absolute hidden lg:block  lg:relative inset-x-0 top-14 md:top-30 lg:top-auto max-h-[400px] md:max-h-[none] overflow-auto lg:overflow-visible bg-white lg:bg-transparent shadow-xl lg:shadow-none rounded-lg z-10 lg:z-0 p-4 lg:p-0">
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Main Expertise
                    </label>
                    <Select
                      placeholder="Product, Marketing, etc"
                      name="Expertise"
                      isClearable={true}
                      styles={customStyles} // Apply the custom styles
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setExper(item);
                      }}
                      value={Exper ? Exper : ""}
                      options={AdvisoryExpertiesstring}
                    />
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Speciality
                    </label>
                    <Select
                      placeholder="Demand Gen, IPO, Fundraising"
                      name="Demand Gen, IPO, Fundraising"
                      styles={customStyles} // Apply the custom styles
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setSpecialityModel(item);
                      }}
                      value={SpecialityModel ? SpecialityModel : ""}
                      options={UniDesiredLocStringresult}
                    />
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Biz Model Experience
                    </label>
                    <Select
                      placeholder="B2B, B2C, etc"
                      name="Biz Model"
                      isClearable={true}
                      styles={customStyles} // Apply the custom styles
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setBizModel(item);
                      }}
                      value={BizModel ? BizModel : ""}
                      options={UniBuzzModelStringresult}
                    />
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Location
                    </label>
                    <GooglePlacesSelect
                      value={Location}
                      styles={customStyles} // Apply the custom styles
                      onChange={(location) => setLocation(location)}
                      placeholder={"Current Location"}
                    />
                  </div>
                  <div className="w-full">
                    <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                      Past Roles
                    </label>
                    <div class="flex flex-wrap items-start">
                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Founder"}
                          checked={RoleModel?.includes("Founder")}
                          onChange={(e) => handleRoleArray(e)}
                          id="Founder"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Founder"
                        >
                          Founder
                        </label>
                      </div>
                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"NED"}
                          checked={RoleModel?.includes("NED")}
                          onChange={(e) => handleRoleArray(e)}
                          id="NED"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="NED"
                        >
                          NED
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Chair"}
                          checked={RoleModel?.includes("Chair")}
                          onChange={(e) => handleRoleArray(e)}
                          id="Chair"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Chair"
                        >
                          Chair
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Advisor"}
                          checked={RoleModel?.includes("Advisor")}
                          onChange={(e) => handleRoleArray(e)}
                          id="Advisor"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Advisor"
                        >
                          Advisor
                        </label>
                      </div>

                    </div>
                  </div>
                  <div className="w-full" style={{paddingTop: '2px'}}>
                    <label className="block w-full text-base font-medium mb-2 sm:mb-3 pt-2">
                      Stage Experience
                    </label>
                    <div class="flex flex-wrap items-start">
                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Pre-Seed"}
                          checked={StageModel?.includes("Pre-Seed")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Pre-Seed"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Pre-Seed"
                        >
                          Pre-Seed
                        </label>
                      </div>
                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Seed"}
                          checked={StageModel?.includes("Seed")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Seed"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Seed"
                        >
                          Seed
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series A"}
                          checked={StageModel?.includes("Series A")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series-A"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series-A"
                        >
                          Series A
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series B"}
                          checked={StageModel?.includes("Series B")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series-B"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series-B"
                        >
                          Series B
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series C"}
                          checked={StageModel?.includes("Series C")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series-C"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series-C"
                        >
                          Series C
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series D"}
                          checked={StageModel?.includes("Series D")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series D"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series D"
                        >
                          Series D
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series E"}
                          checked={StageModel?.includes("Series E")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series E"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series E"
                        >
                          Series E
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Pre-IPO"}
                          checked={StageModel?.includes("Pre-IPO")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Pre-IPO"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Pre-IPO"
                        >
                          Pre-IPO
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Public"}
                          checked={StageModel?.includes("Public")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Public"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Public"
                        >
                          Public
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {toggleFilter && (
                  <div className="search-bar small-filter absolute lg:hidden inset-x-0 top-14 md:top-30 lg:top-auto max-h-[400px] md:max-h-[none] overflow-auto lg:overflow-visible block bg-white lg:bg-transparent shadow-xl lg:shadow-none rounded-lg z-10 lg:z-0 p-4 lg:p-0">
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2">
                        Main Expertise
                      </label>
                      <Select
                        placeholder="Product, Marketing, etc"
                        name="Expertise"
                        isClearable={true}
                        getOptionLabel={(item) => item?.currItem}
                        getOptionValue={(item) => item?.currItem}
                        onChange={(item) => {
                          setDisableInitialAnimation(true);
                          setExper(item);
                        }}
                        value={Exper ? Exper : ""}
                        options={AdvisoryExpertiesstring}
                      />
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2">
                        Speciality
                      </label>
                      <Select
                        placeholder="Demand Gen, IPO, Fundraising"
                        name="Demand Gen, IPO, Fundraising"
                        isClearable={true}
                        getOptionLabel={(item) => item?.currItem}
                        getOptionValue={(item) => item?.currItem}
                        onChange={(item) => {
                          setDisableInitialAnimation(true);
                          setSpecialityModel(item);
                        }}
                        value={SpecialityModel ? SpecialityModel : ""}
                        options={UniDesiredLocStringresult}
                      />
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2">
                        Biz Model Experience
                      </label>
                      <Select
                        placeholder="B2B, B2C, etc"
                        name="Biz Model"
                        isClearable={true}
                        getOptionLabel={(item) => item?.currItem}
                        getOptionValue={(item) => item?.currItem}
                        onChange={(item) => {
                          setDisableInitialAnimation(true);
                          setBizModel(item);
                        }}
                        value={BizModel ? BizModel : ""}
                        options={UniBuzzModelStringresult}
                      />
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                        Location
                      </label>
                      <GooglePlacesSelect
                        value={Location}
                        onChange={(location) => setLocation(location)}
                        placeholder={"Current Location"}
                      />
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                        Stage Experience
                      </label>
                      <div class="flex flex-wrap items-start">
                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Pre-Seed"}
                            checked={StageModel?.includes("Pre-Seed")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Pre-Seed"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Pre-Seed"
                          >
                            Pre-Seed
                          </label>
                        </div>
                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Seed"}
                            checked={StageModel?.includes("Seed")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Seed"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Seed"
                          >
                            Seed
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series A"}
                            checked={StageModel?.includes("Series A")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series-A"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series-A"
                          >
                            Series A
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series B"}
                            checked={StageModel?.includes("Series B")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series-B"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series-B"
                          >
                            Series B
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series C"}
                            checked={StageModel?.includes("Series C")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series-C"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series-C"
                          >
                            Series C
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series D"}
                            checked={StageModel?.includes("Series D")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series D"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series D"
                          >
                            Series D
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series E"}
                            checked={StageModel?.includes("Series E")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series E"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series E"
                          >
                            Series E
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Pre-IPO"}
                            checked={StageModel?.includes("Pre-IPO")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Pre-IPO"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Pre-IPO"
                          >
                            Pre-IPO
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-greynew-500 
                          checked:bg-deep-greynew-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Public"}
                            checked={StageModel?.includes("Public")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Public"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Public"
                          >
                            Public
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </aside>
        )}
        {FormPopup == true ? (
          <div className="CandidatePageDetailsSlideWrapper show" style={FormPopup == true ? {zIndex: "9999"}: null}>
        <FormModleAdvisors VentureData={VentureData} handleCandidatePopup={handleCandidatePopup} setApistatus={setApistatus} uniqueId={uniqueId} storedCompanyName={storedCompanyName} />
        </div>
        ) : null}
        <div
          className={
            showModel == true
              ? "CandidatePageDetailsSlideWrapper show"
              : "CandidatePageDetailsSlideWrapper"
          }
        >
          {showModel == true && (
            <div className="CandidatePageDetails">
              <NewAdvisoryDetails
                cardData={newCardDataId.CanInfo}
                candidateId={newCardDataId?.CanInfo?._id?.$oid}
                userRole={userRole}
                showModel={showModel}
                setShowModel={setShowModel}
                authentication_ref={authentication_ref}
                userCompanys={userCompanys}
                setRefresh={setRefresh}
                refresh={refresh}
                userDetails={WholeUserDetails}
                uniqueId={uniqueId}
                Token={Token}
                UserCase={UserCase}
                activeIndex={activeIndex}
                handleModelClose={handleModelClose}
              />
            </div>
          )}
        </div>

        {showModel == false && (
          <div
            className="lg:ml-[370px] pt-[12rem] sm:pt-48 lg:pt-24 relative"
            key={window.location.pathname}
          >
            {/* <div class="bg-green-100 rounded-lg py-5 px-6 mb-3 max-w-sm mx-auto text-base text-green-700 inline-flex items-center w-full mx-auto" role="alert">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
        </svg>
        Student Added Successfully
      </div> */}
            {/* ---------> disabling banner section for now <-------------- */}
            {/* {bannerSection == false ? (
                <div
                  className="c-information-area flex md:flex-row relative mx-5"
                >
                  <div className="flex md:flex-1 md:pr-4">
                    <h3>👋 Hey {FirstName?.charAt(0)?.toUpperCase() + FirstName?.slice(1)}! Welcome to the {storedCompanyName == "Startup Lane" ? "Startup Lane" : storedCompanyName == "Global VC Talent Network" ? "Global Venture Capital" : storedCompanyName}  Talent Network.</h3>
                  </div>
                  <a
                    href="javascript:void(0);"
                    onClick={handleBannerClose}
                    className="btn-more-txt w-auto md:justify-end align-center flex md:text-right"
                  >
                    <button type="button" aria-label="Close">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </a>
                </div>
            ) : null} */}

            {/* <button className="ml-5 mb-5" onClick={handlePopUpOpen}>hello</button> */}
            {/* <motion.section
              initial={disableInitialAnimation ? false : { x: "0%" }} // Disable initial animation
              animate={{ x: "0%" }}
              exit={{ opacity: 1 }}
              transition={{ ease: "easeOut", duration: 0.35 }}
              key={window.location.pathname + refresh} // Adding refresh to the key
              className="flex flex-wrap px-2"
            > */}
            <div className="flex flex-wrap px-2">
              {authentication_ref?.current?.advisory_data?.loading ||
              (authentication_ref?.current?.advisory_data?.loading &&
                filterData.length <= 0) ? (
                <SkeletonCards />
              ) : (
                filterData?.slice(0, visible).map((item, index) => {
                  return (
                    <div
                      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/4 px-2.5 mb-5"
                      key={index}
                      id={`can-${index}`}
                      onMouseEnter={() => getFullProfile(item?._id?.$oid)}
                      onClick={(e) => {
                        if (!e.target.classList.contains("smallCardName")) {
                          handleClickEvent(index, e);
                        }
                      }}
                    >
                      <div
                        className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4"
                        ref={dummy}
                      >
                        <div className="flex border-b border-gray-300 pb-3 mb-2">
                          <figure
                            className={
                              item.profilepic
                                ? "shrink-0 bg-deep-purple-50 rounded-full w-12 md:w-[60px]  h-12 md:h-[60px] inline-flex items-start justify-center overflow-hidden"
                                : "shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center"
                            }
                            style={{ width: "60px", height: "60px" }}
                          >
                            {item.profilepic ? (
                              <img
                                className="max-w-full h-auto min-h-full rounded-full"
                                // src={`https://cnp1656-dev-image.s3.amazonaws.com/advisorprofilepic/${item.profilepic}`}
                                src={`https://cnp1656-prod.s3.eu-west-2.amazonaws.com/profilepic/${item.profilepic}`}
                                alt={item?.firstname + " " + item?.lastname}
                              />
                            ) : (
                              <span className="text-lg text-deep-purple-500 font-medium">
                                {item?.firstname[0]}
                                {item?.lastname[0]?.toUpperCase()}
                              </span>
                            )}
                          </figure>
                          <figcaption className="w-full px-4 self-center overflow-hidden">
                            <div
                              className="text-lg smallCardName text-base-color font-bold truncate leading-4"
                              style={{ lineHeight: "1.35rem" }}
                              onClick={(e) => {
                                handleNameClick(e, item);
                              }}
                            >
                              {toUpperCaseNameFuntion(item)}
                            </div>
                            <div
                              className="text-sm text-base-color truncate"
                              style={{ lineHeight: "1.225rem" }}
                            >
                              {item?.about?.functionalexpertise?.replace(
                                /,/g,
                                ", "
                              )}
                              {/* {upperCaseWorkFuntion(item)} */}
                            </div>
                            <div
                              className="text-sm truncate"
                              style={{
                                color: "#70747A",
                                lineHeight: "1.225rem",
                              }}
                            >
                              {item?.about?.currentLocation?.formatted_address}
                              {/* {upperCaseEmployer(item)} */}
                            </div>
                          </figcaption>
                        </div>
                        <ul className="text-sm text-base-color pt-1">
                          <li className="flex items-center group mb-1">
                            <span className="shrink-0 mr-2">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.90918 14.0469V16.5031C3.90918 16.6713 4.04528 16.8299 4.26017 16.9164C4.4751 17.0029 4.74727 17.0077 4.96932 16.926L6.78164 16.2627V13.0664L4.30315 13.9412C4.17422 13.9845 4.04528 14.0229 3.90918 14.0469Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M8.21582 13.082V16.2791L10.0236 16.9426C10.2435 17.0231 10.514 17.0182 10.731 16.933C10.9453 16.8464 11.0811 16.6878 11.0811 16.5195V14.0628C10.9453 14.0387 10.8167 14.0003 10.6881 13.957L8.21582 13.082Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M14.9641 4.96226C14.8853 4.72468 14.6561 4.55314 14.391 4.51355L10.0716 3.93942L8.14471 0.336533C7.90114 -0.112178 7.09886 -0.112178 6.85529 0.336533L4.92838 3.93942L0.608978 4.51355C0.343942 4.55314 0.114713 4.72468 0.0359284 4.96226C-0.0500269 5.20641 0.021588 5.46373 0.215009 5.64191L3.33817 8.44638L2.60033 12.4056C2.55735 12.6498 2.66482 12.9005 2.88688 13.0523C3.10894 13.1975 3.40265 13.2173 3.63901 13.0985L6.78368 11.5808L7.5 11.2311L8.21632 11.5808L11.361 13.0985C11.6003 13.2221 11.8951 13.1949 12.1131 13.0523C12.3352 12.9005 12.4426 12.6498 12.3997 12.4056L11.6618 8.44638L14.785 5.64191C14.9784 5.46373 15.05 5.20641 14.9641 4.96226Z"
                                  fill="#2D3958"
                                />
                              </svg>
                            </span>
                            <span className="truncate">
                              {handleIndustries(item)}
                            </span>
                            {/* {StyleFuntion(item)} */}
                          </li>
                          <li className="flex items-center group mb-1">
                            <span className="shrink-0 mr-2">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.41586 10.7807C4.15572 10.5211 4.05775 10.6515 3.99989 10.7094C2.50186 12.2052 1.17194 15.8144 1.87501 15.4619C4.43448 14.1787 4.86094 14.5607 6.35891 13.065C6.41683 13.0072 6.54999 12.9118 6.28228 12.6445L4.41586 10.7807Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M12.4917 10.7629C12.4238 10.5472 12.566 10.4433 12.6259 10.3787C15.9322 6.8144 18.0335 2.06559 16.4763 0.510599C14.9273 -1.03602 10.3182 1.1235 6.74966 4.40115C6.67906 4.46608 6.53991 4.58904 6.29097 4.53713L5.17914 4.27278C4.87045 4.19947 4.43427 4.32268 4.20989 4.54674L0.130242 8.62037C-0.0941394 8.84448 -0.0212383 9.06807 0.292262 9.11722L3.24294 9.58024C3.55644 9.62949 3.99651 9.4864 4.22084 9.26229C4.22084 9.26229 4.37308 9.10986 4.52605 9.26255C5.36996 10.1051 6.93818 11.6711 7.74219 12.4739C7.90201 12.6335 7.74894 12.7852 7.74894 12.7852C7.52451 13.0094 7.38126 13.4487 7.43058 13.7618L7.89423 16.7081C7.9435 17.0212 8.16742 17.094 8.3918 16.8699L12.4715 12.7962C12.6958 12.5721 12.8193 12.1367 12.7458 11.8284L12.4917 10.7629ZM11.5783 5.50002C10.8873 4.81002 10.8873 3.69144 11.5783 3.00155C12.2693 2.31156 13.3895 2.31156 14.0805 3.00155C14.7714 3.69139 14.7714 4.81002 14.0805 5.49996C13.3895 6.18995 12.2692 6.18995 11.5783 5.50002Z"
                                  fill="#2D3958"
                                />
                              </svg>
                            </span>
                            <span className="truncate">
                              {handleStageExperience(item)}
                              {/* {item?.careerplan?.stage_experience.replace(/,/g, ', ')} */}
                              {/* {handleCarrelPlan(item)} */}
                            </span>
                          </li>
                          <li className="flex items-center group">
                            <span className="shrink-0 mr-2">
                              <svg
                                width="19"
                                height="16"
                                viewBox="0 0 19 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.8848 9.81844C15.8556 9.81844 14.9448 9.32427 14.394 8.57031C13.8432 9.32427 12.9323 9.81844 11.9032 9.81844C10.874 9.81844 9.96315 9.32423 9.41234 8.57031C8.86153 9.32427 7.95072 9.81844 6.92153 9.81844C5.89234 9.81844 4.98153 9.32427 4.43072 8.57031C3.87991 9.32427 2.9691 9.81844 1.93991 9.81844C1.64984 9.81844 1.36947 9.77838 1.10352 9.70516V16.0019H17.7212V9.70516C17.4552 9.77838 17.1748 9.81844 16.8848 9.81844Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M13.8086 0L14.8562 5.76401H18.5975L16.5023 0H13.8086Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M9.96289 5.76401H13.7369L12.6893 0H9.96289V5.76401Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M8.85799 5.76401V0H6.13159L5.08398 5.76401H8.85799Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M3.96593 5.76401L5.01351 0H2.31976L0.224609 5.76401H3.96593Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M1.93934 8.75405C3.00871 8.75405 3.87868 7.91791 3.87868 6.89019V6.82422H0V6.89019C0 7.91791 0.869963 8.75405 1.93934 8.75405Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M8.8611 6.89019V6.82422H4.98242V6.89019C4.98242 7.91791 5.85239 8.75405 6.92176 8.75405C7.99114 8.75405 8.8611 7.91791 8.8611 6.89019Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M13.8416 6.89019V6.82422H9.96289V6.89019C9.96289 7.91791 10.8329 8.75405 11.9022 8.75405C12.9716 8.75405 13.8416 7.91791 13.8416 6.89019Z"
                                  fill="#2D3958"
                                />
                                <path
                                  d="M16.8847 8.75405C17.954 8.75405 18.824 7.91791 18.824 6.89019V6.82422H14.9453V6.89019C14.9453 7.91791 15.8153 8.75405 16.8847 8.75405Z"
                                  fill="#2D3958"
                                />
                              </svg>
                            </span>
                            <span className="truncate">
                            {item?.careerplan?.business_model_experience == "" ? "Unknown" : item?.careerplan?.business_model_experience.replace(
                                /,/g,
                                ", "
                              )}
                              {/* {item?.careerplan?.business_model_experience.replace(
                                /,/g,
                                ", "
                              )} */}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            {/* </motion.section> */}

            {/* Skeleton Loader */}

            {/* End Skeleton Loader */}
            {/* Loadmore for the candidates */}

            <div className="text-center px-4 mt-5 max-w-full w-full">
              {TestingNumber == filterData?.length ? null : (
                <>
                  {authentication_ref?.current?.advisory_data?.loading ? (
                    <button
                      type="button"
                      className="text-gray-1000 flex items-center mx-auto"
                      disabled
                    >
                      <span
                        className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-deep-purple-500 border-l-transparent rounded-full"
                        role="status"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    filterData?.length > 19 && (
                      <button
                        type="button"
                        value={size}
                        className="bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-2 px-4 mx-auto mb-2"
                        onClick={(e) => {
                          setDisableInitialAnimation(true);
                          loadmoreHander(e);
                        }}
                      >
                        Load more advisors
                      </button>
                    )
                  )}
                </>
              )}

              <span className="block text-gray-400">
                Showing{" "}
                {filterData?.length < 20
                  ? filterData?.length
                  : filterData?.length < visible
                  ? filterData.length
                  : visible}{" "}
                of {filterData?.length}
              </span>
            </div>
          </div>
        )}

        {showModel != true && (
          <footer className="lg:ml-[370px] text-center mt-6 pb-5">
            <h6>
              <small className="text-base text-gray-400">
                &copy; Startup Lane 2024
              </small>
            </h6>
          </footer>
        )}
        {showPrimiumModel == true && ShowPaymentModel()}
      </div>
    </>
  );
};

AdvisoryDashboard.propTypes = {
  // fetchDashboard: PropTypes.func,
  fetchAdvisoryDashboard: PropTypes.func,
  fetchVentureData: PropTypes.func,
  fetchCompanyDash: PropTypes.func,
  fetchRegisteredCandidate: PropTypes.func,
  fetchUniqueLink: PropTypes.func,
  fetchUSerDetails: PropTypes.func,
  fetchRoles: PropTypes.func,
  fetchDesire: PropTypes.func,
  fetchExperience: PropTypes.func,
  fetchGlobalSearch: PropTypes.func,
  UserLogout: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  // fetchDashboard: getDash,
  fetchAdvisoryDashboard: getAdvisoryDash,
  fetchVentureData: getPortfolioComp,
  fetchCompanyDash: getCompanyDash,
  fetchRegisteredCandidate: getCompanyData,
  fetchRoles: getRoles,
  fetchUniqueLink: getUniqueLink,
  fetchUSerDetails: getUserDetails,
  fetchDesire: getDesire,
  fetchExperience: getExperience,
  fetchGlobalSearch: getGlobalSearch,
  UserLogout: getLogOut,
})(AdvisoryDashboard);

import React, { useEffect, useState, useRef } from "react";
import "../Login/index.css";
import { connect } from "react-redux";
import { setCookie, getCookie } from "../../shared/utils/cookies";
import NewCandidatePage from "./singleton/NewCAndidatePage";
import { Helmet } from "react-helmet";
import SkeletonCards from "./singleton/SkeletonCards.js/SkeletonCards";
import { PropTypes, element } from "prop-types";
import "./custom-scrollbar.css"; // Import your custom scrollbar styles
import { motion } from "framer-motion";
import image1 from "./images/closedEye.svg";
import image2 from "./images/eye.svg";
import axios from "axios";
import {
  ExpertiseMapStringresult,
  Unqiiidesire,
  uniqueLevelresult,
  uniqueBizzModel,
  uniqueNewStage,
  uniqueLocationresult,
} from "../../shared/utils/lists";
import {
  getDash,
  getNewDash,
  getPortfolioComp,
  getCompanyDash,
  getCompanyData,
  getUniqueLink,
  getUserDetails,
  getLogOut,
  getRoles,
  getDesire,
  getExperience,
  getGlobalSearch,
} from "../authentication";
import HeaderUIDash from "./singleton/HeaderDash";
import HeaderUI from "./singleton/Header";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import copy from "copy-to-clipboard";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { getFullProfile } from "../../shared/httpService/apis/enrichedApi";
import { funtion } from "../../shared/utils/funtion";
import BannerModule from "../BannerModule";
import PrimiumModule from "../PrimiumModule";
import {
  GooglePlacesSelect,
  searchOverGooglePlacesObject,
} from "../../shared/components/googlePlaces";
import FormModle from "./singleton/FormModle";
import EditCandidate from "./singleton/EditCandidate";
import AddVc from "./singleton/AddVC";

const Dashboard = ({
  authentication_state,
  fetchDashboard,
  fetchNewDashboard,
  fetchVentureData,
  fetchRegisteredCandidate,
  fetchUniqueLink,
  fetchUSerDetails,
  fetchRoles,
  UserLogout,
  fetchGlobalSearch,
}) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const uniqueId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.id || "";
  const uniqueRoleId =
    authentication_ref?.current?.retailer_auth?.response?.data?.data?.role_id ||
    "";
  const UserCase =
    authentication_ref.current?.User?.response?.data?.data?.access || "";
    const effectRan = useRef(false);
  const Token = getCookie("access_token");
  const history = useHistory();
  const menuRef = useRef();
  const buttonRef = useRef();
  const dummy = useRef(null);
  const [visible, setVisible] = useState(20);
  const [dashData, setDashData] = useState([]);
  const [editCandidate, setEditCandidate] = useState(false)
  const [filterData, setFilterData] = useState([]);
  const [FormPopup, setFormPopup] = useState(false);
  const [userRole, setUserRole] = useState();
  const [disableInitialAnimation, setDisableInitialAnimation] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [apistatus, setApistatus] = useState(0)
  const [userCompany, setUserCompany] = useState();
  const [vcDomain, setVCDomain] = useState();
  const [userCompanys, setUserCompanys] = useState([]);
  const [MultiUserComp, setMultiUserComp] = useState(); // If there are Multiple role funtionality
  const [userCompanyId, setUserCompanyId] = useState();
  const [addCandidatePopUp, setAddCAndidatePopUp] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [PaymentPop, setPaymentPop] = useState(false);
  const [events, setEvents] = useState("");
  const [buttonShows, setButoonShows] = useState(false);
  const [profileIcon, setProfileIcon] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [VC, setVC] = useState();
  const [Exper, setExper] = useState(null);
  const [Experience, setExperenece] = useState(null);
  const [Level, setLevel] = useState([]);
  const [BizModel, setBizModel] = useState(null);
  const [StageModel, setStageModel] = useState([]);
  const [SourceArray, setSourceArray] = useState([]);
  const [Location, setLocation] = useState(null);
  const [colorData, setColorData] = useState([]);
  const [userDetails, setUserDetails] = useState("");
  const [WholeUserDetails, setWholeUserDetails] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [showPrimiumModel, setShowPrimiumModel] = useState(false);
  const [connectScollModel, setconnectScoll] = useState(false);
  const [listModel, setListModel] = useState(false);
  const [VCModel, setVCModel] = useState(false);
  const [bannerSection, setBannerSection] = useState();
  const [FirstName, setFirstName] = useState("");
  const [VentureData, setVentureData] = useState()
  const storedCompanyId = localStorage.getItem("companyId");
  const storedCompanyName = localStorage.getItem("companyName");
  const storedAdvisory = localStorage.getItem("AdvisoryDetails");
  const MainCompanyId = localStorage.getItem("MainCompanyId");
  const MultiUserCompany = localStorage.getItem("MultiUserComp");
  const errorHandling =
    authentication_ref?.current?.dash_data?.error?.response?.status || null;
  // const tagManagerArgs = {
  //   gtmId: "GTM-KQP82DD",
  //   dataLayer: {
  //     userId: uniqueId,
  //     userProject: "StartUpLane",
  //   },
  // };
  // TagManager.initialize(tagManagerArgs);
  // TagManager.dataLayer(tagManagerArgs);

  const [size, setSize] = useState(20);
  const [cardData, setCardData] = useState({
    CanInfo: null,
  });
  const [newCardDataId, setNewCardDataId] = useState({
    CanInfo: null,
  });


  // experties string........ 

  const DesiredLoc = filterData?.map((item) => {
    return item?.careerplan?.industry_experience;
  });
  
  let uniquesDesired = DesiredLoc?.filter((a, index) => {
    return DesiredLoc?.indexOf(a) === index;
  });
  
  let DesiredLocString = uniquesDesired?.toString()?.split(",");
  
  let UniDesiredLocSet = new Set();
  
  let UniDesiredLocStringresult = DesiredLocString?.reduce((acc, currItem) => {
    // Trim spaces and check if the value is not an empty string before pushing it
    const trimmedItem = currItem.trim();
    if (trimmedItem !== '' && !UniDesiredLocSet.has(trimmedItem)) {
      acc.push({ currItem: trimmedItem });
      UniDesiredLocSet.add(trimmedItem);
    }
    return acc;
  }, []);

  // Sort the array alphabetically based on the 'currItem' key
  UniDesiredLocStringresult?.sort((a, b) => a.currItem.localeCompare(b.currItem));
  
  // experties string........ 

  const DesiredBuzzModel = filterData?.map((item) => {
    return item?.careerplan?.kind_of_business;
  });
  
  let uniquesDesiredBuzzModel = DesiredBuzzModel?.filter((a, index) => {
    return DesiredBuzzModel?.indexOf(a) === index;
  });
  
  let DesiredLocStringBuzzModel = uniquesDesiredBuzzModel?.toString()?.split(",");
  
  let UniDesiredLocSetBuzzModel = new Set();
  
  let UniDesiredLocStringresultBuzzModel = DesiredLocStringBuzzModel?.reduce((acc, currItem) => {
    // Trim spaces and check if the value is not an empty string before pushing it
    const trimmedItem = currItem.trim();
    if (trimmedItem !== '' && !UniDesiredLocSetBuzzModel.has(trimmedItem)) {
      acc.push({ currItem: trimmedItem });
      UniDesiredLocSetBuzzModel.add(trimmedItem);
    }
    return acc;
  }, []);

  // Sort the array alphabetically based on the 'currItem' key
  UniDesiredLocStringresultBuzzModel?.sort((a, b) => a.currItem.localeCompare(b.currItem));
  
  

  // ******** Ends ***********

  useEffect(() => {
    fetchUSerDetails(uniqueId).then(() => {
      if (authentication_ref?.current?.User?.response.status == 200) {
        setUserDetails(
          authentication_ref?.current?.User?.response?.data?.data?.access
        );
        setWholeUserDetails(
          authentication_ref?.current?.User?.response?.data?.data
        );
        localStorage.setItem(
          "usersIdentity",
          authentication_ref?.current?.retailer_auth?.response?.data?.data?.id
        );
        setBannerSection(
          authentication_ref?.current?.User?.response?.data?.data?.banner
        );
        setFirstName(
          authentication_ref?.current?.User?.response?.data?.data?.firstname
        );
      }
    });
  }, []);

  //Calling venture data
  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${uniqueId}/`,
        headerData
      ).then((res) => {
        setVentureData(res?.data?.data)
      })
  }, [])

  useEffect(() => {
    // Update the userID value in the script tag
    const plausibleScript = document.getElementById("plausible-script");
    plausibleScript.setAttribute("event-userid", uniqueId); // Use 'event-userid' instead of 'event-userID'
    plausibleScript.setAttribute("defer", true);
  }, [uniqueId]);

  useEffect(() => {
    // Update the userID value in the script tag
    const plausibleScripts = document.getElementById("plausible-script");
    if (userCompany == undefined) {
      plausibleScripts.setAttribute("event-vcName", "Startup Lane"); // Use 'event-vcName' instead of 'event-vcName'
    } else if (userCompany?.company_name == "Octopus Ventures") {
      plausibleScripts.setAttribute("event-vcName", "Octopus Ventures");
    } else if (
      userCompany?.company_name == "Global Venture Capital Talent Network"
    ) {
      plausibleScripts.setAttribute("event-vcName", "GVCTN");
    } else if (userCompany?.company_name == "Human Capital") {
      plausibleScripts.setAttribute("event-vcName", "Human Capital");
    } else if (userCompany?.company_name == "M13") {
      plausibleScripts.setAttribute("event-vcName", "M13");
    } else if (userCompany?.company_name == "Oak HC/FT") {
      plausibleScripts.setAttribute("event-vcName", "Oak HC/FT");
    }
  }, []);

  useEffect(() => {
    setGlobalSearch("");
    setExper(null);
    setExperenece(null);
    setLevel([]);
    setBizModel(null);
    setLocation(null);
    setStageModel([]);
    setSourceArray([]);
  }, [storedCompanyName]);

  useEffect(() => {
    fetchVentureData(uniqueId).then(() => {
      if (authentication_ref?.current?.port_data?.response?.status == 200) {
        //Storing Main user Company id
        if (authentication_ref?.current?.port_data?.response?.data?.data?.length > 1) {
          localStorage.setItem(
            "MainCompanyId",
            authentication_ref?.current?.port_data?.response?.data?.data[1]?.id
          );
          localStorage.setItem(
            "MultiUserComp",
            authentication_ref?.current?.port_data?.response?.data?.data[1]
              ?.company_name
          );
          // setMultiUserComp(authentication_ref?.current?.port_data?.response?.data?.data[1].company_name)
        } else {
          localStorage.setItem(
            "MainCompanyId",
            authentication_ref?.current?.port_data?.response?.data?.data[0]?.id
          );
          localStorage.setItem(
            "MultiUserComp",
            authentication_ref?.current?.port_data?.response?.data?.data[0]
              ?.company_name
          );
          // setMultiUserComp(authentication_ref?.current?.port_data?.response?.data?.data[0].company_name)
        }
        setUserCompany(
          authentication_ref?.current?.port_data?.response?.data?.data[0]
        );
        setVCDomain(
          authentication_ref?.current?.port_data?.response?.data?.data[0]?.contact_address.split('@')[1].toLowerCase()
        );
        setUserCompanys(
          authentication_ref?.current?.port_data?.response?.data?.data
        );
        if (storedCompanyName == undefined) {
          localStorage.setItem(
            "companyName",
            authentication_ref?.current?.port_data?.response?.data?.data[0]
              ?.company_name
          ); // Store in local storage
          // console.log('Auth response data: ' + JSON.stringify(authentication_ref?.current?.port_data?.response?.data?.data) + "\n")
        }
        if(storedAdvisory == undefined){
          localStorage.setItem(
            "AdvisoryDetails",
            authentication_ref?.current?.port_data?.response?.data?.data[0]
              ?.advisors
          ); // Store in local storage
        }
        if (storedCompanyId) {
          setUserCompanyId(storedCompanyId);
        } else {
          const initialCompanyId =
            authentication_ref?.current?.port_data?.response?.data?.data[0]?.id;
          localStorage.setItem("companyId", initialCompanyId); // Store in local storage
          setUserCompanyId(initialCompanyId);
        }
      }
    });
  }, []);

  // 04082

  useEffect(() => {
    //  if (effectRan.current) return; // Exit if the effect has already run
    if (storedCompanyId) {
      fetchNewDashboard(storedCompanyId).then(() => {
        if (
          authentication_ref?.current?.dash_new_data?.response?.status == 200
        ) {
          setDashData(
            authentication_ref?.current?.dash_new_data?.response?.data
          );
          setFilterData(
            authentication_ref?.current?.dash_new_data?.response?.data
          );
          setColorData(
            authentication_ref?.current?.dash_new_data?.response?.data
          );
          localStorage.setItem(
            "userData",
            authentication_ref?.current?.dash_new_data?.response?.data
          );
        }
      });
    } else {
      fetchNewDashboard(userCompanyId).then(() => {
        if (
          authentication_ref?.current?.dash_new_data?.response?.status == 200
        ) {
          setDashData(
            authentication_ref?.current?.dash_new_data?.response?.data
          );
          setFilterData(
            authentication_ref?.current?.dash_new_data?.response?.data
          );
          setColorData(
            authentication_ref?.current?.dash_new_data?.response?.data
          );
          localStorage.setItem(
            "userData",
            authentication_ref?.current?.dash_new_data?.response?.data
          );
        }
      });
    }
    // effectRan.current = true; // Mark the effect as run
  }, [userCompanyId, storedCompanyId]);

  useEffect(() => {
    if (apistatus == 1) {
      if(storedCompanyId){
        fetchNewDashboard(storedCompanyId).then(() => {
          if (
            authentication_ref?.current?.dash_new_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setApistatus(0)
          }
        });
      } else{
        fetchNewDashboard(userCompanyId).then(() => {
          if (
            authentication_ref?.current?.dash_new_data?.response?.status == 200
          ) {
            setDashData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setFilterData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setColorData(
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            localStorage.setItem(
              "userData",
              authentication_ref?.current?.dash_new_data?.response?.data
            );
            setApistatus(0)
          }
        });
      }
    }
  }, [apistatus]);

  // useEffect(() => {
  //   Update the userID value in the script tag
  //   const plausibleScript = document.getElementById('plausible-script');
  //   plausibleScript.setAttribute('event-userID', uniqueId);
  // }, [uniqueId])

  useEffect(() => {
    if (userDetails == "noaccess") {
      UserLogout();
      setCookie("access_token", "");
      window.localStorage.removeItem("Token");
      window.location.href = "/";
    }
  }, [userDetails]);

  useEffect(() => {
    if (errorHandling == 401) {
      UserLogout();
      setCookie("access_token", "");
      window.localStorage.removeItem("Token");
      window.location.href = "/";
    }
  }, [errorHandling]);

  //FETCHING REGISTERED CANDIDTE DETAILS
  // useEffect(() => {
  //   const storedCompanyId =
  //     localStorage.getItem("companyId") ||
  //     authentication_ref?.current?.port_data?.response?.data?.data[0]?.id;
  //   fetchRegisteredCandidate(storedCompanyId);
  // }, []);

  const handleRagistrationLink = () => {
    const RolebasedName = VentureData?.url_shortname
    window.open(`${window.location.origin}/${RolebasedName}/register`);
    // if (storedCompanyName == "Octopus Ventures") {
    //   window.open(`${window.location.origin}/OctopusVentures/register`);
    // } else if (storedCompanyName == "Anzu Partners") {
    //   window.open(`${window.location.origin}/AnzuPartners/register`);
    // } else if (storedCompanyName == "Burnt Island Ventures") {
    //   window.open(`${window.location.origin}/BurntIslandVentures/register`);
    // } else if (storedCompanyName == "M13") {
    //   window.open(`${window.location.origin}/M13/register`);
    // } else if (storedCompanyName == "Human Capital") {
    //   window.open(`${window.location.origin}/HumanCapital/register`);
    // } else if (storedCompanyName == "Global VC Talent Network") {
    //   window.open(`${window.location.origin}/GVCTN/register`);
    // } else if (storedCompanyName == "Better Tomorrow Ventures") {
    //   window.open(`${window.location.origin}/BTV/register`);
    // } else if (storedCompanyName == "Left Lane Capital") {
    //   window.open(`${window.location.origin}/LLC/register`);
    // } else if (storedCompanyName == "Demo VC") {
    //   window.open(`${window.location.origin}/Demo-VC/register`);
    // } else if (storedCompanyName == "Upfront Ventures") {
    //   window.open(`${window.location.origin}/UpfrontVentures/register`);
    // } else if (storedCompanyName == "Startup Lane") {
    //   window.open(`${window.location.origin}/startupLane/register`);
    // }
  };

  const handlemanageTeamMember = () => {
    window.open(`${window.location.origin}/manage/team-member`);
  };

  //FETCHING ROLES OF THE USER
  useEffect(() => {
    fetchRoles(uniqueRoleId).then(() => {
      if (authentication_ref?.current?.Roles_data?.response?.status == 200) {
        setUserRole(
          authentication_ref?.current?.Roles_data?.response?.data?.data
            ?.rolename
        );
      }
    });
  }, []);

  useEffect(() => {
    function handleResize() {
      const newVisible = calculateVisible();
      setVisible(newVisible);
    }

    function calculateVisible() {
      // You can adjust these values according to your needs
      if (window.innerWidth > 1526) {
        return 40;
      } else {
        return 42;
      }
    }

    // Initial calculation
    const initialVisible = calculateVisible();
    setVisible(initialVisible);

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // DASHBOARD DATA OF THE CANDIDATE
  // useEffect(() => {
  //   fetchDashboard().then(() => {
  //     if (authentication_ref?.current?.dash_data?.response?.status == 200) {
  //       setDashData(
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //       setFilterData(
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //       setColorData(
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //       localStorage.setItem(
  //         "userData",
  //         authentication_ref?.current?.dash_data?.response?.data?.data?.data
  //       );
  //     }
  //   });
  // }, []);

  //Candidates Filter
  useEffect(() => {
    if (dashData != undefined) {
      let _dashData = [...dashData];
      if (globalSearch) {
        const trimmedSearch = globalSearch?.toLowerCase()?.trim(); // Trim and convert to lowercase once

        _dashData = _dashData.filter((user) => {
          const trimmedFirstName = user?.firstname?.toLowerCase()?.trim(); // Trim and convert to lowercase
          const trimmedLastName = user?.lastname?.toLowerCase()?.trim(); // Trim and convert to lowercase
          const fullName = `${trimmedFirstName} ${trimmedLastName}`;

          return (
            trimmedFirstName?.includes(trimmedSearch) ||
            trimmedLastName?.includes(trimmedSearch) ||
            fullName?.includes(trimmedSearch) ||
            user?.careerplan?.work?.toLowerCase()?.includes(trimmedSearch) ||
            user?.careerplan?.employer?.toLowerCase()?.includes(trimmedSearch)
          );
        });
      }
      if (Exper) {
        _dashData = _dashData?.filter(
          (item) => item?.about?.functionalexpertise == Exper?.currItem
        );
      }
      if (Level && Level?.toString()?.trim() !== "") {
        const levelList = Level?.toString()?.split(",");

        if (levelList?.length > 0) {
          _dashData = _dashData?.filter((item) => {
            const careerLevels = item?.careerplan?.careerlevel?.split(",");
            return levelList?.some((level) => careerLevels?.includes(level));
          });
        }
        // If levelList is empty, _dashData remains unchanged
      }
      if (BizModel) {
        _dashData = _dashData?.filter((item) =>
          item?.careerplan?.kind_of_business?.includes(BizModel?.currItem)
        );
      }
      if (Experience) {
        _dashData = _dashData?.filter((item) =>
          item?.careerplan?.industry_experience?.includes(Experience?.currItem)
        );
      }
      if (Location) {
        _dashData = _dashData?.filter((item) =>
          searchOverGooglePlacesObject(
            Location,
            item?.about?.currentLocation?.address_components
          )
        );
      }
      if (StageModel && StageModel?.toString()?.trim() !== "") {
        const stageList = StageModel?.toString()?.split(",");

        if (stageList?.length > 0) {
          _dashData = _dashData?.filter((item) => {
            const careerStages = item?.careerplan?.stage?.split(",");
            return stageList?.some((stage) => careerStages?.includes(stage));
          });
        }
        // If stageList is empty, _dashData remains unchanged
      }
      if (SourceArray && SourceArray?.toString()?.trim() !== "") {
        const sourceList = SourceArray?.toString()?.split(",");

        if (sourceList?.length > 0) {
          _dashData = _dashData?.filter((item) => {
            let realStringsToMatch = [];
            let vcNameMatchString = vcDomain;
            let careerFairMatchString = "Career Fair";
            let startupLaneMatchString = "startuplane.io";
            let resumeDropMatchString = "resumedrop.com";

            if (sourceList.includes("VCName")) {
              realStringsToMatch.push(vcNameMatchString)
            }
            if (sourceList.includes("CareerFair")) {
              realStringsToMatch.push(careerFairMatchString)
            }
            if (sourceList.includes("StartupLane")) {
              realStringsToMatch.push(startupLaneMatchString)
            }
            if (sourceList.includes("ResumeDrop")) {
              realStringsToMatch.push(resumeDropMatchString)
            }

            let returnValue = false;
            returnValue = realStringsToMatch.some(function(element, i) {
              let lowercaseElement = element.toLowerCase();
              return item?.referred_by?.toLowerCase().includes(lowercaseElement);
            });
            return returnValue;

          });
        }
        // If SourceArray is empty, _dashData remains unchanged
      }

      setFilterData([..._dashData]);
      setColorData([..._dashData]);
    }
  }, [globalSearch, Exper, Level, BizModel, StageModel, SourceArray, Location, Experience, dashData]);

  useEffect(() => {
    let timeout;
    if (buttonShows) {
      timeout = setTimeout(() => setButoonShows(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [buttonShows]);

  const handleCandidatePopup = () => {
    setFormPopup(false)
  }

  const handleCandidates = () => {
    if(userRole == "venturecapital" &&
    storedCompanyName !=
    "Global VC Talent Network" || userRole == "globalvc" && storedCompanyName !=
    "Global VC Talent Network"){
      setFormPopup(true)
    } 
    else
     {      
      if (storedCompanyName == "Startup Lane") {
        window.open(`${window.location.origin}/StartupLane/applyid=${uniqueId}`);
      } else if (storedCompanyName == "Global VC Talent Network") {
        window.open(`${window.location.origin}/GVCTN/applyid=${uniqueId}`);
      } else {
        const vcURLShortName = VentureData?.url_shortname
        window.open(
          `${window.location.origin}/${vcURLShortName}/applyid=${uniqueId}`
        );
      }
    }
  };

  const handleCandidateAddNew = () => {
    if (addCandidatePopUp == false) {
      setAddCAndidatePopUp(true);
      setDisableInitialAnimation(true);
    } else if (addCandidatePopUp == true) {
      setAddCAndidatePopUp(false);
      setDisableInitialAnimation(true);
    }
  };

  const handleReferCandidate = () => {
    const RolebasedName = VentureData?.url_shortname
    window.open(`${window.location.origin}/${RolebasedName}/applyid=${uniqueId}`);
 
  };

  const handleCandidateDetails = () => {
    window.open(`${window.location.origin}/Candidates/details`);
  };

  const handleCompaniesDetails = () => {
    window.open(`${window.location.origin}/companies/details`);
  };

  const handleTeammember = () => {
    if (storedCompanyName == "Startup Lane") {
      window.open(`${window.location.origin}/StartupLane/add-member`);
    } else {
      const RolebasedName =
        storedCompanyName == "Octopus Ventures"
          ? "OctopusVentures"
          : storedCompanyName == "Anzu Partners"
          ? "AnzuPartners"
          : storedCompanyName == "Burnt Island Ventures"
          ? "BurntIslandVentures"
          : storedCompanyName == "M13"
          ? "M13"
          : storedCompanyName == "Human Capital"
          ? "HumanCapital"
          : storedCompanyName == "Global VC Talent Network"
          ? "GVCTN"
          : storedCompanyName == "Better Tomorrow Ventures"
          ? "BTV"
          : storedCompanyName == "Left Lane Capital"
          ? "LLC"
          : storedCompanyName == "Demo VC"
          ? "Demo-VC"
          : storedCompanyName == "Upfront Ventures"
          ? "UpfrontVentures"
          : "StartupLane";
      // userRole == "admin" ? "StartupLane" : "OctopusVentures";
      window.open(`${window.location.origin}/${RolebasedName}/add-member`);
    }
  };

  const handleCampanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  };

  const TestingNumber =
    filterData?.length < 20
      ? filterData?.length
      : filterData?.length < visible
      ? filterData.length
      : visible;

  const handleCampanyMember = () => {
    if (storedCompanyName == "Octopus Ventures") {
      window.open(`${window.location.origin}/OctopusVentures/register`);
    } else if (storedCompanyName == "Anzu Partners") {
      window.open(`${window.location.origin}/AnzuPartners/register`);
    } else if (storedCompanyName == "Burnt Island Ventures") {
      window.open(`${window.location.origin}/BurntIslandVentures/register`);
    } else if (storedCompanyName == "M13") {
      window.open(`${window.location.origin}/M13/register`);
    } else if (storedCompanyName == "Human Capital") {
      window.open(`${window.location.origin}/HumanCapital/register`);
    } else if (storedCompanyName == "Global VC Talent Network") {
      window.open(`${window.location.origin}/GVCTN/register`);
    } else if (storedCompanyName == "Better Tomorrow Ventures") {
      window.open(`${window.location.origin}/BTV/register`);
    } else if (storedCompanyName == "Left Lane Capital") {
      window.open(`${window.location.origin}/LLC/register`);
    } else if (storedCompanyName == "Demo VC") {
      window.open(`${window.location.origin}/Demo-VC/register`);
    } else if (storedCompanyName == "Upfront Ventures") {
      window.open(`${window.location.origin}/UpfrontVentures/register`);
    } else if (storedCompanyName == "Startup Lane") {
      window.open(`${window.location.origin}/startupLane/register`);
    }
  };

  const handleRegisteredComp = () => {
    window.open(`${window.location.origin}/Company/details`);
  };

  const handleList = () => {
    window.open(`${window.location.origin}/lists`);
  };

  const LogoutUser = () => {
    UserLogout();
    setCookie("access_token", "");
    window.localStorage.removeItem("Token");
    window.localStorage.removeItem("companyId");
    window.localStorage.removeItem("companyName");
    window.localStorage.removeItem("AdvisoryDetails");
    window.localStorage.removeItem("MultiUserComp");
    window.localStorage.removeItem("MainCompanyId");
    window.location.href = "/";
  };

  const handleRefreshPage = () => {
    // setShowModel(!showModel);
    window.location.href = "/dashboard/candidates";
  };

  const handleProfileIconButton = () => {
    setProfileIcon((profileIcon) => !profileIcon);
    setDisableInitialAnimation(true);
  };

  const handleModelClose = () => {
    setShowModel(!showModel);
    setRefresh(!refresh);
    history.replace(`/dashboard/candidates`);
  };

  const handlePremiumChange = () => {
    setShowPrimiumModel(false);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setToggleFilter(!toggleFilter);
  };

  const loadmoreHander = (e) => {
    e.preventDefault();
    setVisible((preValue) => preValue + visible);
    setRefresh(false); // Toggle the refresh state to force a re-render
  };

  useEffect(() => {
    const FirstName = cardData.CanInfo?.firstname.trim();
    const LastName = cardData.CanInfo?.lastname.trim();
    if (events.ctrlKey) {
      var new_url = `/candidate/${
        cardData?.CanInfo?._id?.$oid
      }-${FirstName?.charAt(0)?.toLowerCase()}${FirstName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}-${LastName?.charAt(
        0
      )?.toLowerCase()}${LastName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
      window.open(`${window.location.origin}${new_url}`);
    }
  }, [events]);

  const handleNameClick = (e, item) => {
    if (e) {
      e.preventDefault(); // Prevent the default behavior (opening a new tab)
      const FirstName = item.firstname.trim();
      const LastName = item.lastname.trim();
      var new_url = `/candidate/${item?._id?.$oid}-${FirstName?.charAt(
        0
      )?.toLowerCase()}${FirstName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}-${LastName?.charAt(
        0
      )?.toLowerCase()}${LastName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;

      // Open the new URL in a new tab with _blank target
      const newTab = window.open(
        `${window.location.origin}${new_url}`,
        "_blank"
      );

      // Switch back to the original tab (assuming you're using a two-tab setup)
      window.focus();
    }
  };

  // const FirstName = item.firstname.trim();
  // const LastName = item.lastname.trim();
  //   var new_url = `/candidate/${item?._id?.$oid}-${FirstName?.charAt(
  //     0
  //   )?.toLowerCase()}${FirstName?.slice(1)
  //     ?.toLowerCase()
  //     .replaceAll(" ", "-")}-${LastName?.charAt(
  //     0
  //   )?.toLowerCase()}${LastName?.slice(1)
  //     ?.toLowerCase()
  //     .replaceAll(" ", "-")}`;
  //   window.open(`${window.location.origin}${new_url}`);

  const handleClickEvent = (index, e) => {
    setCardData({ CanInfo: filterData[index] });
    setNewCardDataId({ CanInfo: filterData[index] });
    setEvents(e);
    setActiveIndex(index);
    if (!e.ctrlKey) {
      setShowModel(!showModel);
    }
  };

  const handlePrimiumModelOpen = () => {
    setShowPrimiumModel(true);
  };

  // Making the URL when the card is clicked and the model is opened
  useEffect(() => {
    const FirstName = cardData.CanInfo?.firstname.trim();
    const LastName = cardData.CanInfo?.lastname.trim();
    if (showModel == true) {
      var new_url = `/candidate/${
        cardData?.CanInfo?._id?.$oid
      }-${FirstName?.charAt(0)?.toLowerCase()}${FirstName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}-${LastName?.charAt(
        0
      )?.toLowerCase()}${LastName?.slice(1)
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
      window.history.pushState("data", "", new_url);
    }
  }, [showModel]);
  useEffect(() => {
    if (showModel == false) {
      var new_url = `/dashboard/candidates`;
      window.history.pushState("data", "", new_url);
    }
  }, [showModel]);

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    let eventhandler = (event) => {
      if (!buttonRef?.current?.contains(event.target)) {
        setAddCAndidatePopUp(false);
      }
    };
    document.addEventListener("mousedown", eventhandler);

    return () => {
      document.removeEventListener("mousedown", eventhandler);
    };
  }, []);

  const toUpperCaseNameFunction = (item) => {
    const capitalizeWord = (word) => {
      // Check if the word contains a hyphen or an apostrophe
      if (word.includes("-")) {
        const parts = word.split("-");
        return parts.map((part) => capitalizeWord(part)).join("-");
      } else if (word.includes("'")) {
        const parts = word.split("'");
        return parts.map((part) => capitalizeWord(part)).join("'");
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const FirstName = item?.firstname;
    const arr = FirstName?.split(" ").map(capitalizeWord);
    const FirstName2 = arr.join(" ");

    const LastName = item?.lastname;
    const arrLast = LastName?.split(" ").map(capitalizeWord);
    const LastName2 = arrLast.join(" ");

    const FullName = `${FirstName2} ${LastName2}`;

    return FullName;
  };

  const handleBannerClose = (e) => {
    setBannerSection(true);
    axios({
      method: "get",
      url: `/api/user/banner/${uniqueId}/`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status_code) {
        setBannerSection(res.data.data.banner);
      }
    });
  };

  const upperCaseWorkFuntion = (item) => {
    const Str = item?.careerplan?.work;
    const arr = Str?.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  const upperCaseEmployer = (item) => {
    const Str = item?.careerplan?.employer;
    const arr = Str?.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)?.toLowerCase();
    }
    const str2 = arr.join(" ");
    return str2;
  };

  useEffect(() => {
    if (colorData != undefined) {
      const targetArray = filterData;
      let z = 1;
      for (let i = 0; i < targetArray.length; i++) {
        if (targetArray[i].access == "premium") {
          targetArray[i].colour = z;
          z++;
          if (z == 6) {
            z = 1;
          }
        }
      }
      setFilterData([...targetArray]);
    }
  }, [colorData]);

  const handlePopUpOpen = () => {
    setPaymentPop(true);
  };

  const ShowPaymentModel = () => {
    return <PrimiumModule handlePremiumChange={handlePremiumChange} />;
  };

  const StyleFuntion = (item) => {
    if (userRole == "companyportfolio") {
      return;
    } else if (VC == "Startup Lane") {
      return;
    } else {
      const ColorFuntions = funtion(item);
      return ColorFuntions;
    }
  };

  const handleCareerPlan = (item) => {
    if(item.careerplan.careerlevel == ""){
      return "Unknown"
    }else{
    // Parse the career levels string into an array of objects
    const levelArray = item.careerplan.careerlevel
      ?.split(",")
      .map((level) => ({ name: level }));

    const UniStage = levelArray?.reduce((acc, name) => {
      if (name.name == "Mid") {
        acc.push({ name, id: "1" });
      }
      if (name.name == "Senior") {
        acc.push({ name, id: "2" });
      }
      if (name.name == "Lead/Principal") {
        acc.push({ name, id: "3" });
      }
      if (name.name == "Manager") {
        acc.push({ name, id: "4" });
      }
      if (name.name == "Head Of") {
        acc.push({ name, id: "5" });
      }
      if (name.name == "Director") {
        acc.push({ name, id: "6" });
      }
      if (name.name == "VP/GM") {
        acc.push({ name, id: "7" });
      }
      if (name.name == "Exec") {
        acc.push({ name, id: "8" });
      }
      if (name.name == "Board Director/Chair") {
        acc.push({ name, id: "9" });
      }
      if (name.name == "Founder") {
        acc.push({ name, id: "10" });
      }
      if (name.name == "Head of / Director") {
        acc.push({ name, id: "991" });
      }
      if (name.name == "Board of Directors / Advisory") {
        acc.push({ name, id: "992" });
      }
      if (name.name == "VP / GM") {
        acc.push({ name, id: "993" });
      }
      if (name.name == "Executive / C-suite") {
        acc.push({ name, id: "994" });
      }
      if (name.name == "Lead / Mid-Manager") {
        acc.push({ name, id: "995" });
      }
      if (name.name == "Senior / Principal") {
        acc.push({ name, id: "996" });
      }
      if (name.name == "Junior / Mid-Level") {
        acc.push({ name, id: "997" });
      }
      if (name.name == "Entry Level") {
        acc.push({ name, id: "998" });
      }
      if (name.name == "CEO") {
        acc.push({ name, id: "999" });
      }
      return acc;
    }, []);

    if (UniStage.length > 1) {
      const smallestIdObj = UniStage.reduce(
        (minObj, level) => (Number(level.id) < Number(minObj.id) ? level : minObj),
        UniStage[0]
      );
      const greatestIdObj = UniStage.reduce(
        (maxObj, level) => (Number(level.id) > Number(maxObj.id) ? level : maxObj),
        UniStage[0]
      );

      return `${smallestIdObj.name.name} → ${greatestIdObj.name.name}`;
    } else {
      const finalStage = UniStage.sort((a, b) => {
        if (Number(a.id) > Number(b.id)) return 1;
        if (Number(a.id) < Number(b.id)) return -1;
        return 0;
      });

      const CareerString = finalStage.map((item) => {
        return item?.name?.name?.split(",");
      });

      const FinalStringArray = CareerString.toString().replace(/,/g, " → ");

      return FinalStringArray;
    }
    }
  };

  useEffect(() => {
    // Reset the disableInitialAnimation state when the component mounts again
    setDisableInitialAnimation(false);
    if (showModel) {
      setRefresh(true);
    }
  }, [showModel]);

  const handleDesiredArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setLevel([...Level, value]);
    } else {
      setLevel(Level.filter((e) => e !== value));
    }
  };

  const handleStagesArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setStageModel([...StageModel, value]);
    } else {
      setStageModel(StageModel.filter((e) => e !== value));
    }
  };

  const handleSourceArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setSourceArray([...SourceArray, value]);
    } else {
      setSourceArray(SourceArray.filter((e) => e !== value));
    }
  };

  const updateFilterData = (updatedItem) => {
    setFilterData((prevFilterData) =>
      prevFilterData.map((item) =>
        item._id?.$oid === updatedItem._id?.$oid ? updatedItem : item
      )
    );
    setDashData((prevFilterData) =>
      prevFilterData.map((item) =>
        item._id?.$oid === updatedItem._id?.$oid ? updatedItem : item
      )
    );
  };

  const handlevcmodel = () => {
    setVCModel(true)
  }

  const handlevcmodelClose = () => {
    setVCModel(false)
  }

  return (
    <>
      <Helmet>
        <title>
          {showModel == true
            ? "Startup Lane Candidate"
            : "Startup Lane Dashboard"}
        </title>
        {/* <script defer event-userID={uniqueId} data-domain="app.startuplane.io" type="javascript" src="https://plausible.io/js/script.outbound-links.tagged-events.pageview-props.js"></script> */}
      </Helmet>

      <div
        className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
        style={{ fontFamily: "DM Sans, sans-serif" }}
      >
        {showModel == false ? (
          <HeaderUIDash
            userRole={userRole}
            handleModelClose={handleModelClose}
            handlevcmodel={handlevcmodel}
            handleRefreshPage={handleRefreshPage}
            showModel={showModel}
            buttonRef={buttonRef}
            handleReferCandidate={handleReferCandidate}
            logo={cardData?.CanInfo?.careerplan?.capital}
            handleCandidateAddNew={handleCandidateAddNew}
            addCandidatePopUp={addCandidatePopUp}
            handleRegistrationLink={handleRagistrationLink}
            handleCandidates={handleCandidates}
            menuRef={menuRef}
            handleProfileIconButton={handleProfileIconButton}
            profileIcon={profileIcon}
            handleCompanyTeamMember={handleCampanyTeamMember}
            handleCollection={handleCollection}
            handleCampanyMember={handleCampanyMember}
            LogoutUser={LogoutUser}
            handleCandidateDetails={handleCandidateDetails}
            handleRegisteredComp={handleRegisteredComp}
            handleList={handleList}
            handlemanageTeamMember={handlemanageTeamMember}
            handleCompaniesDetails={handleCompaniesDetails}
            fetchNewDashboard={fetchNewDashboard}
            setDashData={setDashData}
            setFilterData={setFilterData}
            setColorData={setColorData}
            userCompany={userCompany}
            userCompanys={userCompanys}
            Auth={authentication_ref?.current.dash_data?.loading}
          />
        ) : (
          <HeaderUI
            userRole={userRole}
            VentureData={VentureData}
            handleModelClose={handleModelClose}
            handleRefreshPage={handleRefreshPage}
            editCandidate={editCandidate}
            showModel={showModel}
            buttonRef={buttonRef}
            logo={cardData?.CanInfo?.careerplan?.capital}
            Vclogo={cardData?.CanInfo?.logo}
            handleCandidateAddNew={handleCandidateAddNew}
            handleCollection={handleCollection}
            addCandidatePopUp={addCandidatePopUp}
            handleRegistrationLink={handleRagistrationLink}
            handleCandidates={handleCandidates}
            menuRef={menuRef}
            handleProfileIconButton={handleProfileIconButton}
            profileIcon={profileIcon}
            handleCompanyTeamMember={handleCampanyTeamMember}
            handleCompanyMember={handleCampanyMember}
            LogoutUser={LogoutUser}
            handleCandidateDetails={handleCandidateDetails}
            handleRegisteredComp={handleRegisteredComp}
            handleList={handleList}
            handlemanageTeamMember={handlemanageTeamMember}
            handleCompaniesDetails={handleCompaniesDetails}
            fetchNewDashboard={fetchNewDashboard}
            setDashData={setDashData}
            setFilterData={setFilterData}
            setColorData={setColorData}
            userCompany={userCompany}
            userCompanys={userCompanys}
            Auth={authentication_ref?.current.dash_data?.loading}
          />
        )}
        {/* lg:max-h-[1010px] */}
        {showModel == false && (
          <aside className="bg-deep-purple-10 fixed top-0 z-10 w-full lg:w-[370px] lg:h-screen lg:max-h-[1298px] pt-28 sm:pt-32 lg:pt-24 pb-5 lg:pb-8 m-0 px-4 lg:pr-2 lg:pl-6">
            <div className="w-full lg:h-full relative rounded-lg lg:bg-white lg:border lg:border-solid lg:border-[#E6E9F0] lg:py-1 lg:pr-1">
              <form
                className="custom-scroll flex lg:block w-full lg:h-full lg:overflow-y-auto lg:p-3 lg:pl-4"
                method="POST"
                action="#"
              >
                <label className="hidden lg:block w-full text-base font-medium mb-2">
                  Search
                </label>
                <div className="inline-flex lg:flex items-center group lg:w-full sm:relative lg:mb-6">
                  <span className="mobileSearch-btn block absolute w-[46px] sm:w-5 h-[45px] sm:h-5 flex items-center justify-center left-[1px] top-[1px] sm:left-3 sm:top-[14px] z-[1]">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      className="w-4 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                        fill="#6936FF"
                      />
                    </svg>
                  </span>
                  <input
                    type="search"
                    id="search"
                    value={globalSearch}
                    onChange={(e) => {
                      setDisableInitialAnimation(true);
                      setGlobalSearch(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setGlobalSearch(e.target.value);
                      }
                    }}
                    // disabled = {authentication_ref?.current?.global_data?.loading || authentication_ref?.current?.dash_data?.loading ? true : false}
                    placeholder="Name, Job Title, etc"
                    className="w-12 hover:w-full focus:w-full my-input-class active:focus sm:w-full absolute top-0 sm:relative appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 pl-[45px] sm:pl-9 sm:pr-10 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:border-deep-purple-500 transition duration-500"
                    style={{ minHeight: "47px" }}
                  />
                  {globalSearch != "" ? (
                    <button
                      onClick={(e) => {
                        if (e) {
                          e.preventDefault();
                          setDisableInitialAnimation(true);
                          setGlobalSearch("");
                        }
                      }}
                      className="w-10 h-[45px] flex items-center justify-center bg-transparent focus:bg-transparent focus:outline-none absolute right-[1px] top-[1px]"
                    >
                      <svg
                        width="10px"
                        height="10px"
                        viewBox="0 0 16 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Group</title>
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <g
                            id="Group"
                            transform="translate(2.000000, 2.000000)"
                            stroke="#6936FF"
                            stroke-width="3"
                          >
                            <g id="icon_dropdown">
                              <polyline
                                id="Path"
                                points="0 0 6 6 12 0"
                              ></polyline>
                              <polyline
                                id="Path"
                                transform="translate(6.000000, 9.000000) rotate(180.000000) translate(-6.000000, -9.000000) "
                                points="0 6 6 12 12 6"
                              ></polyline>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                  ) : null}
                </div>

                <div className="flex lg:hidden items-center justify-between ml-auto h-[47px]">
                  {/* {userRole == "anonymous" ? null : userRole ==
                  "companyportfolio" ? (
                  <button
                    type="button"
                    onClick={handleReferCandidate}
                    className="add-candidate-btn inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      className="mr-1"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                        fill="white"
                      />
                    </svg>
                    Refer candidate
                  </button>
                ) : null} */}

                  <button
                    onClick={(e) => handleToggle(e)}
                    className="bg-transparent focus:bg-transparent focus:outline-none ml-2"
                  >
                    <svg
                      width="25px"
                      height="23px"
                      viewBox="0 0 25 23"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>filter-icon</title>
                      <g
                        id="Page-1"
                        stroke="none"
                        className="hover:fill-deep-purple-500"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g id="filter-icon" fill="#666" fill-rule="nonzero">
                          <path
                            d="M16.3456427,5.31606969 L17.1362931,5.31606969 C17.4261982,5.31606969 17.6633933,5.07887457 17.6633933,4.78896943 C17.6633933,4.49906429 17.4261982,4.26186917 17.1362931,4.26186917 L16.3456427,4.26186917 C16.0557375,4.26186917 15.8185424,4.49906429 15.8185424,4.78896943 C15.8185424,5.07887457 16.0557375,5.31606969 16.3456427,5.31606969 Z"
                            id="Path"
                          ></path>
                          <path
                            d="M24.1467265,0.62487736 C23.9622415,0.255907177 23.5932713,0.0450670724 23.1979461,0.0450670724 L1.05973508,0.0450670724 C0.66440988,0.0450670724 0.295439697,0.28226219 0.110954605,0.62487736 C-0.0735304865,0.993847544 -0.0208204603,1.41552775 0.216374658,1.73178791 L9.51969428,13.4334137 L9.51969428,21.0500125 C9.51969428,21.4453377 9.73053438,21.7879529 10.0731496,21.972438 C10.2312796,22.051503 10.3894097,22.104213 10.5738948,22.104213 C10.7847349,22.104213 10.995575,22.051503 11.1800601,21.9197279 L14.1054666,19.8903919 C14.5271468,19.5741318 14.7643419,19.0997415 14.7643419,18.5989963 L14.7643419,13.4070587 L24.0413065,1.73178791 C24.2785016,1.41552775 24.3312116,0.993847544 24.1467265,0.62487736 Z M13.8155614,12.9063135 C13.7628514,12.9853785 13.7101414,13.0907986 13.7101414,13.2225736 L13.7101414,18.5726413 C13.7101414,18.7571264 13.6310763,18.9152564 13.4993013,19.0206765 L10.5738948,21.0500125 L10.5738948,13.2225736 C10.5738948,13.0380885 10.4948298,12.8799584 10.3630547,12.8008934 L4.40682174,5.31606969 L13.9736915,5.31606969 C14.2635966,5.31606969 14.5007918,5.07887457 14.5007918,4.78896943 C14.5007918,4.49906429 14.2635966,4.26186917 13.9736915,4.26186917 L3.69523639,4.26186917 C3.66888137,4.26186917 3.61617135,4.26186917 3.58981633,4.26186917 L1.05973508,1.0992676 L23.1979461,1.0992676 L13.8155614,12.9063135 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>

                <div className="search-bar small-filter absolute hidden lg:block  lg:relative inset-x-0 top-14 md:top-30 lg:top-auto max-h-[400px] md:max-h-[none] overflow-auto lg:overflow-visible bg-white lg:bg-transparent shadow-xl lg:shadow-none rounded-lg z-10 lg:z-0 p-4 lg:p-0">
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Expertise
                    </label>
                    <Select
                      placeholder="Product, Marketing, etc"
                      name="Expertise"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setExper(item);
                      }}
                      value={Exper ? Exper : ""}
                      options={ExpertiseMapStringresult}
                    />
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                      Desired Level
                    </label>
                    {/* <Select
                      placeholder="Desired Level"
                      name="Desired Level"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setLevel(item);
                      }}
                      value={Level ? Level : ""}
                      options={uniqueLevelresult}
                    /> */}
                    <div class="flex flex-wrap items-start">
                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Mid"}
                          checked={Level?.includes("Mid")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Mid"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Mid"
                        >
                          Mid
                        </label>
                      </div>
                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Senior"}
                          checked={Level?.includes("Senior")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Senior"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Senior"
                        >
                          Senior
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Lead/Principal"}
                          checked={Level?.includes("Lead/Principal")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Lead/Principal"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Lead/Principal"
                        >
                          Lead/Principal
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Manager"}
                          checked={Level?.includes("Manager")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Manager"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Manager"
                        >
                          Manager
                        </label>
                      </div>

                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Head Of"}
                          checked={Level?.includes("Head Of")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="headOf"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="headOf"
                        >
                          Head Of
                        </label>
                      </div>

                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Director"}
                          checked={Level?.includes("Director")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg                        
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0] 
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Director"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Director"
                        >
                          Director
                        </label>
                      </div>

                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"VP/GM"}
                          checked={Level?.includes("VP/GM")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0] 
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="VP/GM"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="VP/GM"
                        >
                          VP/GM
                        </label>
                      </div>

                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Exec"}
                          checked={Level?.includes("Exec")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Exec"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Exec"
                        >
                          Exec
                        </label>
                      </div>

                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Board Director/Chair"}
                          checked={Level?.includes("Board Director/Chair")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="NED"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="NED"
                        >
                          Board Director
                        </label>
                      </div>

                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          value={"Founder"}
                          checked={Level?.includes("Founder")}
                          onChange={(e) => handleDesiredArray(e)}
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          id="Founder"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Founder"
                        >
                          Founder
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Biz Model Experience
                    </label>
                    <Select
                      placeholder="B2B, B2C, etc"
                      name="Biz Model"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setBizModel(item);
                      }}
                      value={BizModel ? BizModel : ""}
                      options={UniDesiredLocStringresultBuzzModel}
                    />
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                    Industry Experience
                    </label>
                    <Select
                      placeholder="Industrial Tech, Life Sciences, etc"
                      name="Industry Experience"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setExperenece(item);
                      }}
                      value={Experience ? Experience : ""}
                      options={UniDesiredLocStringresult}
                    />
                  </div>
                  <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                      Location
                    </label>
                    <GooglePlacesSelect
                      value={Location}
                      onChange={(location) => setLocation(location)}
                      placeholder={"Current Location"}
                    />
                  </div>
                  <div className="w-full">
                    <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                      Desired Stage
                    </label>
                    <div class="flex flex-wrap items-start">
                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Pre-Seed"}
                          checked={StageModel?.includes("Pre-Seed")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Pre-SeedStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Pre-Seed"
                        >
                          Pre-Seed
                        </label>
                      </div>
                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Seed"}
                          checked={StageModel?.includes("Seed")}
                          onChange={(e) => handleStagesArray(e)}
                          id="SeedStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Seed"
                        >
                          Seed
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series A"}
                          checked={StageModel?.includes("Series A")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series-AStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series-A"
                        >
                          Series A
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series B"}
                          checked={StageModel?.includes("Series B")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series-BStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series-B"
                        >
                          Series B
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series C"}
                          checked={StageModel?.includes("Series C")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series-CStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series-C"
                        >
                          Series C
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series D"}
                          checked={StageModel?.includes("Series D")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series DStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series D"
                        >
                          Series D
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Series E"}
                          checked={StageModel?.includes("Series E")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Series EStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Series E"
                        >
                          Series E
                        </label>
                      </div>

                      {/* <!--First checkbox--> */}
                      <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"Pre-IPO"}
                          checked={StageModel?.includes("Pre-IPO")}
                          onChange={(e) => handleStagesArray(e)}
                          id="Pre-IPOStages"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="Pre-IPO"
                        >
                          Pre-IPO
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                      Source
                    </label>
                    <div class="flex flex-wrap items-start">
                      {/* <!--First Source checkbox VC Name--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"VCName"}
                          checked={SourceArray?.includes("VCName")}
                          onChange={(e) => handleSourceArray(e)}
                          id={"VCName"}
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="VCName"
                        >
                          {storedCompanyName}
                        </label>
                      </div>
                      {/* <!--Second Source checkbox Resume Drop--> */}
                       <div className="w-3/5 sm:w-1/3 lg:w-2/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"ResumeDrop"}
                          checked={SourceArray?.includes("ResumeDrop")}
                          onChange={(e) => handleSourceArray(e)}
                          id="ResumeDrop"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="ResumeDrop"
                        >
                          Resume Drop
                        </label>
                      </div>
                      {/* <!--Third Source checkbox Startup Lane--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"StartupLane"}
                          checked={SourceArray?.includes("StartupLane")}
                          onChange={(e) => handleSourceArray(e)}
                          id="StartupLane"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="StartupLane"
                        >
                        <p>Startup</p>
                        <p>Lane</p>  
                        </label>
                      </div>
                      {/* <!--Fourth Source checkbox Career Fair--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-2/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"CareerFair"}
                          checked={SourceArray?.includes("CareerFair")}
                          onChange={(e) => handleSourceArray(e)}
                          id="CareerFair"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="CareerFair"
                        >
                          Career Fair
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {toggleFilter && (
                  <div className="search-bar small-filter absolute lg:hidden inset-x-0 top-14 md:top-30 lg:top-auto max-h-[400px] md:max-h-[none] overflow-auto lg:overflow-visible block bg-white lg:bg-transparent shadow-xl lg:shadow-none rounded-lg z-10 lg:z-0 p-4 lg:p-0">
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2">
                        Expertise
                      </label>
                      <Select
                        placeholder="Product, Marketing, etc"
                        name="Expertise"
                        isClearable={true}
                        getOptionLabel={(item) => item?.currItem}
                        getOptionValue={(item) => item?.currItem}
                        onChange={(item) => {
                          setDisableInitialAnimation(true);
                          setExper(item);
                        }}
                        value={Exper ? Exper : ""}
                        options={ExpertiseMapStringresult}
                      />
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                        Desired Level
                      </label>
                      {/* <Select
                      placeholder="Desired Level"
                      name="Desired Level"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setLevel(item);
                      }}
                      value={Level ? Level : ""}
                      options={uniqueLevelresult}
                    /> */}
                      <div class="flex flex-wrap items-start">
                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Mid"}
                            checked={Level?.includes("Mid")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Mid"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Mid"
                          >
                            Mid
                          </label>
                        </div>
                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Senior"}
                            checked={Level?.includes("Senior")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Senior"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Senior"
                          >
                            Senior
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Lead/Principal"}
                            checked={Level?.includes("Lead/Principal")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Lead/Principal"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Lead/Principal"
                          >
                            Lead/Principal
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Manager"}
                            checked={Level?.includes("Manager")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Manager"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Manager"
                          >
                            Manager
                          </label>
                        </div>

                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Head Of"}
                            checked={Level?.includes("Head Of")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="headOf"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="headOf"
                          >
                            Head Of
                          </label>
                        </div>

                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Director"}
                            checked={Level?.includes("Director")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg                        
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0] 
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Director"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Director"
                          >
                            Director
                          </label>
                        </div>

                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"VP/GM"}
                            checked={Level?.includes("VP/GM")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0] 
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="VP/GM"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="VP/GM"
                          >
                            VP/GM
                          </label>
                        </div>

                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Exec"}
                            checked={Level?.includes("Exec")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Exec"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Exec"
                          >
                            Exec
                          </label>
                        </div>

                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Board Director/Chair"}
                            checked={Level?.includes("Board Director/Chair")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="NED"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="NED"
                          >
                            Board Director
                          </label>
                        </div>

                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            value={"Founder"}
                            checked={Level?.includes("Founder")}
                            onChange={(e) => handleDesiredArray(e)}
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            id="Founder"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Founder"
                          >
                            Founder
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2">
                        Biz Model Experience
                      </label>
                      <Select
                        placeholder="B2B, B2C, etc"
                        name="Biz Model"
                        isClearable={true}
                        getOptionLabel={(item) => item?.currItem}
                        getOptionValue={(item) => item?.currItem}
                        onChange={(item) => {
                          setDisableInitialAnimation(true);
                          setBizModel(item);
                        }}
                        value={BizModel ? BizModel : ""}
                        options={UniDesiredLocStringresultBuzzModel}
                      />
                    </div>
                    <div className="w-full mb-4 sm:mb-6">
                    <label className="block w-full text-base font-medium mb-2">
                    Industry Experience
                    </label>
                    <Select
                      placeholder="Industrial Tech, Life Sciences, etc"
                      name="Industry Experience"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setExperenece(item);
                      }}
                      value={Experience ? Experience : ""}
                      options={UniDesiredLocStringresult}
                    />
                  </div>
                    <div className="w-full mb-4 sm:mb-6">
                      <label className="block w-full text-base font-medium mb-2">
                        Location
                      </label>
                      <GooglePlacesSelect
                        value={Location}
                        onChange={(location) => setLocation(location)}
                        placeholder={"Current Location"}
                      />
                    </div>
                    {/* <div className="w-full sm:mb-4">
                    <Select
                      placeholder="Desired Location"
                      name="Desired Location"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDesire(item);
                      }}
                      value={Desire ? Desire : ""}
                      options={Unqiiidesire}
                    />
                  </div> */}
                    <div className="w-full">
                      <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                        Desired Stage
                      </label>
                      {/* <Select
                      placeholder="Desired Stage"
                      name="Desired Stage"
                      isClearable={true}
                      getOptionLabel={(item) => item?.currItem}
                      getOptionValue={(item) => item?.currItem}
                      onChange={(item) => {
                        setDisableInitialAnimation(true);
                        setStageModel(item);
                      }}
                      value={StageModel ? StageModel : ""}
                      options={uniqueNewStage}
                    /> */}
                      <div class="flex flex-wrap items-start">
                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Pre-Seed"}
                            checked={StageModel?.includes("Pre-Seed")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Pre-Seed"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Pre-Seed"
                          >
                            Pre-Seed
                          </label>
                        </div>
                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Seed"}
                            checked={StageModel?.includes("Seed")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Seed"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Seed"
                          >
                            Seed
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series A"}
                            checked={StageModel?.includes("Series A")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series-A"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series-A"
                          >
                            Series A
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series B"}
                            checked={StageModel?.includes("Series B")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series-B"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series-B"
                          >
                            Series B
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series C"}
                            checked={StageModel?.includes("Series C")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series-C"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series-C"
                          >
                            Series C
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series D"}
                            checked={StageModel?.includes("Series D")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series D"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series D"
                          >
                            Series D
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Series E"}
                            checked={StageModel?.includes("Series E")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Series E"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Series E"
                          >
                            Series E
                          </label>
                        </div>

                        {/* <!--First checkbox--> */}
                        <div className="w-2/5 sm:w-1/3 lg:w-2/5 flex relative mb-[14px] pl-10">
                          <input
                            className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                            type="checkbox"
                            value={"Pre-IPO"}
                            checked={StageModel?.includes("Pre-IPO")}
                            onChange={(e) => handleStagesArray(e)}
                            id="Pre-IPO"
                          />
                          <label
                            className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                            for="Pre-IPO"
                          >
                            Pre-IPO
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                    <label className="block w-full text-base font-medium mb-2 sm:mb-3">
                      Source
                    </label>
                    <div class="flex flex-wrap items-start">
                      {/* <!--First Source checkbox VC Name--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"VCName"}
                          checked={SourceArray?.includes("VCName")}
                          onChange={(e) => handleSourceArray(e)}
                          id={"VCName"}
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="VCName"
                        >
                          {storedCompanyName}
                        </label>
                      </div>
                      {/* <!--Second Source checkbox Resume Drop--> */}
                       <div className="w-3/5 sm:w-1/3 lg:w-2/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"ResumeDrop"}
                          checked={SourceArray?.includes("ResumeDrop")}
                          onChange={(e) => handleSourceArray(e)}
                          id="ResumeDrop"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="ResumeDrop"
                        >
                          Resume Drop
                        </label>
                      </div>
                      {/* <!--Third Source checkbox Startup Lane--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-3/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"StartupLane"}
                          checked={SourceArray?.includes("StartupLane")}
                          onChange={(e) => handleSourceArray(e)}
                          id="StartupLane"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="StartupLane"
                        >
                        <p>Startup</p>
                        <p>Lane</p>
                        </label>
                      </div>
                      {/* <!--Fourth Source checkbox Career Fair--> */}
                      <div className="w-3/5 sm:w-1/3 lg:w-2/5 flex relative mb-[8px] pl-10">
                        <input
                          className="
                          absolute 
                          left-0 
                          h-[1.75rem] 
                          w-[1.75rem]  
                          appearance-none 
                          rounded-lg 
                          bg-[#F8FAFF]
                          border
                          border-solid 
                          border-gray-[#E6E9F0]
                          outline-none 
                          checked:border-deep-purple-500 
                          checked:bg-deep-purple-500  
                          checked:after:absolute 
                          checked:after:mt-0 
                          checked:after:ml-[0.5rem] 
                          checked:after:block 
                          checked:after:h-[1.225rem] 
                          checked:after:w-[0.675rem] 
                          checked:after:rotate-45 
                          checked:after:border-[0.125rem] 
                          checked:after:border-l-0 
                          checked:after:border-t-0 
                          checked:after:border-solid 
                          checked:after:border-white 
                          checked:after:bg-transparent 
                          checked:after:content-[''] 
                          hover:cursor-pointer 
                          focus:shadow-none  
                          focus:outline-none 
                          focus:transition-[border-color_0.2s]    
                          focus:after:absolute 
                          focus:after:z-[1] 
                          focus:after:block 
                          focus:after:h-[1.875rem] 
                          focus:after:w-[1.875rem]  
                          focus:after:content-['']  
                          checked:focus:after:mt-0
                          checked:focus:after:ml-[0.5rem] 
                          checked:focus:after:h-[1.225rem] 
                          checked:focus:after:w-[0.675rem] 
                          checked:focus:after:rotate-45 
                          checked:focus:after:border-[0.125rem] 
                          checked:focus:after:border-l-0 
                          checked:focus:after:border-t-0 
                          checked:focus:after:border-solid 
                          checked:focus:after:border-white 
                          checked:focus:after:bg-transparent
                          transition 
                          duration-500"
                          type="checkbox"
                          value={"CareerFair"}
                          checked={SourceArray?.includes("CareerFair")}
                          onChange={(e) => handleSourceArray(e)}
                          id="CareerFair"
                        />
                        <label
                          className="block self-center leading-normal font-normal text-[#424D6A] hover:cursor-pointer mb-0"
                          for="CareerFair"
                        >
                          Career Fair
                        </label>
                      </div>
                    </div>
                  </div>
                  </div>
                )}
              </form>
            </div>
          </aside>
        )}
        {VCModel == true && (<AddVc handlevcmodelClose={handlevcmodelClose} />)}
        {/* <EditCandidate /> */}
        {FormPopup == true ? (
          <div className="CandidatePageDetailsSlideWrapper show" style={FormPopup == true ? {zIndex: "9999"}: null}>
        <FormModle handleCandidatePopup={handleCandidatePopup} VentureData={VentureData} setApistatus={setApistatus} uniqueId={uniqueId} storedCompanyName={storedCompanyName} />
        </div>
        ) : null}
        {editCandidate == false ? (
          <div
          className={
             showModel == true
              ? "CandidatePageDetailsSlideWrapper show"
              : "CandidatePageDetailsSlideWrapper"
          }
          style={
            connectScollModel == true 
              ? { overflow: 'hidden' } : connectScollModel == false && listModel == false ? {overflowX: 'hidden'}
              : { overflow: "scroll" }
          }
        >
          {showModel == true && (
            <div className="CandidatePageDetails">
              <NewCandidatePage
                cardData={newCardDataId.CanInfo}
                setEditCandidate={setEditCandidate}
                editCandidate={editCandidate}
                storedCompanyName={storedCompanyName}
                VentureData={VentureData}
                updateFilterData={updateFilterData}
                candidateId={newCardDataId?.CanInfo?._id?.$oid}
                userRole={userRole}
                setconnectScoll={setconnectScoll}
                setListModel={setListModel}
                connectScollModel={connectScollModel}
                showModel={showModel}
                setShowModel={setShowModel}
                authentication_ref={authentication_ref}
                userCompanys={userCompanys}
                setRefresh={setRefresh}
                refresh={refresh}
                userDetails={WholeUserDetails}
                uniqueId={uniqueId}
                Token={Token}
                UserCase={UserCase}
                activeIndex={activeIndex}
                handleModelClose={handleModelClose}
              />
            </div>
          )}
        </div>
        ) : (
          <div
          className="CandidatePgeScroll"
        >
          {showModel == true && (
            <div className="CandidatePageDetails">
              <NewCandidatePage
                cardData={newCardDataId.CanInfo}
                setEditCandidate={setEditCandidate}
                editCandidate={editCandidate}
                storedCompanyName={storedCompanyName}
                VentureData={VentureData}
                updateFilterData={updateFilterData}
                candidateId={newCardDataId?.CanInfo?._id?.$oid}
                userRole={userRole}
                setconnectScoll={setconnectScoll}
                setListModel={setListModel}
                connectScollModel={connectScollModel}
                showModel={showModel}
                setShowModel={setShowModel}
                authentication_ref={authentication_ref}
                userCompanys={userCompanys}
                setRefresh={setRefresh}
                refresh={refresh}
                userDetails={WholeUserDetails}
                uniqueId={uniqueId}
                Token={Token}
                UserCase={UserCase}
                activeIndex={activeIndex}
                handleModelClose={handleModelClose}
              />
            </div>
          )}
        </div>
        )}
        

        {showModel == false && (
          <div
            className="lg:ml-[370px] pt-[12rem] sm:pt-48 lg:pt-24 relative"
            key={window.location.pathname}
          >
            {/* <div class="bg-green-100 rounded-lg py-5 px-6 mb-3 max-w-sm mx-auto text-base text-green-700 inline-flex items-center w-full mx-auto" role="alert">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
        </svg>
        Student Added Successfully
      </div> */}
            {/* ---------> disabling banner section for now <-------------- */}
            {/* {bannerSection == false ? (
                <div
                  className="c-information-area flex md:flex-row relative mx-5"
                >
                  <div className="flex md:flex-1 md:pr-4">
                    <h3>👋 Hey {FirstName?.charAt(0)?.toUpperCase() + FirstName?.slice(1)}! Welcome to the {storedCompanyName == "Startup Lane" ? "Startup Lane" : storedCompanyName == "Global VC Talent Network" ? "Global Venture Capital" : storedCompanyName}  Talent Network.</h3>
                  </div>
                  <a
                    href="javascript:void(0);"
                    onClick={handleBannerClose}
                    className="btn-more-txt w-auto md:justify-end align-center flex md:text-right"
                  >
                    <button type="button" aria-label="Close">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </a>
                </div>
            ) : null} */}

            {/* <button className="ml-5 mb-5" onClick={handlePopUpOpen}>hello</button> */}
            {/* <motion.section
              initial={disableInitialAnimation ? false : { x: "0%" }} // Disable initial animation
              animate={{ x: "0%" }}
              exit={{ opacity: 1 }}
              transition={{ ease: "easeOut", duration: 0.35 }}
              key={window.location.pathname + refresh} // Adding refresh to the key
              className="flex flex-wrap px-2"
            > */}
            <div className="flex flex-wrap px-2">
              {authentication_ref?.current?.dash_new_data?.loading ||
              (authentication_ref?.current?.dash_new_data?.loading &&
                filterData.length <= 0) ? (
                <SkeletonCards />
              ) : (
                filterData?.slice(0, visible).map((item, index) => {
                  return (
                    <div
                      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/4 px-2.5 mb-5"
                      key={index}
                      id={`can-${index}`}
                      onMouseEnter={() => getFullProfile(item?._id?.$oid)}
                      onClick={(e) => {
                        if (!e.target.closest(".smallCardName")) {
                          handleClickEvent(index, e);
                        } else {
                          e.stopPropagation(); // Stop event propagation when clicking on an element with class "smallCardName"
                        }
                      }}
                    >
                      <div
                        className="relative rounded-lg shadow hover:shadow-md text-base-color bg-white overflow-hidden cursor-pointer p-4"
                        ref={dummy}
                        // style={item?.hidden == 'yes' ? {opacity: '70%'} : null}
                      >
                        <div className="flex border-b border-gray-300 pb-3 mb-2">
                          {/* <figure className="shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-start justify-center overflow-hidden"> */}
                          <figure
                            className={
                              item.profilepic
                                ? "shrink-0 bg-deep-purple-50 rounded-full w-12 md:w-[60px]  h-12 md:h-[60px] inline-flex items-start justify-center overflow-hidden"
                                : "shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center"
                            }
                            style={{ width: "60px", height: "60px" }}
                          >
                            {item.profilepic ? (
                              <img
                                className="max-w-full h-auto min-h-full rounded-full"
                                // src={`${process.env.REACT_APP_IMAGE}/${item?.profilepic}`}
                                // src={`https://cnp1656-dev-image.s3.amazonaws.com/profilepic/${item.profilepic}`}
                                src={`https://cnp1656-prod.s3.amazonaws.com/profilepic/${item.profilepic}`}
                                alt={item?.firstname + " " + item?.lastname}
                              />
                            ) : (
                              <span className="text-lg text-deep-purple-500 font-medium">
                                {item?.firstname[0]?.toUpperCase()}
                                {item?.lastname[0]?.toUpperCase()}
                              </span>
                            )}
                          </figure>
                          <figcaption className="w-full self-center overflow-hidden">
                            <div
                              className="flex items-center text-lg smallCardName text-base-color font-bold truncate leading-4"
                              style={{ lineHeight: "1.35rem" }}
                              onClick={(e) => {
                                handleNameClick(e, item);
                              }}
                            >
                              <span className="flex-grow px-4 mr-2 truncate overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                                {toUpperCaseNameFunction(item)}
                              </span>
                            </div>

                            <div
                              className="text-sm px-4 text-base-color truncate"
                              style={{ lineHeight: "1.225rem" }}
                            >
                              {upperCaseWorkFuntion(item)}
                            </div>
                            <div
                              className="text-sm px-4 truncate"
                              style={{
                                color: "#70747A",
                                lineHeight: "1.225rem",
                              }}
                            >
                              {upperCaseEmployer(item)}
                            </div>
                          </figcaption>
                        </div>
                        <ul className="text-sm text-base-color">
                          <li className="flex items-center group mb-1">
                            <span className="shrink-0 mr-2">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.14045 1.15575L10.9147 6.40878L16.4584 6.47296C16.9354 6.47851 17.1329 7.08639 16.7503 7.37089L12.3026 10.6812L13.9547 15.9737C14.0966 16.4291 13.5801 16.8044 13.1907 16.5285L8.66834 13.3213L4.14538 16.5279C3.75659 16.8037 3.23943 16.4279 3.38137 15.9731L5.03344 10.6806L0.585767 7.37027C0.203145 7.08577 0.400627 6.4779 0.87767 6.47234L6.42136 6.40816L8.19562 1.15513C8.34867 0.703394 8.98802 0.703394 9.14045 1.15575Z"
                                  fill="#6936FF"
                                />
                              </svg>
                            </span>
                            <span className="truncate">
                              {item?.about?.functionalexpertise}
                            </span>
                          </li>
                          <li className="flex items-center group mb-1">
                            <span className="shrink-0 mr-2">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.66797 0.727295C8.26651 0.727295 7.9407 1.05311 7.9407 1.45457V1.49008C4.50427 1.83101 1.77168 4.5636 1.43075 8.00002H1.39524C0.993787 8.00002 0.667969 8.32584 0.667969 8.7273C0.667969 9.12875 0.993787 9.45457 1.39524 9.45457H1.43075C1.77168 12.891 4.50427 15.6236 7.9407 15.9645V16C7.9407 16.4015 8.26651 16.7273 8.66797 16.7273C9.06942 16.7273 9.39524 16.4015 9.39524 16V15.9645C12.8317 15.6236 15.5643 12.891 15.9052 9.45457H15.9407C16.3422 9.45457 16.668 9.12875 16.668 8.7273C16.668 8.32584 16.3422 8.00002 15.9407 8.00002H15.9052C15.5643 4.5636 12.8317 1.83101 9.39524 1.49008V1.45457C9.39524 1.05311 9.06942 0.727295 8.66797 0.727295ZM7.9407 2.95883V3.63639C7.9407 4.03784 8.26651 4.36366 8.66797 4.36366C9.06942 4.36366 9.39524 4.03784 9.39524 3.63639V2.95883C12.0215 3.28867 14.1066 5.37375 14.4364 8.00002H13.7589C13.3574 8.00002 13.0316 8.32584 13.0316 8.7273C13.0316 9.12875 13.3574 9.45457 13.7589 9.45457H14.4364C14.1066 12.0808 12.0215 14.1659 9.39524 14.4958V13.8182C9.39524 13.4167 9.06942 13.0909 8.66797 13.0909C8.26651 13.0909 7.9407 13.4167 7.9407 13.8182V14.4958C5.31443 14.1659 3.22935 12.0808 2.8995 9.45457H3.57706C3.97851 9.45457 4.30433 9.12875 4.30433 8.7273C4.30433 8.32584 3.97851 8.00002 3.57706 8.00002H2.8995C3.22935 5.37375 5.31443 3.28867 7.9407 2.95883Z"
                                  fill="#6936FF"
                                />
                              </svg>
                            </span>
                            <span className="truncate">
                            {/* {item.caree} */}
                              {handleCareerPlan(item)}
                              {/* {item?.careerplan?.careerlevel} */}
                            </span>
                          </li>
                          <li className="flex items-center group">
                            <span className="shrink-0 mr-2">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 13 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.6681 0.246338C3.48645 0.246338 0.907227 2.82556 0.907227 6.00721C0.907227 9.27116 4.53164 14.0889 6.03111 15.9414C6.3603 16.348 6.97589 16.348 7.30509 15.9414C8.80456 14.0889 12.429 9.27116 12.429 6.00721C12.429 2.82556 9.84975 0.246338 6.6681 0.246338ZM6.6681 8.06466C5.53156 8.06466 4.61064 7.14375 4.61064 6.00721C4.61064 4.87067 5.53156 3.94976 6.6681 3.94976C7.80464 3.94976 8.72555 4.87067 8.72555 6.00721C8.72555 7.14375 7.80464 8.06466 6.6681 8.06466Z"
                                  fill="#6936FF"
                                />
                              </svg>
                            </span>
                            <span className="truncate">
                              {item?.about?.currentLocation?.formatted_address}
                            </span>
                            {
                              userRole == "venturecapital" && storedCompanyName !== "Global VC Talent Network" || userRole == "globalvc" && storedCompanyName !== "Global VC Talent Network" ? (
                                <span className="ml-auto">
                            {item?.hidden == 'yes' ? (
                                <img
                                src={image1}
                                style={{ paddingRight: "0px" }}
                                alt="Image"
                              />
                              ) : (
                                <img
                                src={image2}
                                style={{ paddingRight: "0px" }}
                                alt="Image"
                              />
                              )}
                            </span>
                              ) : null
                            }
                            {StyleFuntion(item)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            {/* </motion.section> */}

            {/* Skeleton Loader */}

            {/* End Skeleton Loader */}
            {/* Loadmore for the candidates */}

            <div className="text-center px-4 mt-5 max-w-full w-full">
              {TestingNumber == filterData?.length ? null : (
                <>
                  {authentication_ref?.current?.dash_new_data?.loading ? (
                    <button
                      type="button"
                      className="text-gray-1000 flex items-center mx-auto"
                      disabled
                    >
                      <span
                        className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-deep-purple-500 border-l-transparent rounded-full"
                        role="status"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    filterData?.length > 19 && (
                      <button
                        type="button"
                        value={size}
                        className="bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-2 px-4 mx-auto mb-2"
                        onClick={(e) => {
                          setDisableInitialAnimation(true); // Disable initial animation temporarily
                          loadmoreHander(e);
                        }}
                      >
                        Load more candidates
                      </button>
                    )
                  )}
                </>
              )}

              <span className="block text-gray-400">
                Showing{" "}
                {filterData?.length < 20
                  ? filterData?.length
                  : filterData?.length < visible
                  ? filterData.length
                  : visible}{" "}
                of {filterData?.length}
              </span>
            </div>

            {/* {filterData?.length < 20
                  ? filterData?.length
                  : filterData?.length < visible
                  ? filterData.length
                  : visible == filterData?.length ? null : (
                    <div className="text-center px-4 mt-5 max-w-full w-full">
              {authentication_ref?.current.dash_data?.loading ? (
                <button
                  type="button"
                  className="text-gray-1000 flex items-center mx-auto"
                  disabled
                >
                  <span
                    className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-deep-purple-500 border-l-transparent rounded-full"
                    role="status"
                  ></span>
                  Loading...
                </button>
              ) : (
                filterData?.length > 19 && (
                  <button
                    type="button"
                    value={size}
                    className="bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-2 px-4 mx-auto mb-3"
                    onClick={(e) => {
                      loadmoreHander(e);
                    }}
                  >
                    Load more candidates
                  </button>
                )
              )}

              <span className="block text-gray-400">
                Showing{" "}
                {filterData?.length < 20
                  ? filterData?.length
                  : filterData?.length < visible
                  ? filterData.length
                  : visible}{" "}
                of{" "}
                {
                  filterData?.length
                }
              </span>
            </div>
                  )} */}
          </div>
        )}

        {showModel != true && (
          <footer className="lg:ml-[370px] text-center mt-6 pb-5">
            <h6>
              <small className="text-base text-gray-400">
                &copy; Startup Lane 2024
              </small>
            </h6>
          </footer>
        )}
        {showPrimiumModel == true && ShowPaymentModel()}
        {/* {showModel == true && ShowCandiateModel()} */}
      </div>
    </>
  );
};

Dashboard.propTypes = {
  fetchDashboard: PropTypes.func,
  fetchNewDashboard: PropTypes.func,
  fetchVentureData: PropTypes.func,
  fetchCompanyDash: PropTypes.func,
  fetchRegisteredCandidate: PropTypes.func,
  fetchUniqueLink: PropTypes.func,
  fetchUSerDetails: PropTypes.func,
  fetchRoles: PropTypes.func,
  fetchDesire: PropTypes.func,
  fetchExperience: PropTypes.func,
  fetchGlobalSearch: PropTypes.func,
  UserLogout: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  fetchDashboard: getDash,
  fetchNewDashboard: getNewDash,
  fetchVentureData: getPortfolioComp,
  fetchCompanyDash: getCompanyDash,
  fetchRegisteredCandidate: getCompanyData,
  fetchRoles: getRoles,
  fetchUniqueLink: getUniqueLink,
  fetchUSerDetails: getUserDetails,
  fetchDesire: getDesire,
  fetchExperience: getExperience,
  fetchGlobalSearch: getGlobalSearch,
  UserLogout: getLogOut,
})(Dashboard);

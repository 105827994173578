import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "../../../Login/index.css";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { getCookie, setCookie } from "../../../../shared/utils/cookies";
import ViewIcon from "../../images/ViewSelected.svg";
import InactiveEye from ".././../images/InactiveEye.svg";
import {
  getDash,
  getLogOut,
} from "../../../authentication";
import PageHeaders from "./PageHeaders";
import { Helmet } from "react-helmet";
import Select, { NonceProvider } from "react-select";
import { PropTypes } from "prop-types";
import axios from "axios";
import UiComp from "./UiComp";
import {GooglePlacesSelect} from "../../../../shared/components/googlePlaces";

const CandidateDetailTable = ({ authentication_state, fetchDashboard, UserLogout, }) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const menuRefs = useRef();
  const UserID = localStorage.getItem("usersIdentity");
  const RoleName =
    authentication_ref?.current?.Roles_data?.response?.data?.data?.rolename;
  const FullData =
    authentication_ref?.current?.dash_data?.response?.data?.data?.data || []; 
  const Token = getCookie("access_token");
  const storedCompanyId = localStorage.getItem('companyId');
  const storedCompanyName = localStorage.getItem('companyName')
  const CompanyName = authentication_ref?.current?.port_data?.response?.data?.data[0]
  const dilogRef = useRef();
  const menu = useRef();
  const [search, setSearch] = useState("");
  const [cardData, setCardData] = useState([]);
  const [CandidateDetails, setCandidateDetails] = useState(false)
  const [show, setShow] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showModelss, setShowModelsss] = useState(false);
  const [profileIcon, setProfileIcon] = useState(false);
  const [candata, setCanData] = useState();
  const [file, setFileName] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);

  let ExpertiseMapStringresult = [
    { currItem: "Biz Ops & Strategy" },
    { currItem: "Product" },
    { currItem: "Commercial" },
    { currItem: "Growth & Marketing" },
    { currItem: "People & Talent" },
    { currItem: "Customer Success" },
    { currItem: "Finance" },
    { currItem: "Legal" },
    { currItem: "Risk & Compliance" },
    { currItem: "Software Engineering" },
    { currItem: "Data Science / Machine Learning" },
    { currItem: "BI / Data Analytics" },
    { currItem: "Procurement & Supply Chain" },
    { currItem: "Administrative" },
  ];

    // Gender Array
    let GenderArray = [
      { currItem: "Female" },
      { currItem: "Male" },
      { currItem: "Transgender" },
      { currItem: "Non-binary" },
      { currItem: "Prefer not to say" },
    ];

      // EthencityArray Array
  let EthencityArray = [
    { currItem: "White" },
    { currItem: "Black or African American" },
    { currItem: "Hispanic or Latino" },
    { currItem: "Asian" },
    { currItem: "Mixed" },
    { currItem: "Prefer not to say" },
  ];

  //Edit Model State Used ------>
  const [editfirstName, setEditFirstName] = useState("")
  const [editLastName, setEditLastName] = useState("")
  const [editLocation, setEditLocation] = useState("")
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [editExpertise, setEditExpertise] = useState()
  const [editRole, setEditRole] = useState("")
  const [editWorkFor, setEditWorkFor] = useState("")
  const [editBoardDirector, setEditBoardDirector] = useState()
  const [editLinkedIn, setEditLinkedIn] = useState("")
  const [editEmail, setEditEmail] = useState("")
  const [editCarrer, setEditCareer] = useState([])
  const [typeOfwork, setTypeOfWork] = useState([])
  const [editExperience, setEditExperience] = useState()
  const [industries, setIndustries] = useState([])
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [editLikeToWork, setEditLikeToWork] = useState([])
  const [editStage, setEditStage] = useState([])
  const [editTextArea, setEditTextArea] = useState("")
  const [editReferred, setEditReferred] = useState("")
  const [editGender, setEditGender] = useState(null)
  const [editEthnicity, setEditEthnicity] = useState(null)
  const [CV, setCV] = useState(null)
  const [capital, setCapital] = useState("");
  const [access, setAccess] = useState("");
  const [Extention, setExtention] = useState(null)
  const [files, setFiles] = useState();
  const [deleteModel, setDeleteModel] = useState(false);
  const [VentureData, setVentureData] = useState()

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${UserID}/`,
        headerData
      ).then((res) => {
        setVentureData(res?.data?.data)
      })
  }, [])

  // useEffect(() => {
  //   const result = FullData.filter((candidate) => {
  //     return(
  //       candidate.firstname.toLocaleLowerCase().match(search.toLocaleLowerCase()) || 
  //       candidate.lastname.toLocaleLowerCase().match(search.toLocaleLowerCase())
  //     )
  //   });
  //   setFullData(result);
  // }, [search]);

  useEffect(() => {
    if(candata != undefined){
      setEditFirstName(candata.firstname)
      setEditLastName(candata.lastname)
      // setEditLocation(candata?.about?.currentLocation)
      setEditExpertise({ currItem : candata?.about?.functionalexpertise})
      setEditRole(candata?.careerplan?.work)
      setEditWorkFor(candata?.careerplan?.employer)
      setEditBoardDirector(candata?.careerplan?.experience)
      setEditLinkedIn(candata?.about?.linkedin)
      setEditEmail(candata.email)
      setEditCareer(candata?.careerplan?.careerlevel?.split(",") || [])
      setTypeOfWork(candata?.about?.worktype)
      setEditExperience(candata?.careerplan?.remote)
      setIndustries(candata?.careerplan?.industry == "" || candata?.careerplan?.industry == null ? candata?.careerplan?.industry_experience?.split(",") :  candata?.careerplan?.industry?.split(","))
      setMarketPlaces(candata?.careerplan?.kind_of_business?.split(","))
      setEditLikeToWork(candata?.careerplan?.workplace?.split(","))
      setEditStage(candata?.careerplan?.stage?.split(",") || [])
      setEditTextArea(candata?.about?.candidate_description)
      setCapital(candata?.careerplan?.capital)
      setAccess(candata?.access)
      setEditGender({currItem : candata.about?.gender})
      setEditEthnicity({currItem : candata.about?.ethnicity})
      setCV(candata.cv)
      setExtention(candata.ext)
    }
  }, [candata])

// This useEffect will set the initial location if available or reset when candata changes
useEffect(() => {
  if (candata?.about?.currentLocation) {
    setEditLocation(candata.about.currentLocation);
    setEditReferred(candata?.referred_by)
    setIsInitialDataLoaded(true);
  } else {
    setEditLocation("");
    setIsInitialDataLoaded(false);
    setEditReferred("")
  }
}, [candata, showModel]);

useEffect(() => {
  if(showModel == false){
    setEditLocation("");
    setIsInitialDataLoaded(false);
  }
}, [showModel])

  const ArrayThing = editLikeToWork?.map(item => ({currItem: item}))

  const StageArrays = editStage?.map(item => ({currItem : item}))

  const handleSelectionChanges = (item) => {
    let likeToWorkArray = [];
    const likeToWorkArrayMap = item?.map((items) => {
      likeToWorkArray.push(items.currItem);
    });
    setEditLikeToWork(likeToWorkArray)
  }

  const handleStageChange = (item) => {
    let Stages = [];
    const StagesArray = item?.map((items) => {
      Stages.push(items.currItem)
    })
    setEditStage(Stages)
  }

  const handleRowData = (state) => {
    setCardData(state.selectedRows);
  };

  const headers = [
    {
      label: "First Name",
      key: "firstname",
    },
    {
      label: "Last Name",
      key: "lastname",
    },
    {
      label: "Functional Expertise",
      key: "about.functionalexpertise",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Job Title",
      key: "careerplan.work",
    },
    {
      label: "Current Employer",
      key: "careerplan.employer",
    },
    {
      label: "Current Location",
      key: "about.currentLocation.formatted_address",
    },
    {
      label: "LinkedIn",
      key: "about.linkedin",
    },
  ];

  const csvLink = {
    filename: "CandidateDetails.csv",
    headers: headers,
    data: cardData,
  };


  const CustomTitleLinkIn = ({ row }) => (
    <div>
      {}
      <div>{row.about.linkedin}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );
  const CustomTitleEmail = ({ row }) => (
    <div>
      {}
      <div>{row.email}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );
  const CustomTitleName = ({ row }) => (
    <div>
      {}
      <div>{`${row.firstname}  ${row.lastname}`}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );
  const CustomTitleJob = ({ row }) => (
    <div>
      {}
      <div>{row.careerplan.work}</div>
      <div>
        <div
          data-tag="allowRowEvents"
          style={{
            color: "grey",
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipses",
          }}
        >
          {}
          {row.plot}
        </div>
      </div>
    </div>
  );

  const handleModelButtonClick = (row) => {
    setShowModel(true);
    setCanData(row);
  };

  const handledeleteModelClose = () => {
    setDeleteModel(false)
  }

  const handledetailsModelClose = () => {
    setCandidateDetails(false)
    setCardData([])
  }

  const handleClose = () => {
    setShow(false);
    setShowModel(false);
    setFileName(null);
    setFiles(undefined)
  };

  const handleFileInputChange = (e) => {
    let files = e.target.files[0];
    setFileName(files);
  };

  const handleFileInputChangeCV = (e) => {
    let files = e.target.files[0];
    setFiles(files);
  }

  useEffect(() => {
    setLoadings(true);
    fetchUsers(1); // fetch page 1 of users
  }, []);

  const GetAllData = () => {
    setLoadings(true);
    fetchUsers(1);
  }

  const fetchUsers = async (page) => {
    try {
      const response = await axios({
        method: "get",
        url: `/api/candidate/candidate/getall/${storedCompanyId}/?page=${page}&size=${perPage}`,
        headers: {
          "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
          Authorization: `Bearer ${Token}`,
        },
      });
      if (response.status === 200) {
        setData(response.data.data.data);
        setTotalRows(response.data.data.count); // Set the actual total count received from the API
        setLoadings(false);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
      setLoadings(false);
    }
  };

  const handlePageChange = (page) => {
    // Check if the page number has changed before making the API call
    if (currentPage !== page) {
      setLoadings(true);
      fetchUsers(page);
      setCurrentPage(page); // Update the state to reflect the current page
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (perPage !== newPerPage){
      setLoadings(true);
      try {
        const response = await axios({
          method: "get",
          url: `/api/candidate/candidate/getall/?page=${page}&size=${newPerPage}`,
          headers: {
            "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
            Authorization: `Bearer ${Token}`,
          },
        });
        if (response.status === 200) {
          setData(response.data.data.data);
          setPerPage(newPerPage);
          setLoadings(false);
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
        setLoadings(false);
      }
    }
  };

  const totalPages = Math.ceil(totalRows / perPage);

  const handleSearchInputChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearch(newSearchQuery)
    setShowModelsss(true);
  }

  const fetchDataFromAPI = (search) => {
    // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint URL
    const apiEndpoint = `/api/candidate/candidate/getall/${storedCompanyId}/?search_name=${encodeURIComponent(search)}`;
        // Customize the headers as needed
        const headers = {
          'Content-Type': 'application/json', // Adjust the Content-Type as per your API requirements
           Authorization: `Bearer ${Token}`, // Replace with your actual authorization token
        };

    // Perform the API request using fetch or any other suitable method
    fetch(apiEndpoint, {headers})
      .then(response => response.json())
      .then(data => {
        setData(data.data.data);
        setTotalRows(data?.data?.data?.length);
        // Process the API response data here
      })
      .catch(error => {
        console.error('Error fetching data from API:', error);
      });
  };

  useEffect(() => {
    // Set up a timer to call the API after a delay when searchQuery changes
    const debounceTimeout = setTimeout(() => {
      if (search !== '') {
        fetchDataFromAPI(search);
      }else if(showModelss == true) {
        setLoadings(true);
        fetchUsers(1);
      }
    }, 500); // Adjust the delay (in milliseconds) as needed

    // Clear the timer when the component is unmounted or when search changes
    return () => clearTimeout(debounceTimeout);
  }, [search]);


  const DeleteCandidateModel = () => {
    setDeleteModel(true)
    setShowModel(false)
  }

  const DeleteCandidate = () => {
    setLoading(true);
    axios({
      method: "delete",
      url: `/api/candidate/candidate/delete/${candata.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(candata.id)
    })
    .then((res) => {
      if (res.status == 200) {
        setLoading(false);
        setDeleteModel(false)
        setShowModel(false);
        setFiles(undefined);
        GetAllData()
      }
    })
    .catch((err) => {
      if(err){
        setLoading(false);
      }  
    })
  }

  const EditCandidate = () => {
    setLoading(true);
    if(candata?.careerplan?.industry == ""){
      const data = {
        firstname: editfirstName,
        lastname: editLastName,
        email: editEmail,
        access: access,
        referred_by: editReferred ? editReferred : null,
        about: {
          candidate_description: editTextArea,
          currentLocation: editLocation,
          // nativeplace: editLocation?.currItem,
          functionalexpertise: editExpertise?.currItem,
          gender: editGender.currItem, 
          ethnicity: editEthnicity.currItem,
          linkedin: editLinkedIn,
          worktype: typeOfwork,
        },
        careerplan: {
          kind_of_business: marketPlaces.toString(),
          work: editRole,
          employer: editWorkFor,
          careerlevel: editCarrer?.toString(),
          capital:capital,
          industry: "",
          industry_experience: industries.toString(),
          stage: editStage.toString(),
          // workplace: editLikeToWork.join(","),
          remote: editExperience,
          experience: editBoardDirector,
          work_preference: editWorkFor,
        },
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      };
      axios.put(`/api/candidate/candidate/update/${candata.id}/`, data, headerData)
      .then((res) => {
        if (res.status == 206) {
          setLoading(false);
          setShowModel(false);
          setFiles(undefined);
          GetAllData()
        }
      })
      .catch((err) => {
        if(err){
          setLoading(false);
        }  
      })
        if(files != undefined){
          const headerData = {
        headers: {
          "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
        Authorization: `Bearer ${Token}`,
        },
      };
      var filey = new FormData();
      filey.append("file", files);
      axios.put(`/api/candidate/candidate/update/cv/${candata.id}`, filey, headerData)
          .then((res) => {
            if (res.status == 206) {
              setLoading(false);
              setShowModel(false);
              setFiles(undefined);
              GetAllData()
            }
          })
          .catch((err) => {
            if(err){
              setLoading(false);
            }  
          })
      }
    }else{
      const data = {
        firstname: editfirstName,
        lastname: editLastName,
        email: editEmail,
        access: access,
        referred_by: editReferred ? editReferred : null,
        about: {
          candidate_description: editTextArea,
          currentLocation: editLocation,
          // nativeplace: editLocation?.currItem,
          functionalexpertise: editExpertise?.currItem,
          gender: editGender.currItem, 
          ethnicity: editEthnicity.currItem,
          linkedin: editLinkedIn,
          worktype: typeOfwork,
        },
        careerplan: {
          kind_of_business: marketPlaces.toString(),
          work: editRole,
          employer: editWorkFor,
          careerlevel: editCarrer?.toString(),
          capital:capital,
          industry: industries.toString(),
          industry_experience: "",
          stage: editStage.toString(),
          // workplace: editLikeToWork.join(","),
          remote: editExperience,
          experience: editBoardDirector,
          work_preference: editWorkFor,
        },
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      };
      axios.put(`/api/candidate/candidate/update/${candata.id}/`, data, headerData)
      .then((res) => {
        if (res.status == 206) {
          setLoading(false);
          setShowModel(false);
          setFiles(undefined);
          GetAllData()
        }
      })
      .catch((err) => {
        if(err){
          setLoading(false);
        }  
      })
        if(files != undefined){
          const headerData = {
        headers: {
          "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
        Authorization: `Bearer ${Token}`,
        },
      };
      var filey = new FormData();
      filey.append("file", files);
      axios.put(`/api/candidate/candidate/update/cv/${candata.id}`, filey, headerData)
          .then((res) => {
            if (res.status == 206) {
              setLoading(false);
              setShowModel(false);
              setFiles(undefined);
              GetAllData()
            }
          })
          .catch((err) => {
            if(err){
              setLoading(false);
            }  
          })
      }
    }
  }

  const DeleteImage = () => {
    setLoading(true);
    axios({
      method: "delete",
      url: `/api/candidate/candidate/delete/profilepic/${candata.id}/`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
        Authorization: `Bearer ${Token}`,
      },
    }).then((res) => {
      if (res.status == 206) {
        setLoading(false);
        setShow(false);
        setFileName(null);
        GetAllData();
      }
    }).catch((err) => {
      if(err){
        setLoading(false);
      }
    })
  }


  const SubmitImage = () => {
    if (file != undefined) {
      setLoading(true);
      var filey = new FormData();
      filey.append("file", file);
      axios({
        method: "put",
        url: `/api/candidate/candidate/update/profilepic/${candata.id}`,
        data: filey,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {
        if (res.status == 206) {
          setLoading(false);
          setShow(false);
          setFileName(null);
          GetAllData();
        }
      });
    }
  };

  const Funtionstesting = (row) => {
    const FirstName = row.firstname.trim();
    const LastName = row.lastname.trim()
    var new_url = `/candidate/${
      row.id
    }-${FirstName
      ?.charAt(0)
      ?.toLowerCase()}${FirstName
      ?.slice(1)
      ?.toLowerCase().replaceAll(' ', '-')}-${LastName
      ?.charAt(0)
      ?.toLowerCase()}${LastName?.slice(1)?.toLowerCase().replaceAll(' ', '-')}`;
      return new_url;
  }

  const handleImageChange = (row) => {
    if(RoleName == "admin"){
      setShow(true);
      setCanData(row);
    }
  }

  const VcColumns = [
    {
      name: "Full Name",
      selector: (row) => `${row.firstname}  ${row.lastname}`,
      sortable: true,
      cell: (row) => (
        <div>{
          <a href={`${Funtionstesting(row)}`} target="_blank" rel="noopener noreferrer">
          <CustomTitleName row={row} />
          </a>
        }</div>
      ) ,
      maxWidth: "650px",
      minWidth: "150px",
      style: {
        					textDecoration: 'underline',
        					color: 'blue',
        					'&:hover': {
        						cursor: 'pointer',
        					},
        				},
    },
    {
      name: "Functional Expertise",
      selector: (row) => row.about.functionalexpertise,
    },
    {
      name: "Job Title",
      selector: (row) => row.careerplan.work,
      cell: (row) => <CustomTitleJob row={row} />,
      maxWidth: "650px",
      minWidth: "200px",
    },
    {
      name: "Current Employer",
      selector: (row) => row.careerplan.employer,
    },
    {
      name: "Current Location",
      selector: (row) => row?.about?.currentLocation?.formatted_address,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <CustomTitleEmail row={row} />,
      maxWidth: "650px",
    },
    // {
    //   name: "Source",
    //   selector: (row) => row.careerplan.capital == "admin" ? "Startup Lane" : row.careerplan.capital == "Oak HC/FT" ? "Oak HC/FT" : row.careerplan.capital == "M13" ? "M13" : row.careerplan.capital == "Human Capital" ? "Human Capital" : "Octopus Ventures",
    // },
  ]

  const columns = [
    {
      name: "Thumbnail",
      cell: row => (
        <>
            <div>{row.profilepic ? (
           <figure className="mt-3 mb-2 shrink-0 bg-deep-purple-50 rounded-full w-12 md:w-[60px]  h-12 md:h-[60px] inline-flex items-start justify-center overflow-hidden cursor-pointer" onClick={() => handleImageChange(row)} style={{width:'30px', height:'30px'}}>
                <img
                  className="max-w-full h-auto min-h-full rounded-full"
                  // src={`https://cnp1656-dev-image.s3.amazonaws.com/profilepic/${row.profilepic}`}
                  src={`https://cnp1656-prod.s3.amazonaws.com/profilepic/${row.profilepic}`}
                  // src={`${window.location.origin}/api/candidate/profilepic/${row.profilepic}`}
                  alt={row?.firstname + " " + row?.lastname}
                />
              </figure>
        ) : (
          <figure className="mt-3 mb-2 shrink-0 bg-deep-purple-50 rounded-full w-12 h-12 inline-flex items-center justify-center cursor-pointer"  onClick={() => handleImageChange(row)} style={{width:'30px', height:'30px'}}>           
                  <span className="text-md text-deep-purple-500 font-medium">
                  {row?.firstname[0] + row?.lastname[0]}
                </span>             
              </figure>
        )}</div>
        </>
      ),
      maxWidth: "100px",
      minWidth: "50px",
    },
    {
      name: "Full Name",
      selector: (row) => `${row.firstname}  ${row.lastname}`,
      sortable: true,
      cell: (row) => (
        <div>{
          <a href={`${Funtionstesting(row)}`} target="_blank" rel="noopener noreferrer">
          <CustomTitleName row={row} />
          </a>
        }</div>
      ) ,
      style: {
        					textDecoration: 'underline',
        					color: 'blue',
        					'&:hover': {
        						cursor: 'pointer',
        					},
        				},
    },
    {
      name: "Functional Expertise",
      selector: (row) => row.about.functionalexpertise,
    },
    {
      name: "Job Title",
      selector: (row) => row.careerplan.work,
      cell: (row) => <CustomTitleJob row={row} />,
      maxWidth: "650px",
      minWidth: "200px",
    },
    {
      name: "Current Employer",
      selector: (row) => row.careerplan.employer,
    },
    {
      name: "Current Location",
      selector: (row) => row?.about?.currentLocation?.formatted_address,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <CustomTitleEmail row={row} />,
      maxWidth: "650px",
    },
    {
      name: "LinkedIn",
      selector: (row) => row.about.linkedin,
      button: true,
      cell: (row) => (
        <div>{RoleName == "admin" && (
            <a  href={
            row?.about?.linkedin.includes("http")
              ? row?.about?.linkedin
              : `https://${row?.about?.linkedin}`
          } target="_blank" rel="noopener noreferrer">
            <CustomTitleLinkIn row={row}/>
          </a> 
        )}</div>     
      ),
      maxWidth: "200px",
      minWidth: "100px",
      style: {
        textDecoration: 'underline',
        color: 'blue',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      name: "Source",
      selector: (row) => row.careerplan.capital == "admin" || row.careerplan.capital == "Startup Lane" ? "Startup Lane" : row.careerplan.capital == "Anzu Partners" ? "Anzu Partners" : row.careerplan.capital == "Burnt Island Ventures" ? "Burnt Island Ventures" : row.careerplan.capital == "M13" ? "M13" : row.careerplan.capital == "Human Capital" ? "Human Capital" : row.careerplan.capital == "Global VC Talent Network" ? "Global VC Talent" : row.careerplan.capital == "Better Tomorrow Ventures" ? "Better Tomorrow Ventures" : row.careerplan.capital == "Left Lane Capital" ? "Left Lane Capital" : row.careerplan.capital == "Demo VC" ? "Demo VC" : row.careerplan.capital == "Upfront Ventures" ? "Upfront Ventures" : "Octopus Ventures",
    },
    {
      name: "Edit",
      cell: (row) => (
        <div>        
            <button
              className="bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition p-2"
              onClick={() => {
                handleModelButtonClick(row);
              }}
            >
              Edit
            </button>
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setTypeOfWork([...typeOfwork, value]);
    } else {
      setTypeOfWork(typeOfwork.filter((e) => e !== value));
    }
  };

  const handleCareerWork = (event) => {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      // Add the checkbox value to selectedCheckboxes if the limit is not reached
      if (editCarrer.length < 4) {
        setEditCareer([...editCarrer, checkboxValue]);
      } else {
        // Prevent selecting more checkboxes if the limit is reached
        event.target.checked = false;
      }
    } else {
      // Remove the checkbox value from selectedCheckboxes
      setEditCareer(editCarrer.filter((value) => value !== checkboxValue));
    }
  };

  const handleStagesArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setEditStage([...editStage, value]);
    } else {
      setEditStage(editStage.filter((e) => e !== value));
    }
  }

  const handleMarketPlace = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setMarketPlaces([...marketPlaces, value]);
    } else {
      setMarketPlaces(marketPlaces.filter((e) => e !== value));
    }
  }

  const handleIndustries = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setIndustries([...industries, value]);
    } else {
      setIndustries(industries.filter((e) => e !== value));
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault()
    setSearch(e.target.value)
  }

  const handleCampanyTeamMember = () => {
    window.open(`${window.location.origin}/manage-company/team-member`);
  };

    //LoggOut user
    const LogoutUser = () => {
      UserLogout();
      setCookie("access_token", "");
      window.localStorage.removeItem("Token");
      window.location.href = "/";
    };

  const handleCandidateDetails = () => {
      window.open(`${window.location.origin}/Candidates/details`);
  };
  
  const handleRegisteredComp = () => {
    window.open(`${window.location.origin}/Company/details`);
  };

  const handleList = () => {
    window.open(`${window.location.origin}/lists`);
  };

  const handlemanageTeamMember = () => {
    window.open(`${window.location.origin}/manage/team-member`);
  };

  const handleCompaniesDetails = () => {
    window.open(`${window.location.origin}/companies/details`);
  };

  useEffect(() => {
    let handler = (event) => {
      if (!menuRefs?.current?.contains(event.target)) {
        setProfileIcon(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    if(showModel){
      let handler = (event) => {
        if(event.target.classList.value === "css-tj5bde-Svg"){
          return
        }
        if(!dilogRef?.current?.contains(event.target) && event.target.classList.length != 0){
          handleClose()
        }
      }
      document.addEventListener("mousedown", handler);
  
      return () => {
        document.removeEventListener("mousedown", handler)
      }
    }
  }, [showModel])

  const handleCollection = () => {
    window.open(`${window.location.origin}/candidates/connections`);
  }

  return (
    <>
     <Helmet>
        <title>
        Manage Candidates      
        </title>
      </Helmet>
      {CandidateDetails == false ? (
        <>
        <div
      className="leading-normal text-gray-1000 bg-deep-purple-10 font-sans min-h-screen	h-full"
      style={{ fontFamily: "DM Sans, sans-serif" }}
    >
      <PageHeaders
       title={"Candidates"}
       VentureData={VentureData}
       CompanyName={CompanyName}
       userRole={RoleName}
       profileIcon={profileIcon}
       menuRefs={menuRefs}
       handleCollection={handleCollection}
       storedCompanyName={storedCompanyName}
       setProfileIcon={setProfileIcon}
       handleCampanyTeamMember={handleCampanyTeamMember}
       LogoutUser={LogoutUser}
       handleCandidateDetails={handleCandidateDetails}
       handleRegisteredComp={handleRegisteredComp}
       handleList={handleList}
       handlemanageTeamMember={handlemanageTeamMember}
       handleCompaniesDetails={handleCompaniesDetails}
          />
        <div className="pt-[9.5rem] sm:pt-[9.75rem] lg:pt-[9rem]">
          <section className="fixed z-10 inset-x-0 top-[100px] sm:top-[4rem] lg:top-[3.25rem] bg-deep-purple-10 w-full px-7 pt-2 lg:pt-4 border-b border-gray-300">
            <form className="flex relative TableHeads" method="POST" action="#">
              <div className="inline-flex items-center group lg:w-48 lg:pr-1.5 mb-2 lg:mb-4">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="absolute z-[1] left-4 mb-0.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.50441 0.554688C3.19958 0.554688 0.504395 3.24987 0.504395 6.55471C0.504395 9.85954 3.19958 12.5547 6.50441 12.5547C8.02237 12.5547 9.4082 11.9825 10.4668 11.0474L11.0044 11.585V12.5547L13.6939 15.2442C14.1079 15.6582 14.7799 15.6582 15.1939 15.2442C15.6079 14.8302 15.6079 14.1582 15.1939 13.7442L12.5044 11.0547H11.5347L10.9971 10.5171C11.9322 9.45849 12.5044 8.07266 12.5044 6.55471C12.5044 3.24987 9.80925 0.554688 6.50441 0.554688ZM6.50441 2.05469C8.99859 2.05469 11.0044 4.06053 11.0044 6.55471C11.0044 9.04888 8.99859 11.0547 6.50441 11.0547C4.01024 11.0547 2.0044 9.04888 2.0044 6.55471C2.0044 4.06053 4.01024 2.05469 6.50441 2.05469Z"
                  fill="#434343"
                />
              </svg>
              <input
                type="search"
                id="search"
                value={search}
                onChange={handleSearchInputChange}
                // onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {if (e.key === 'Enter') {
                  e.preventDefault()
                  setSearch(e.target.value)
                }}}
                placeholder="Search name"
                className="w-[42px] absolute sm:relative hover:w-full focus:w-full sm:w-48 sm:hover:w-48 sm:focus:w-48 sm:active:w-48 appearance-none text-ellipsis text-base leading-3 sm:leading-6 bg-white font-normal text-gray-800  placeholder:text-gray-400 rounded-lg py-2 hover:pr-4 focus:pr-4 sm:pr-4 pl-9 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-deepe-purplee-500 focus:border-deepe-purplee-500 transition duration-500"
              />
            </div>
            <div className="flex items-center ml-auto justify-between mb-2 lg:mb-4">
            {
                cardData.length === 0 ? (
                  <>
                  <button
                  type="button"
                  className={"add-candidate-btnnn mr-3 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg transition duration-200 py-3 px-4"} 
                >
                <svg
                      width="16px"
                      className="mr-2 mb-0.5"
                      height="19px"
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="noun-download-5485540"
                          fill="#B8B7BD"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                      {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                 {/* <img src={Inactive} className="mr-2 mb-1" /> */}
                  Download CSV
                </button>
                  </>
                ) : (
                  <>
                  <CSVLink {...csvLink}>
                  <button
                    type="button"
                    className="csvButton mr-3 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                  >
                     <svg
                      width="16px"
                      className="mr-2 mb-0.5"
                      height="19px"
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="noun-download-5485540"
                          fill="#434343"
                          fill-rule="nonzero"
                        >
                          <path
                            d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                      {/* <path d="M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,8.08261861 20.8410748,5.28472557 18.7781746,3.22182541 C16.7152744,1.15892524 13.9173814,0 11,0 Z M14.71,13.71 L11.71,16.71 C11.5212812,16.8971865 11.265803,17.0015368 11,17.0000167 C10.735233,16.9963165 10.4816338,16.8927337 10.29,16.71 L7.29,13.71 C6.89787783,13.3178778 6.89787783,12.6821222 7.29,12.29 C7.68212217,11.8978778 8.31787783,11.8978778 8.71,12.29 L10,13.59 L10,6 C10,5.44771525 10.4477153,5 11,5 C11.5522847,5 12,5.44771525 12,6 L12,13.59 L13.29,12.29 C13.6821222,11.8978778 14.3178778,11.8978778 14.71,12.29 C15.1021222,12.6821222 15.1021222,13.3178778 14.71,13.71 Z" id="Shape"></path> */}
                    </svg>
                    Download CSV
                  </button>
                </CSVLink>
                  </>
                )
              }
            {
              cardData.length === 0 ? (
                <button
                type="button"
                // onClick={() => {
                //   setCandidateDetails(true)
                // }}
                disabled={cardData?.length > 0 ? false : true}
                className="add-candidate-btnnn ml-3 inline-flex items-center text-base font-medium whitespace-nowrap rounded-lg transition duration-200 py-3 px-4"
              >
              <img src={InactiveEye} className="mr-2" />
                View Selected Candidates
              </button>
              ) : (
                <button
                type="button"
                onClick={() => {
                  setCandidateDetails(true)
                }}
                disabled={cardData?.length > 0 ? false : true}
                className="add-Port-Button ml-3 inline-flex items-center text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
              >
              <img src={ViewIcon} className="mr-2" />
                View Selected Candidates
              </button>
              )
            }
            </div>
          </form>
        </section>
        <section className="flex flex-wrap">
          <DataTable
            // fixedHeader
            fixedHeaderScrollHeight="100%"
            columns={RoleName == "admin" ? columns : VcColumns}
            data={data}
            pagination
            paginationTotalRows={totalRows}
            paginationServer
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[100, 200, 300, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
            selectableRowsVisibleOnly
            progressPending={loadings}
            onSelectedRowsChange={handleRowData}
            selectableRowsHighlight
            highlightOnHover
            // subHeader
            // subHeaderAlign="left"
          />
        </section>
        {deleteModel == true && (
            <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
              <div className="modal-backdrop fade show"></div>
              <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <h2 className="text-2xl font-bold text-center">
                    Are you sure you want to delete this candidate ?
                  </h2>
                  <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-gray-300 rounded-t-lg">
                    <button
                      type="button"
                      className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                      onClick={handledeleteModelClose}
                      aria-label="Close"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                          fill="#6936FF"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="inline-flex justify-between pb-3" role="group">
                    <button
                      type="button"
                      onClick={DeleteCandidate}
                      style={{
                        marginLeft: "130px",
                      }}
                      className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-10"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      style={{
                        marginRight: "130px",
                      }}
                      onClick={handledeleteModelClose}
                      className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-10"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        {showModel == true && (
          <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
            <div className="modal-backdrop fade show"></div>
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full w-lg pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current" ref={dilogRef}>
                {/* <h2 className="text-2xl font-bold text-center mb-2">
                  Choose Profile Pic For{" "}
                  {`${candata.firstname} ${candata.lastname}`}
                </h2> */}
                <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-b border-gray-300 rounded-t-lg">
                  <button
                    type="button"
                    className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                    onClick={(e) => {
                      if(e){
                        handleClose()
                      }
                      }}
                    aria-label="Close"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                        fill="#6936FF"
                      />
                    </svg>
                  </button>
                  <section className="w-full bg-white rounded-xl shadow max-w-[26.25rem] mx-auto p-4 md:p-8">
                    <form className="flex flex-col" method="POST" action="#">
                      <div className="flex space-x-3">
                        <div className="w-full md:w-1/2 mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="email"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            id="firstName"
                            value={editfirstName}
                            onChange={(e) => {
                              setEditFirstName(e.target.value);
                            }}
                            // "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-300 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                            placeholder="Jane"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                        <div className="w-full md:w-1/2 mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                            htmlFor="password"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="lastName"
                            value={editLastName}
                            onChange={(e) => {
                              setEditLastName(e.target.value);
                            }}
                            placeholder="Doe"
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                          />
                        </div>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Where do you <strong>live</strong>?
                        </label>
                        {isInitialDataLoaded ? (
                            <GooglePlacesSelect
                                 value={editLocation || null}
                                   onChange={location => setEditLocation(location)}
                                 placeholder={"Type your location"}
                            />
                        ) : (
                            <div>Loading...</div> // You can show a loading state here
                        )}
                        {/* <GooglePlacesSelect value={editLocation || null} onChange={location => setEditLocation(location)} placeholder={"Choose Location"}/> */}
                        {/* <Select
                          placeholder="Choose Location"
                          name="Location"
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}                        
                          onChange={(item) => setEditLocation(item)}
                          value={editLocation}
                          options={uniqueLocationresult}
                        /> */}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          What are you <strong>best at</strong>?
                        </label>
                        <Select
                          placeholder="Choose Expertise"
                          name="Expertise"
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          value={editExpertise}
                          onChange={(item) => setEditExpertise(item)}
                          options={ExpertiseMapStringresult}
                        />
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          What was your most recent <strong>role</strong>?
                        </label>
                        <input
                          type="text"
                          id="role"
                          value={editRole}
                          onChange={(e) => {
                            setEditRole(e.target.value);
                          }}
                          placeholder="Senior Designer"
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                        />
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Who did you last <strong>work for</strong>?
                        </label>
                        <input
                          type="text"
                          id="employer"
                          placeholder="Acme LTD"
                          value={editWorkFor}
                          onChange={(e) => {
                            setEditWorkFor(e.target.value);
                          }}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                        />
                      </div>
                      <div className="w-full mb-4">
                      <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          What <strong>kinds of businesses </strong>have you worked for ?
                        </label>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                        <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2b"
                              type="checkbox"
                              value={"B2B"}
                              checked={marketPlaces?.includes(
                                "B2B"
                              )}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2b"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2B
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2g"
                              type="checkbox"
                              value={"B2G"}
                              checked={marketPlaces?.includes("B2G")}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2g"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2G
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2c"
                              type="checkbox"
                              value={"B2C"}
                              checked={marketPlaces?.includes("B2C")}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2c"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2C
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Marketplace"
                              type="checkbox"
                              value={"Marketplace"}
                              checked={marketPlaces?.includes("Marketplace")}
                              onChange={(e) => handleMarketPlace(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Marketplace"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Marketplace
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Have you ever been on a{" "}
                          <strong>board of directors</strong>?
                        </label>
                        <div className="flex space-x-3">
                          <div className="w-1/2 relative">
                            <input
                              type="radio"
                              id="radio-4"
                              checked={editBoardDirector === "Yes"}
                              value="Yes"
                              onClick={(e) => {
                                setEditBoardDirector(e.target.value);
                              }}
                              name="startupBoardDirector"
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-4"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="w-1/2 relative">
                            <input
                              type="radio"
                              id="radio-3"
                              checked={editBoardDirector === "No"}
                              value="No"
                              onClick={(e) => {
                                setEditBoardDirector(e.target.value);
                              }}
                              name="startupBoardDirector"
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-3"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="url"
                        >
                          What’s your LinkedIn <strong>profile</strong>?
                        </label>
                        <input
                          type="url"
                          id="linkedin"
                          value={editLinkedIn}
                          autoComplete="off"
                          onChange={(e) => {
                            setEditLinkedIn(e.target.value);
                          }}
                          placeholder="www.linkedin.com/in/..."
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                        />
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          and your <strong>email address</strong>?
                        </label>
                        <input
                          type="text"
                          id="linkedin"
                          value={editEmail}
                          onChange={(e) => {
                            setEditEmail(e.target.value);
                          }}
                          placeholder="me@email.com"
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                        />
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          What’s your <strong>target career level</strong>?
                        </label>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="mid"
                              type="checkbox"
                              value={"Mid"}
                              checked={editCarrer?.includes("Mid") || editCarrer?.includes("Junior / Mid-Level") || editCarrer?.includes("Entry Level")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="mid"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Mid
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Senior"
                              type="checkbox"
                              value={"Senior"}
                              checked={editCarrer?.includes("Senior") || editCarrer?.includes("Senior / Principal")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Senior"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Senior
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Lead/Principal"
                              type="checkbox"
                              value={"Lead/Principal"}
                              checked={editCarrer?.includes("Lead/Principal") || editCarrer?.includes("Lead / Mid-Manager")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Lead/Principal"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Lead/Principal
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Manager"
                              type="checkbox"
                              value={"Manager"}
                              checked={editCarrer?.includes("Manager")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Manager"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Manager
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Head Of"
                              type="checkbox"
                              value={"Head Of"}
                              checked={editCarrer?.includes("Head Of") || editCarrer?.includes("Head of / Director")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Head Of"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Head Of
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Director"
                              type="checkbox"
                              value={"Director"}
                              checked={editCarrer?.includes("Director") || editCarrer?.includes("Board of Directors / Advisory")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Director"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Director
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="VP/GM"
                              type="checkbox"
                              value={"VP/GM"}
                              checked={editCarrer?.includes("VP/GM") || editCarrer?.includes("VP / GM")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="VP/GM"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              VP/GM
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Executive"
                              type="checkbox"
                              value={"Exec"}
                              checked={editCarrer?.includes("Exec") || editCarrer?.includes("Executive / C-suite")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Executive"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Executive
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="NED"
                              type="checkbox"
                              value={"Board Director"}
                              checked={editCarrer?.includes("Board Director") || editCarrer?.includes("CEO")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="NED"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Board Director
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Founder"
                              type="checkbox"
                              value={"Founder"}
                              checked={editCarrer?.includes("Founder")}
                              onChange={(e) => handleCareerWork(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Founder"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Founder
                            </label>
                          </li>
                        </ul>
                        {/* <Select
                          placeholder="Choose a Seniority Level"
                          name="Seniority"
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          value={editCarrer}
                          onChange={(item) => setEditCareer(item)}
                          options={uniqueLevelresult}
                        /> */}
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          What <strong>type of work</strong> would you like to
                          do?
                        </label>
                        <ul className="">
                          <li className="flex mb-3">
                            <input
                              id="full_time"
                              type="checkbox"
                              value={"Full Time"}
                              checked={typeOfwork?.includes("Full Time")}
                              onChange={(e) => handleCheckboxChange(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="full_time"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Full Time
                            </label>
                          </li>
                          <li className="flex mb-3">
                            <input
                              id="part_time"
                              type="checkbox"
                              checked={typeOfwork?.includes("Part Time")}
                              value={"Part Time"}
                              onChange={(e) => handleCheckboxChange(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="part_time"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Part Time
                            </label>
                          </li>
                          <li className="flex mb-3">
                            <input
                              id="project_based_consultancy"
                              type="checkbox"
                              value={"Project Based / Consultancy"}
                              checked={typeOfwork?.includes(
                                "Project Based / Consultancy"
                              )}
                              onChange={(e) => handleCheckboxChange(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="project_based_consultancy"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Project Based / Consultancy
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Are you open to <strong>remote work</strong>?
                        </label>
                        <div className="flex space-x-3">
                          <div className="w-1/2 relative">
                            <input
                              checked={editExperience == true || editExperience == "true"}
                              type="radio"
                              id="radio-1"
                              name="startupExperience"
                              value={true}
                              onClick={(e) => {
                                setEditExperience(e.target.value);
                              }}
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-1"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="w-1/2 relative">
                            <input
                              type="radio"
                              checked={editExperience == false || editExperience == "false"}
                              id="radio-2"
                              name="startupExperience"
                              value={false}
                              onClick={(e) => {
                                setEditExperience(e.target.value);
                              }}
                              className="form-radio-input block w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                            />
                            <label
                              htmlFor="radio-2"
                              className="block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 text-center bg-white bg-clip-padding border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-deep-purple-500 transition ease-in-out"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-full mb-4">
                      <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          What <strong>stage</strong> should the business be at?
                        </label>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="pre-Seed"
                              type="checkbox"
                              value={"Pre-Seed"}
                              checked={editStage.includes(
                                "Pre-Seed"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="pre-Seed"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Pre-Seed
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series C"
                              type="checkbox"
                              value={"Series C"}
                              checked={editStage.includes(
                                "Series C"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series C"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series C
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="seed"
                              type="checkbox"
                              value={"Seed"}
                              checked={editStage.includes(
                                "Seed"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="seed"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Seed
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series D"
                              type="checkbox"
                              value={"Series D"}
                              checked={editStage.includes(
                                "Series D"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series D"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series D
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series A"
                              type="checkbox"
                              value={"Series A"}
                              checked={editStage.includes(
                                "Series A"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series A"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series A
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series E"
                              type="checkbox"
                              value={"Series E"}
                              checked={editStage.includes(
                                "Series E"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series E"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series E
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="series B"
                              type="checkbox"
                              value={"Series B"}
                              checked={editStage.includes(
                                "Series B"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="series B"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Series B
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="pre-IPO"
                              type="checkbox"
                              value={"Pre-IPO"}
                              checked={editStage.includes(
                                "Pre-IPO"
                              )}
                              onChange={(e) => handleStagesArray(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="pre-IPO"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Pre-IPO
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="w-full mb-4 ">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-3"
                          htmlFor="password"
                        >
                          Any <strong>preferred industries</strong>?
                        </label>
                        <div className="flex mb-5 ">
                          <input
                            id="noIndustryPreference"
                            type="checkbox"
                            value={"No Industry Preference"}
                            checked={industries?.includes(
                              "No Industry Preference"
                            )}
                            onChange={(e) => handleIndustries(e)}
                            className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                          />
                          <label
                            htmlFor="noIndustryPreference"
                            className="text-base md:text-lg font-normal text-gray-1000 place-self-center block w-full border-b border-gray-300 pb-5"
                          >
                            No Industry Preference
                          </label>
                        </div>
                        <ul className="w-full flex flex-wrap items-start o-preferred-candidate">
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="artificialIntelligence"
                              type="checkbox"
                              value={"Artificial Intelligence"}
                              checked={industries?.includes(
                                "Artificial Intelligence"
                              )}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="artificialIntelligence"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Artificial Intelligence
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="automotive"
                              type="checkbox"
                              value={"Automotive"}
                              checked={industries?.includes(
                                "Automotive"
                              )}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="automotive"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Automotive
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="blockchain"
                              type="checkbox"
                              value={"Blockchain"}
                              checked={industries?.includes("Blockchain")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="blockchain"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Blockchain
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="b2bServices"
                              type="checkbox"
                              value={"B2B Services"}
                              checked={industries?.includes("B2B Services")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="b2bServices"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              B2B Services
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="crypto"
                              type="checkbox"
                              value={"Crypto"}
                              checked={industries?.includes("Crypto")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="crypto"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Crypto
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="consumer"
                              type="checkbox"
                              value={"Consumer"}
                              checked={industries?.includes("Consumer")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="consumer"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Consumer
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="deepTech"
                              type="checkbox"
                              value={"Deep Tech"}
                              checked={industries?.includes("Deep Tech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="deepTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Deep Tech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="dataAnalytics"
                              type="checkbox"
                              value={"Data Analytics"}
                              checked={industries?.includes("Data Analytics")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="dataAnalytics"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Data Analytics
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="IndustrialTech"
                              type="checkbox"
                              value={"Industrial Tech"}
                              checked={industries?.includes("Industrial Tech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="dataAnalytics"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Industrial Tech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="LifeSciences"
                              type="checkbox"
                              value={"Life Sciences"}
                              checked={industries?.includes("Life Sciences")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="dataAnalytics"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Life Sciences
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="eCommerce"
                              type="checkbox"
                              value={"eCommerce"}
                              checked={industries?.includes("eCommerce")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="eCommerce"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              eCommerce
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="ClimateTech"
                              type="checkbox"
                              value={"Sustainability"}
                              checked={industries?.includes("Sustainability")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="ClimateTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Sustainability
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="edTech"
                              type="checkbox"
                              value={"EdTech"}
                              checked={industries?.includes("EdTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="edTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              EdTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="enterpriseSoftware"
                              type="checkbox"
                              value={"Enterprise Software"}
                              checked={industries?.includes(
                                "Enterprise Software"
                              )}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="enterpriseSoftware"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Enterprise Software
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="femTech"
                              type="checkbox"
                              value={"FemTech"}
                              checked={industries?.includes("FemTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="femTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              FemTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="finTech"
                              type="checkbox"
                              value={"FinTech"}
                              checked={industries?.includes("FinTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="finTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              FinTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="FutureofWork"
                              type="checkbox"
                              value={"Future of Work"}
                              checked={industries?.includes("Future of Work")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Future of Work"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Future of Work
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="healthcare"
                              type="checkbox"
                              value={"Healthcare"}
                              checked={industries?.includes("Healthcare")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="healthcare"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Healthcare
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="insurTech"
                              type="checkbox"
                              value={"InsurTech"}
                              checked={industries?.includes("InsurTech")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="insurTech"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              InsurTech
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="marketplace"
                              type="checkbox"
                              value={"Marketplace"}
                              checked={industries?.includes("Marketplace")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="marketplace"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Marketplace
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="payments"
                              type="checkbox"
                              value={"Payments"}
                              checked={industries?.includes("Payments")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="payments"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Payments
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="robotics"
                              type="checkbox"
                              value={"Robotics"}
                              checked={industries?.includes("Robotics")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="robotics"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Robotics
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="retail"
                              type="checkbox"
                              value={"Retail"}
                              checked={industries?.includes("Retail")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="retail"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Retail
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="saas"
                              type="checkbox"
                              value={"SaaS"}
                              checked={industries?.includes("SaaS")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="saas"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              SaaS
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="telecom"
                              type="checkbox"
                              value={"Telecom"}
                              checked={industries?.includes("Telecom")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="telecom"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Telecom
                            </label>
                          </li>
                          <li className="w-full sm:w-1/2 flex mb-3">
                            <input
                              id="Other"
                              type="checkbox"
                              value={"Other"}
                              checked={industries?.includes("Other")}
                              onChange={(e) => handleIndustries(e)}
                              className="form-check-input appearance-none w-6 h-6 md:w-8 md:h-8 flex-none border border-gray-300 rounded md:rounded-lg bg-white bg-center bg-contain checked:bg-white checked:border-2 checked:border-deep-purple-500 focus:outline-none mr-2 md:mr-3 cursor-pointer transition duration-200"
                            />
                            <label
                              htmlFor="Other"
                              className="text-base md:text-lg font-normal text-gray-800 place-self-center"
                            >
                              Other
                            </label>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          htmlFor="password"
                        >
                          Where would you like to <strong>work</strong>?
                        </label>
                        <Select
                          placeholder="Choose Location(s)"
                          name="Location"
                          isClearable={false}
                          isMulti
                          getOptionLabel={(item) => item?.currItem}
                          getOptionValue={(item) => item?.currItem}
                          onChange={(item) => {
                            handleSelectionChanges(item)
                          }}
                          value={ArrayThing || []}
                          options={UniDesiredLocStringresult}
                        />
                      </div> */}
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          for="password"
                        >
                          Anything else you’d like{" "}
                          <strong>employers to know?</strong>
                        </label>
                        <textarea
                          id="textarea"
                          placeholder={`No information to show`}
                          rows="11"
                          onChange={(e) => setEditTextArea(e.target.value)}
                          // maxlength="600"
                          value={editTextArea}
                          className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"
                        />
                      </div>
                      <div className="w-full mb-4">
                        <div className="flex space-x-3">
                          <div className="w-full md:w-1/2 mb-4">
                            <label
                              className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                              for="email"
                            >
                              Gender Identity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Gender"
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              onChange={(item) => {
                                setEditGender(item);
                              }}
                              value={editGender}
                              options={GenderArray}
                            />
                          </div>
                          <div className="w-full md:w-1/2 mb-4">
                            <label
                              className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                              for="password"
                            >
                              Ethnicity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Ethnicity"
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              onChange={(item) => {
                                setEditEthnicity(item);
                              }}
                              value={editEthnicity}
                              options={EthencityArray}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full mb-4">
                        <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-1"
                          for="password"
                        >
                          Candidate's <strong>CV</strong>
                        </label>
                        <div className="block relative flex justify-center items-center group w-full bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 text-lg font-medium rounded-lg cursor-pointer transition duration-200 py-3 px-4">
                          <input
                            type="file"
                            id="file"
                            accept=".pdf"
                            size="10"
                            placeholder="Other information"
                            onChange={(e) => handleFileInputChangeCV(e)}
                            className="appearance-none absolute inset-0 opacity-0 z-10 cursor-pointer	w-full"
                          />
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            className="mr-2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.3727 0.280762C10.3032 0.280762 9.23467 0.704491 8.43348 1.54975L2.62024 7.68202C0.446656 9.97575 0.446656 13.6629 2.62024 15.9567C2.68724 16.0273 2.76777 16.0837 2.85702 16.1226L2.86127 16.124C5.05119 18.1992 8.46713 18.176 10.5702 15.9567L14.6593 11.6421C14.7286 11.5737 14.7833 11.492 14.8203 11.402C14.8574 11.312 14.8759 11.2154 14.8749 11.1181C14.8739 11.0208 14.8533 10.9246 14.8143 10.8354C14.7754 10.7462 14.7189 10.6657 14.6483 10.5988C14.5776 10.5318 14.4942 10.4798 14.403 10.4457C14.3118 10.4116 14.2147 10.3962 14.1175 10.4005C14.0202 10.4047 13.9248 10.4284 13.8369 10.4702C13.749 10.5121 13.6705 10.5711 13.6059 10.6439L9.51673 14.9585C7.88206 16.6835 5.3098 16.6835 3.67513 14.9585C2.04039 13.2334 2.04039 10.4053 3.67513 8.68019L9.48696 2.54793C10.5503 1.42613 12.1966 1.42613 13.2599 2.54793C14.3231 3.66962 14.3231 5.52104 13.2599 6.64272L7.96984 12.2249C7.477 12.7445 6.75758 12.7441 6.26557 12.2249C5.77355 11.7056 5.77355 10.8314 6.26557 10.3122L10.5206 5.82178C10.5898 5.75335 10.6446 5.67169 10.6816 5.58167C10.7186 5.49165 10.7372 5.3951 10.7361 5.29777C10.7351 5.20043 10.7145 5.1043 10.6756 5.01509C10.6367 4.92587 10.5802 4.8454 10.5095 4.77845C10.4389 4.7115 10.3555 4.65944 10.2643 4.62537C10.1731 4.5913 10.076 4.57591 9.97874 4.58013C9.8815 4.58434 9.78609 4.60806 9.69819 4.64989C9.6103 4.69172 9.53171 4.75079 9.46711 4.8236L5.21068 9.31398C4.17966 10.402 4.17966 12.135 5.21068 13.223C5.27949 13.2955 5.36252 13.3529 5.45455 13.3918C6.50038 14.2654 8.06267 14.2358 9.02332 13.223L14.3134 7.6409C15.8497 6.02002 15.8718 3.48337 14.4622 1.78512C14.4283 1.69779 14.3777 1.61786 14.3134 1.54975C13.5122 0.704491 12.4423 0.280762 11.3727 0.280762Z"
                              fill="#6936FF"
                            />
                          </svg>
                          {CV && files == undefined
                            ? CV : files?.name ? files?.name
                            : "Choose PDF file"}
                        </div>
                      </div>
                      <div className="w-full mb-4 mt-5">
                      <label
                          className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                          for="password"
                        >
                        Who <strong>referred you</strong> to our network?
                        </label>
                        <p className="text-sm md:text-base text-gray-800 mb-4">
                        Please add the email address of the person who shared this form with you. We’d like to let them know that you’ve completed it.
                        </p>
                        <input
                        type="text"
                        value={editReferred}
                          onChange={(e) => {
                            setEditReferred(e.target.value);
                          }}
                          className={"appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition ease-in-out"}
                        id="refferedBy"
                        placeholder="person@company.com"
                      />
                      </div>
                      {loading == true ? (
                        <button
                          type="button"
                          className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                          disabled
                        >
                          <span
                            className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                            role="status"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={EditCandidate}
                          className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                        >
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            className="mr-1"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                              fill="white"
                            />
                          </svg>
                          Save Changes
                        </button>
                      )}
                      {loading == true ? (
                        <button
                          type="button"
                          className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                          disabled
                        >
                          <span
                            className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                            role="status"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={DeleteCandidateModel}
                          className="inline-flex mt-2 items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                        >
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            className="mr-1"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                              fill="white"
                            />
                          </svg>
                          Delete Candidate
                        </button>
                      )}
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
        {show == true && (
          <div className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto show">
            <div className="modal-backdrop fade show"></div>
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none pt-16">
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <h2 className="text-2xl font-bold text-center mb-2">
                  Choose Profile Pic For{" "}
                  {`${candata.firstname} ${candata.lastname}`}
                </h2>
                <div className="modal-header flex flex-shrink-0 items-start justify-between p-4 md:px-6 border-b border-gray-300 rounded-t-lg">
                  <button
                    type="button"
                    className="btn-close box-content w-4 h-4 p-2.5 md:p-3 absolute right-4 -top-14 bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 border-none rounded-xl focus:shadow-none focus:outline-none hover:text-deep-purple-500 hover:no-underline"
                    onClick={handleClose}
                    aria-label="Close"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.00291 0.000434093C0.804333 0.000486257 0.610286 0.0597734 0.445575 0.170716C0.280863 0.281658 0.152972 0.439215 0.0782501 0.623243C0.00352837 0.807271 -0.014628 1.00941 0.0261023 1.20381C0.0668325 1.39821 0.164598 1.57605 0.306901 1.71459L6.58849 7.99786L0.306901 14.2811C0.211101 14.3731 0.134617 14.4833 0.0819275 14.6053C0.029238 14.7272 0.00140279 14.8584 5.16679e-05 14.9913C-0.00129945 15.1241 0.0238609 15.2559 0.0740591 15.3789C0.124257 15.5019 0.198484 15.6136 0.292393 15.7075C0.386302 15.8015 0.498004 15.8757 0.620959 15.9259C0.743913 15.9761 0.875648 16.0013 1.00845 15.9999C1.14125 15.9986 1.27244 15.9707 1.39435 15.918C1.51626 15.8653 1.62643 15.7888 1.71841 15.693L8 9.40974L14.2816 15.693C14.3736 15.7888 14.4837 15.8653 14.6056 15.918C14.7276 15.9708 14.8587 15.9986 14.9915 15.9999C15.1243 16.0013 15.2561 15.9761 15.379 15.9259C15.502 15.8757 15.6137 15.8015 15.7076 15.7075C15.8015 15.6136 15.8757 15.5019 15.9259 15.3789C15.9761 15.2559 16.0013 15.1241 15.9999 14.9913C15.9986 14.8584 15.9708 14.7272 15.9181 14.6053C15.8654 14.4833 15.7889 14.3731 15.6931 14.2811L9.4115 7.99786L15.6931 1.71459C15.8373 1.57434 15.9359 1.39378 15.9757 1.19656C16.0156 0.999346 15.995 0.794678 15.9166 0.609379C15.8382 0.424079 15.7057 0.266792 15.5364 0.158131C15.3671 0.0494693 15.1689 -0.00549711 14.9678 0.000434093C14.7085 0.0081633 14.4624 0.116575 14.2816 0.302702L8 6.58597L1.71841 0.302702C1.62539 0.207055 1.51414 0.131028 1.39125 0.079111C1.26836 0.0271943 1.13631 0.000441872 1.00291 0.000434093Z"
                        fill="#6936FF"
                      />
                    </svg>
                  </button>
                  <div className="block relative flex justify-center items-center group w-full bg-deep-purple-50 hover:bg-deep-purple-100 text-deep-purple-500 text-lg font-medium rounded-lg cursor-pointer transition duration-200 py-3 px-4">
                    <input
                      type="file"
                      id="file"
                      accept="jpg, jpeg, png"
                      size="10"
                      placeholder="Other information"
                      onChange={handleFileInputChange}
                      className="appearance-none absolute inset-0 opacity-0 z-10 cursor-pointer	w-full"
                    />
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      className="mr-2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3727 0.280762C10.3032 0.280762 9.23467 0.704491 8.43348 1.54975L2.62024 7.68202C0.446656 9.97575 0.446656 13.6629 2.62024 15.9567C2.68724 16.0273 2.76777 16.0837 2.85702 16.1226L2.86127 16.124C5.05119 18.1992 8.46713 18.176 10.5702 15.9567L14.6593 11.6421C14.7286 11.5737 14.7833 11.492 14.8203 11.402C14.8574 11.312 14.8759 11.2154 14.8749 11.1181C14.8739 11.0208 14.8533 10.9246 14.8143 10.8354C14.7754 10.7462 14.7189 10.6657 14.6483 10.5988C14.5776 10.5318 14.4942 10.4798 14.403 10.4457C14.3118 10.4116 14.2147 10.3962 14.1175 10.4005C14.0202 10.4047 13.9248 10.4284 13.8369 10.4702C13.749 10.5121 13.6705 10.5711 13.6059 10.6439L9.51673 14.9585C7.88206 16.6835 5.3098 16.6835 3.67513 14.9585C2.04039 13.2334 2.04039 10.4053 3.67513 8.68019L9.48696 2.54793C10.5503 1.42613 12.1966 1.42613 13.2599 2.54793C14.3231 3.66962 14.3231 5.52104 13.2599 6.64272L7.96984 12.2249C7.477 12.7445 6.75758 12.7441 6.26557 12.2249C5.77355 11.7056 5.77355 10.8314 6.26557 10.3122L10.5206 5.82178C10.5898 5.75335 10.6446 5.67169 10.6816 5.58167C10.7186 5.49165 10.7372 5.3951 10.7361 5.29777C10.7351 5.20043 10.7145 5.1043 10.6756 5.01509C10.6367 4.92587 10.5802 4.8454 10.5095 4.77845C10.4389 4.7115 10.3555 4.65944 10.2643 4.62537C10.1731 4.5913 10.076 4.57591 9.97874 4.58013C9.8815 4.58434 9.78609 4.60806 9.69819 4.64989C9.6103 4.69172 9.53171 4.75079 9.46711 4.8236L5.21068 9.31398C4.17966 10.402 4.17966 12.135 5.21068 13.223C5.27949 13.2955 5.36252 13.3529 5.45455 13.3918C6.50038 14.2654 8.06267 14.2358 9.02332 13.223L14.3134 7.6409C15.8497 6.02002 15.8718 3.48337 14.4622 1.78512C14.4283 1.69779 14.3777 1.61786 14.3134 1.54975C13.5122 0.704491 12.4423 0.280762 11.3727 0.280762Z"
                        fill="#6936FF"
                      />
                    </svg>
                    {file?.name ? file?.name : "Chose File"}
                  </div>
                  <br />
                  {loading == true ? (
                    <button
                      type="button"
                      className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                      disabled
                    >
                      <span
                        className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                        role="status"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={SubmitImage}
                      className="inline-flex items-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-base font-medium whitespace-nowrap rounded-lg hover:shadow-md transition duration-200 py-3 px-4"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        className="mr-1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.09814 0.0386963C3.67974 0.0386963 0.0981445 3.6203 0.0981445 8.0387C0.0981445 12.4571 3.67974 16.0387 8.09814 16.0387C12.5165 16.0387 16.0981 12.4571 16.0981 8.0387C16.0981 3.6203 12.5165 0.0386963 8.09814 0.0386963ZM10.4981 8.8387H8.89814V10.4387C8.89814 10.8803 8.53974 11.2387 8.09814 11.2387C7.65654 11.2387 7.29814 10.8803 7.29814 10.4387V8.8387H5.69814C5.25654 8.8387 4.89814 8.4803 4.89814 8.0387C4.89814 7.5971 5.25654 7.2387 5.69814 7.2387H7.29814V5.6387C7.29814 5.1971 7.65654 4.8387 8.09814 4.8387C8.53974 4.8387 8.89814 5.1971 8.89814 5.6387V7.2387H10.4981C10.9397 7.2387 11.2981 7.5971 11.2981 8.0387C11.2981 8.4803 10.9397 8.8387 10.4981 8.8387Z"
                          fill="white"
                        />
                      </svg>
                      Upload
                    </button>
                  )}
                </div>
                {candata.profilepic && (
                  <div className="items-center justify-center buttonLeft">
                {loading == true ? (
                    <button
                      type="button"
                      className="w-full flex items-center justify-center bg-deep-purple-500 hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                      disabled
                    >
                      <span
                        className="animate-spin inline-block w-8 h-8 mr-3 border-2 border-white border-l-transparent rounded-full"
                        role="status"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                  className="bg-deep-purple-500 items-center hover:bg-deep-purple-400 text-white text-lg font-medium rounded-lg  hover:shadow-md transition duration-200 py-3 px-4 mb-4"
                  type="submit"
                  onClick={DeleteImage}
                >
                  Delete profilepic
                </button>
                  )}
                </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
        </>
      ) : (
        <UiComp candata={cardData} handledetailsModelClose={handledetailsModelClose} />
      )}
    </>
   
  );
};

CandidateDetailTable.propTypes = {
  fetchDashboard: PropTypes.func,
  UserLogout: PropTypes.func,
};


const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps, {
  fetchDashboard: getDash,
  UserLogout: getLogOut,
})(CandidateDetailTable);
